import React from 'react';
import { always, cond, equals } from 'ramda';

import { Block } from '@definitions/common.types';
import { BLOCK_TYPE } from '@components/blocks/blocks.constants';

import { Container, H1, H2, H3, H4, H5, H6 } from './heading.styles';

export const Heading = ({ type, value }: Block) => {
  const header = cond([
    [equals(BLOCK_TYPE.H1), always(<H1>{value}</H1>)],
    [equals(BLOCK_TYPE.H2), always(<H2>{value}</H2>)],
    [equals(BLOCK_TYPE.H3), always(<H3>{value}</H3>)],
    [equals(BLOCK_TYPE.H4), always(<H4>{value}</H4>)],
    [equals(BLOCK_TYPE.H5), always(<H5>{value}</H5>)],
    [equals(BLOCK_TYPE.H6), always(<H6>{value}</H6>)],
    // @ts-ignore
  ])(type);

  return <Container data-testid={type}>{header}</Container>;
};
