import React from 'react';
import NextLink from 'next/link';
import { is } from 'ramda';
import { FormattedMessage } from 'react-intl';

import { Artwork } from '@definitions/artworks.types';
import { ROUTE } from '@utils/constants';
import { FavoriteButton } from '@components/favoriteButton';
import { renderWhen } from '@utils/rendering';
import { formatPrice } from '@utils/helpers';

import {
  Container,
  Content,
  ArtworkContainer,
  ImageContainer,
  Image,
  Caption,
  favoriteButtonCustomStyles,
  Price,
  ViewAll,
  ViewAllCopy,
  ArrowRight,
} from './smallArtworkList.styles';

export interface SmallArtworkListProps {
  artworks: Artwork[];
}

export const SmallArtworkList = ({ artworks }: SmallArtworkListProps) => {
  const renderPrice = renderWhen(is(Number), (price: string) => <Price>{formatPrice(price, true)}</Price>);

  return (
    <Container>
      <Content>
        {artworks.map(({ primaryImage, artist, slug, favorite, price }, index) => (
          <ArtworkContainer
            key={index}
            onClick={() => {
              window.history.pushState({}, '', `${ROUTE.ARTWORKS}/${artist.slug}/${slug}`);
            }}
            href={`${ROUTE.ARTWORKS}/${artist.slug}/${slug}`}
          >
            <ImageContainer>
              <Image src={primaryImage?.urlThumbnail} alt={primaryImage?.description} />
            </ImageContainer>
            <Caption>
              {renderPrice(price)}
              <FavoriteButton
                slug={slug}
                favorite={favorite}
                width={22}
                height={22}
                customStyles={favoriteButtonCustomStyles}
              />
            </Caption>
          </ArtworkContainer>
        ))}
        <a
          onClick={() => {
            window.history.pushState({}, '', ROUTE.ACCOUNT_FAVORITES);
          }}
          href={ROUTE.ACCOUNT_FAVORITES}
        >
          <ViewAll>
            <ViewAllCopy>
              <FormattedMessage id="smallArtworkList.viewAll" defaultMessage="View all" />
              <ArrowRight />
            </ViewAllCopy>
          </ViewAll>
        </a>
      </Content>
    </Container>
  );
};
