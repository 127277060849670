import styled from 'styled-components';

import { media } from '@theme/media';
import { FONT_SIZE, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';
import { CONTAINER_PADDING } from '@components/blocks/blocks.constants';

import { containerStyles } from '../blocks.styles';

export const Container = styled.div<{ backgroundColor: string; textColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
`;
export const InnerContainer = styled.div`
  ${containerStyles};
  min-height: var(--vh);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  pointer-events: none;
`;

export const Masthead = styled.h3`
  ${typography({ spacing: LETTER_SPACING.M })};
  text-transform: uppercase;
  padding-bottom: 51px;
  margin: 0;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const Headline = styled.h1<{ isLongWord: boolean }>`
  ${({ isLongWord }) =>
    isLongWord
      ? typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.S })
      : typography({ size: FONT_SIZE.XL, height: LINE_HEIGHT.S })};
  text-align: center;
  margin: 0;

  ${media.mobile} {
    ${typography({ size: FONT_SIZE.XXL, height: LINE_HEIGHT.S })};
  }

  ${media.desktop} {
    max-width: 676px;
  }
`;

export const Subhead = styled.p`
  ${typography({ size: FONT_SIZE.M })};
  text-align: center;
  margin: ${CONTAINER_PADDING}px 0;
`;
