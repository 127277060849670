import styled, { css } from 'styled-components';
import { always, ifElse, propEq } from 'ramda';
import ReactPlayer from 'react-player';

import { ASSET_PLACEMENT, CONTAINER_PADDING, CONTAINER_WIDTH } from '@components/blocks/blocks.constants';
import { media } from '@theme/media';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

interface StylesProps {
  placement: ASSET_PLACEMENT;
}

export const Caption = styled.p`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
`;

const normalVideoStyle = css`
  max-width: ${CONTAINER_WIDTH - 2 * CONTAINER_PADDING}px;
  margin: ${OFFSET.L} auto ${OFFSET.M};
  ${Caption} {
    margin: ${OFFSET.XS} 0 0;
  }
`;

const wideVideoStyle = css`
  margin: ${OFFSET.L} -${OFFSET.M} ${OFFSET.M};
  ${Caption} {
    margin: ${OFFSET.XS} ${OFFSET.M} 0;
  }
`;

const getVideoContainerStyles: ({ placement }: StylesProps) => string = ifElse(
  propEq('placement', ASSET_PLACEMENT.NORMAL),
  always(normalVideoStyle),
  always(wideVideoStyle)
);

export const Container = styled.div`
  margin: 0 ${OFFSET.M};

  ${media.tablet} {
    margin: 0 ${CONTAINER_PADDING * 1.5}px;
  }
`;

export const Content = styled.div`
  ${getVideoContainerStyles};

  ${media.tablet} {
    margin: ${OFFSET.L} auto ${OFFSET.M};

    ${Caption} {
      margin: ${OFFSET.XS} 0 0;
    }
  }

  position: relative;
`;

export const VideoWrapper = styled.div`
  padding-top: 56.25%;
  position: relative;
`;

export const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
