import { useEffect, useRef, useState } from 'react';

import { changeImageSize } from '@components/optimisedImageWrapper';

interface useOptimisedImageSizeProps {
  src: string;
}

export const useOptimisedImageSize = ({ src: originalSrc }: useOptimisedImageSizeProps) => {
  const ref = useRef(null);
  const [optimisedURL, setOptimisedURL] = useState(originalSrc);

  useEffect(() => {
    if (ref !== null && ref.current !== null) {
      const { offsetWidth } = ref.current;
      setOptimisedURL(changeImageSize(originalSrc, offsetWidth));
    }
  }, [ref.current]);

  return { optimisedURL, ref };
};
