import React from 'react';
import { motion } from 'framer-motion';

import { MAIN_ANIMATION } from '@utils/constants';

export interface MainAnimateContainerProps {
  children: React.ReactNode;
}

export const MainAnimateContainer = ({ children }: MainAnimateContainerProps) => (
  <motion.div
    initial={{ opacity: 0, y: MAIN_ANIMATION.MOVE_Y }}
    exit={{ opacity: 0, transition: { duration: MAIN_ANIMATION.REVERSE_DURATION } }}
    animate={{ opacity: 1, y: 0, transition: { duration: MAIN_ANIMATION.DURATION } }}
  >
    {children}
  </motion.div>
);
