import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';

import { ResponseAddWaitlistPayload, ResponseRemoveWaitlistPayload } from '@definitions/artworks.types';
import { GlobalState } from '@modules/createStore';
import { plpActions } from '@modules/plp';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useWaitlist = (prefix: string) => {
  const dispatch: AppDispatch = useDispatch();

  const addWaitlist = (data: ResponseAddWaitlistPayload) =>
    dispatch(plpActions.addToWaitlist(prefix)(data)).then(unwrapResult);

  const removeFromWaitlist = (data: ResponseRemoveWaitlistPayload) =>
    dispatch(plpActions.removeFromWaitlist(prefix)(data)).then(unwrapResult);

  return { addWaitlist, removeFromWaitlist };
};
