import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

const inputStyles = css`
  background-color: ${COLORS.WILD_SAND};
  border: none;
  outline: none;
  width: 100%;
  padding: ${OFFSET.XS};
  ${typography({})};

  ${media.mobile} {
    ${typography({ spacing: LETTER_SPACING.M })};
  }
`;
export const Input = styled.input`
  ${inputStyles};
`;

export const FlexStyle = styled.div`
  flex: column;
  width: 100%;
`;

export const ErrorTextStyle = styled.div`
  color: ${COLORS.RED};
  fontsize: ${FONT_SIZE.XS};
`;
