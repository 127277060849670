import styled, { css } from 'styled-components';

import { COLORS } from '@theme/colors';
import { OFFSET } from '@theme/helpers';
import { FONT_SIZE, typography } from '@theme/font';
import { media } from '@theme/media';

export const OuterContainer = styled.div<{ secondaryStyles?: boolean; isMobileVersion?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 4px;

  ${({ secondaryStyles }) =>
    secondaryStyles &&
    css`
      border-top: 1px solid ${COLORS.SILVER};
      border-bottom: 1px solid ${COLORS.SILVER};
      text-align: center;
      align-items: center;
      padding: ${OFFSET.S} ${OFFSET.XS};
      margin: ${OFFSET.S} 0;

      ${CountDownContainer} {
        ${typography({})};
        color: ${COLORS.BLACK};
      }
    `}

  ${media.mobile} {
    flex-direction: column;
  }

  ${({ isMobileVersion }) =>
    isMobileVersion &&
    css`
      justify-content: center;
      margin: ${OFFSET.S};

      ${media.mobile} {
        display: none;
      }
    `};
`;

export const CountDownContainer = styled.div`
  position: relative;
  display: flex;
  gap: ${OFFSET.XS};
  color: ${COLORS.SILVER_CHALICE};
  ${typography({ size: FONT_SIZE.XS })};
`;
