import React from 'react';

import { LinkSetType } from '@definitions/common.types';

import {
  CenteredTextContainer,
  Container,
  Image,
} from './textOnImageBlock.styles';

export interface TextOnImageBlockProps {
  text: string;
  link: { type: string, urlPath: string, displayText: string, slug: string };
  image: { urls: [{ url: string, size: string }], title: string }
  fontSize: { size: string; spacing: string; };
  textAlign: 'left' | 'right' | 'center';
  blockWidth: string;
  textBoxWidth: string;
  backgroundColor: string;
  transparentBackground: boolean;
}

export const TextOnImageBlock = ({ text, image, fontSize, textAlign, blockWidth, textBoxWidth, transparentBackground, backgroundColor, link }: TextOnImageBlockProps) => {
  const getblockWidth = blockWidth === 'full-bleed' ? 100 : blockWidth === 'wide' ? 80 : 35
  const calculatedWidth: number = (parseFloat(textBoxWidth) / 100) * getblockWidth;
  return (
    <Container>
      <Image
        onClick={(e) => {
          if(link.type){
            e.preventDefault();
            const targetUrl = link.type === LinkSetType.INTERNAL ? `/${link.slug}` : link.urlPath;
            if(link.slug){ window.history.pushState({}, '', targetUrl); }
            window.location.href = targetUrl;
          }
        }}
        style={{ cursor: link.type ? 'pointer' : 'default' }}
        blockWidth={String(getblockWidth) + '%'}
        src={image.urls[0].url}
        alt={image.title}
      />

      <CenteredTextContainer
        calculatedWidth={calculatedWidth}
        textAlign={textAlign}
        backgroundColor={!transparentBackground ? backgroundColor : 'transparent'}
        fontSize={fontSize.size}
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </CenteredTextContainer>
    </Container>
  );
};
