import styled from 'styled-components';

import { legalPageFontStyles, linkStyles } from '@components/blocks/blocks.styles';
import { CONTAINER_PADDING } from '@components/blocks';
import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';

export const Container = styled.li`
  list-style: none;
  padding: 0;
  ${legalPageFontStyles};

  &:nth-child(1) {
    a {
      border-top: 1px solid ${COLORS.GALLERY};
      padding-top: ${CONTAINER_PADDING / 2}px;
      margin-top: ${CONTAINER_PADDING / 2}px;
    }
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Link = styled.a`
  display: flex;
  border-bottom: 1px solid ${COLORS.GALLERY};
  padding-bottom: ${CONTAINER_PADDING / 2}px;
  margin-bottom: ${CONTAINER_PADDING / 2}px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 15px;

  span {
    text-transform: capitalize;
  }

  &:nth-child(2) {
    flex: 2;
  }
  &:nth-child(3),
  &:nth-child(4) {
    flex: 3;
  }
`;

export const ContentMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: ${OFFSET.XS};
  padding-right: ${OFFSET.XS};
  flex-wrap: wrap;
  min-width: 50%;

  span {
    padding: ${OFFSET.XS} ${OFFSET.XS} 0 0;
    align-self: flex-end;
    text-align: left;
    color: ${COLORS.BLACK40};
    flex: 1;
    text-transform: capitalize;
  }

  &:nth-child(1),
  &:nth-child(2) {
    flex: 0.5;
    max-height: 200px;
  }

  &:nth-child(3) {
    flex: 1;
  }
`;

export const Title = styled.div`
  text-transform: uppercase;
  white-space: pre-wrap;
  margin-bottom: ${OFFSET.XS};
`;

export const Description = styled.div`
  width: 100%;
  a {
    ${linkStyles};
  }
  p {
    margin: 0;
  }
`;

export const Thumbnail = styled.div`
  width: 85px;
  margin-right: ${OFFSET.M};
  flex-shrink: 0;

  ${media.tablet} {
    width: 200px;
    margin-right: 0;
  }

  ${media.desktop} {
    width: 120px;
    min-width: 120px;
  }
`;

export const ImageWrapper = styled.div`
  height: 110px;
  overflow: hidden;

  ${media.tablet} {
    height: 120px;
  }

  ${media.desktop} {
    height: 80px;
  }
`;

export const ThumbnailMobile = styled.div`
  width: 100%;

  img,
  svg {
    width: 100%;
    height: auto;
    max-height: 150px;
  }
`;

export const Image = styled.img`
  display: block;
  width: 200px;
  height: auto;
  max-height: 1400px;
  object-fit: cover;
  object-position: center;

  ${media.desktop} {
    width: 120px;
    max-height: 100px;
  }
`;

export const Placeholder = styled.div`
  path {
    paint-order: stroke;
  }
`;
