import styled, { css } from 'styled-components';
import { always } from 'ramda';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, FONT_WEIGHT, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';
import { renderWhenTrue } from '@utils/rendering';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  padding: 0 13px;
  height: 100%;
  overflow-y: auto;
`;

export const Title = styled.div`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  border-top: 1px solid ${COLORS.BLACK};
  border-bottom: 1px solid ${COLORS.BLACK};
  text-transform: uppercase;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 44px;

  ${media.tablet} {
    border-top: none;
    ${typography({ spacing: LETTER_SPACING.M })};
    padding-top: 9px;
    padding-bottom: 6px;
    margin-bottom: ${OFFSET.M};
  }
`;

export const CountryList = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  line-height: ${LINE_HEIGHT.M};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 612px;

  ${media.mobile} {
    height: 320px;
  }
`;

export const CountryListItem = styled.li`
  width: 50%;

  ${media.mobile} {
    width: 33.333%;
  }
`;

const getIsActiveStyles = renderWhenTrue(
  always(css`
    border-bottom: 1px solid ${COLORS.BLACK};
    cursor: default;
  `)
);

export const CountryLink = styled.button<{ isActive: boolean }>`
  color: ${COLORS.BLACK};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 0;
  cursor: pointer;
  font-weight: ${FONT_WEIGHT.BOOK};
  letter-spacing: ${LETTER_SPACING.M};

  ${media.mobile} {
    padding: 0 0 2px;
  }

  &:hover {
    border-bottom: 1px solid ${COLORS.BLACK40};
    ${({ isActive }) => (isActive ? `border-bottom-color: ${COLORS.BLACK}` : null)};
  }

  ${({ isActive }) => getIsActiveStyles(isActive)};
`;
