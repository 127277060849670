import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';
import useCustomerBC, { Customer } from '@bigcommerce/storefront-data-hooks/use-customer';
import { useEffect, useState } from 'react';

import { customerSelectors, customerActions } from '@modules/customer';
import { GlobalState } from '@modules/createStore';
import { RegisterPayload, UpdatePayload } from '@definitions/customer.types';
import { ResetPasswordPayload, ResetPasswordConfirmPayload } from '@definitions/common.types';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useRegisterCustomer = () => {
  const dispatch: AppDispatch = useDispatch();

  const customer = useSelector(customerSelectors.selectCustomer);
  const registerCustomer = (formData: RegisterPayload) =>
    dispatch(customerActions.registerCustomer(formData)).then(unwrapResult);

  return { customer, registerCustomer };
};

export const useResetPassword = () => {
  const dispatch: AppDispatch = useDispatch();

  const resetPassword = (formData: ResetPasswordPayload) =>
    dispatch(customerActions.resetPassword(formData)).then(unwrapResult);

  return { resetPassword };
};

export const useChangePassword = () => {
  const dispatch: AppDispatch = useDispatch();

  const changePassword = (formData: ResetPasswordConfirmPayload) =>
    dispatch(customerActions.changePassword(formData)).then(unwrapResult);

  return { changePassword };
};

export const useUpdateCustomer = () => {
  const dispatch: AppDispatch = useDispatch();

  const customer = useSelector(customerSelectors.selectCustomer);
  const updateCustomer = (formData: UpdatePayload) =>
    dispatch(customerActions.updateCustomer(formData)).then(unwrapResult);

  return { customer, updateCustomer };
};

export const useGetCustomer = () => {
  const dispatch: AppDispatch = useDispatch();

  const customer = useSelector(customerSelectors.selectCustomer);
  const getCustomer = () => dispatch(customerActions.getCustomer()).then(unwrapResult);

  return { customer, getCustomer };
};

export const useCustomer = () => {
  const [loadingCustomer, setLoadingCustomer] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);
  const { data: customer, revalidate } = useCustomerBC();

  useEffect(() => {
    if (customer === null) {
      setIsAuthed(false);
      setLoadingCustomer(false);
    }

    if (customer?.email) {
      setIsAuthed(true);
      setLoadingCustomer(false);
    }
  }, [customer]);

  return { loadingCustomer, isAuthed, customer, revalidate } as {
    loadingCustomer: boolean;
    isAuthed: boolean;
    customer: Customer;
    revalidate: () => Promise<any>;
  };
};
