import { useDispatch, useSelector } from 'react-redux';

import { gdprConsentsActions, gdprConsentsSelectors } from '@modules/gdprConsents';
import { CookieTypes } from '@definitions/gdprConsents.types';

export const useGdprConsents = () => {
  const dispatch = useDispatch();

  const showModal = useSelector(gdprConsentsSelectors.selectShowModal);
  const gdprConsent = useSelector(gdprConsentsSelectors.selectGdprConsent);
  const gdprConsents = useSelector(gdprConsentsSelectors.selectGdprConsents);
  const setConsents = (types: CookieTypes) => dispatch(gdprConsentsActions.setConsents(types));

  return { showModal, gdprConsent, gdprConsents, setConsents };
};
