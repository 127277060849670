import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';

import { useLogout } from '@hooks/useCmsUserAuth';

import { Container, LogoutCopy, PreviewCopy } from './previewMark.styles';

export const PREVIEW_MARK_TEST_ID = 'previewMarkTestId';

export const PreviewMark = () => {
  const { logout } = useLogout();
  const router = useRouter();

  const handleLogout = () => {
    logout();
    setTimeout(() => {
      router.reload();
    });
  };

  return (
    <Container data-testid={PREVIEW_MARK_TEST_ID} onClick={handleLogout}>
      <PreviewCopy>
        <FormattedMessage id="previewMark.title" defaultMessage="Preview" />
      </PreviewCopy>
      <LogoutCopy>
        <FormattedMessage id="previewMark.logout" defaultMessage="Logout" />
      </LogoutCopy>
    </Container>
  );
};
