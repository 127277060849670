import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import NextLink from 'next/link';
import { always } from 'ramda';
import useLogin from '@bigcommerce/storefront-data-hooks/use-login';
import { useRouter } from 'next/router';
import { StatusCodes } from 'http-status-codes';

import { Error, formMessages, TextField, Link, Button } from '@components/form';
import { USER_WAITLISTED_ITEMS } from '@components/waitlistButton/waitlistButton.constants';
import { useYupValidationResolver } from '@hooks/useYup';
import { renderWhenTrue } from '@utils/rendering';
import { ROUTE } from '@utils/constants';
import { ls } from '@utils/helpers';

import { Container, ButtonsContainer, FormTitle } from './loginForm.styles';
import { ERROR_TYPE, LOGIN_FORM_EMAIL, LOGIN_FORM_PASSWORD, LOGIN_TEST_ID } from './loginForm.constants';

interface Form {
  [LOGIN_FORM_EMAIL]: string;
  [LOGIN_FORM_PASSWORD]: string;
}

export interface LoginFormProps {
  closeAllSublevels: () => void;
  isMobile?: boolean;
  preventRedirectAfterLogin: boolean;
}

export const LoginForm = ({ closeAllSublevels, isMobile = false, preventRedirectAfterLogin }: LoginFormProps) => {
  const [error, setError] = useState<ERROR_TYPE | null>(null);
  const inputNameSuffix = isMobile ? '-mobile' : '';
  const login = useLogin();
  const router = useRouter();
  const intl = useIntl();
  const validationSchema = useMemo(
    () =>
      yup.object({
        [LOGIN_FORM_EMAIL]: yup
          .string()
          .email(intl.formatMessage(formMessages.emailError))
          .required(intl.formatMessage(formMessages.required)),
        [LOGIN_FORM_PASSWORD]: yup.string().required(intl.formatMessage(formMessages.required)),
      }),
    []
  );
  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit, control } = useForm<Form>({
    defaultValues: {
      [LOGIN_FORM_EMAIL]: '',
      [LOGIN_FORM_PASSWORD]: '',
    },
    resolver,
  });
  const { errors } = useFormState({ control });

  const renderError = renderWhenTrue(
    always(
      <Error>
        <FormattedMessage {...formMessages.loginError} />
      </Error>
    )
  );

  const onSubmit = async (formData: Form) => {
    try {
      setError(null);
      await login({
        email: formData[LOGIN_FORM_EMAIL],
        password: formData[LOGIN_FORM_PASSWORD],
      });
      closeAllSublevels();

      if (preventRedirectAfterLogin) {
        return;
      }
      ls.remove(USER_WAITLISTED_ITEMS);
      await router.push(ROUTE.ACCOUNT);
    } catch (e) {
      const error = e as { status: StatusCodes };
      renderError(e);
      if (error.status === StatusCodes.UNAUTHORIZED) {
        return setError(ERROR_TYPE.LOGIN);
      }

      return setError(ERROR_TYPE.GENERAL);
    }
  };

  return (
    <Container data-testid={LOGIN_TEST_ID}>
      <form method="post" onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>
          <FormattedMessage id="loginForm.title" defaultMessage="Login:" />
        </FormTitle>
        <TextField
          register={register(LOGIN_FORM_EMAIL)}
          error={errors[LOGIN_FORM_EMAIL]}
          nameSuffix={inputNameSuffix}
          placeholder={intl.formatMessage(formMessages.email)}
          autoFocus
        />
        <TextField
          type="password"
          register={register(LOGIN_FORM_PASSWORD)}
          error={errors[LOGIN_FORM_PASSWORD]}
          nameSuffix={inputNameSuffix}
          placeholder={intl.formatMessage(formMessages.password)}
        />
        {renderError(!!error)}
        <ButtonsContainer>
          <Button type="submit" fullWidth>
            <FormattedMessage id="loginForm.logIn" defaultMessage="LOG IN" />
          </Button>

          <NextLink href={ROUTE.SIGN_UP} passHref>
            <Link fullWidth onClick={closeAllSublevels}>
              <FormattedMessage id="loginForm.register" defaultMessage="Create new account" />
            </Link>
          </NextLink>

          <NextLink href={ROUTE.FORGOT_PASSWORD} passHref>
            <Link textLink fullWidth onClick={closeAllSublevels}>
              <FormattedMessage id="loginForm.forgotPassword" defaultMessage="Forgot password?" />
            </Link>
          </NextLink>
        </ButtonsContainer>
      </form>
    </Container>
  );
};
