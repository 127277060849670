import React, { InputHTMLAttributes } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { always } from 'ramda';

import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { formMessages } from '@components/form';

import { ErrorMessage, Optional } from '../form.styles';

import { Input, Label, InputWrapper, Textarea, TextAreaOptional } from './textField.styles';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  error?: FieldError;
  optional?: boolean;
  asterisk?: boolean;
  className?: string;
  nameSuffix?: string;
  autoFocus?: boolean;
  textarea?: boolean;
  register: UseFormRegisterReturn;
}

export const TextField = ({
  nameSuffix = '',
  placeholder,
  error,
  type = 'text',
  optional = false,
  asterisk = false,
  autoFocus = false,
  className,
  textarea = false,
  register,
  ...restProps
}: TextFieldProps) => {
  const renderError = renderWhenTrue(() => <ErrorMessage>{error?.message}</ErrorMessage>);
  const renderTextAreaOptional = renderWhenTrue(
    always(
      <TextAreaOptional>
        <FormattedMessage {...formMessages.optional} />
      </TextAreaOptional>
    )
  );
  const renderOptional = renderWhenTrue(
    always(
      <Optional>
        <FormattedMessage {...formMessages.optional} />
      </Optional>
    )
  );

  const renderAsterisk = renderWhenTrue(always(<span>{' *'}</span>));

  const renderTextareaOrInput = renderWhenTrueOtherwise(
    always(
      <Textarea
        id={`${register.name}${nameSuffix}`}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...register}
        {...restProps}
      />
    ),
    always(
      <Input
        type={type}
        id={`${register.name}${nameSuffix}`}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...register}
        {...restProps}
      />
    )
  );

  return (
    <InputWrapper className={className}>
      <Label htmlFor={`${register.name}${nameSuffix}`}>
        {placeholder}
        {renderAsterisk(asterisk)}
        {renderTextAreaOptional(optional && textarea)}
      </Label>
      {renderTextareaOrInput(textarea)}
      {renderOptional(optional && !textarea)}
      {renderError(!!error)}
    </InputWrapper>
  );
};
