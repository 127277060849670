import React from 'react';

import { BLOCK_TYPE } from '@components/blocks/blocks.constants';

import { Container } from './paragraph.styles';

export interface ParagraphProps {
  content: string;
}

export const Paragraph = ({ content }: ParagraphProps) => (
  <Container dangerouslySetInnerHTML={{ __html: content }} data-testid={BLOCK_TYPE.PARAGRAPH} />
);
