import React from 'react';

import { BLOCK_TYPE } from '@components/blocks/blocks.constants';

import { Container } from './centeredText.styles';

export interface CenteredTextProps {
  content: string;
}

export const CenteredText = ({ content }: CenteredTextProps) => (
  <Container data-testid={BLOCK_TYPE.CENTERED_TEXT}>{content}</Container>
);
