import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

import { CONTAINER_PADDING, CONTAINER_WIDTH } from './blocks.constants';

export const containerStyles = css`
  max-width: ${CONTAINER_WIDTH}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${CONTAINER_PADDING}px;
`;

export const multilineTextLinkStyles = css`
  border-bottom: 1px solid ${COLORS.BLACK};
  display: inline-flex;
  height: 18px;

  ${media.mobile} {
    height: 24px;
  }
`;

export const linkStyles = css`
  border-bottom: 1px solid ${COLORS.BLACK};
`;

export const paragraphTextStyles = css`
  ${typography({})};

  ${media.mobile} {
    ${typography({ size: FONT_SIZE.M })};
  }

  a {
    ${linkStyles};
  }
`;

export const paragraphStyles = css`
  margin: 0 0 ${OFFSET.M};

  ${paragraphTextStyles};
`;

export const colonSuffix = css`
  span:first-child::after {
    content: ':';
  }
`;

export const masterBlockBottomLine = css`
  &::after {
    content: '';
    display: block;
    width: calc(100% - ${CONTAINER_PADDING}px * 2);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0 ${CONTAINER_PADDING}px;
  }
`;

export const legalPageFontStyles = css`
  ${typography({ size: FONT_SIZE.XS })};

  ${media.mobile} {
    ${typography({ spacing: LETTER_SPACING.M })};
  }
`;

export const Title = styled.div`
  ${legalPageFontStyles};
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding-top: 9px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${COLORS.BLACK};
  margin-bottom: ${OFFSET.M};
  margin-top: ${OFFSET.L};

  ${media.tablet} {
    margin-top: ${OFFSET.M};
  }
`;
