import styled, { css } from 'styled-components';

import { CONTAINER_PADDING } from '@components/blocks/blocks.constants';
import { Z_INDEX } from '@utils/constants';
import { media } from '@theme/media';
import { COLORS } from '@theme/colors';

export const ScrollContainer = styled.div`
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const ScrollTrack = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: calc(100% - ${CONTAINER_PADDING * 2}px);
  position: absolute;
  bottom: 0;
  left: ${CONTAINER_PADDING}px;
  z-index: ${Z_INDEX.CUSTOM_SCROLL_TRACK};
`;

export const ScrollBar = styled.div`
  height: 1px;
  position: absolute;
  left: ${CONTAINER_PADDING}px;
  bottom: 0;
  z-index: ${Z_INDEX.CUSTOM_SCROLL_TRACK};
  background-color: rgba(0, 0, 0, 0.3);
`;

interface ArrowProps {
  isVisible: boolean;
}

export const arrowsWrapperStyles = css<ArrowProps>`
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 2;
  visibility: hidden;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  padding: 0;

  svg {
    stroke: ${COLORS.BLACK};
  }

  &:before {
    display: none;
  }

  ${media.desktop} {
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  }
`;

export const ArrowNextWrapper = styled.button<ArrowProps>`
  ${arrowsWrapperStyles};

  right: 32px;
`;

export const ArrowPrevWrapper = styled.button<ArrowProps>`
  ${arrowsWrapperStyles};

  left: 32px;
`;
