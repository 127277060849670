import styled, { css, keyframes } from 'styled-components';
import { always, both, ifElse, propEq } from 'ramda';
import { motion } from 'framer-motion';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, FONT_WEIGHT, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';
import { CONTAINER_PADDING, NARROW_CONTAINER_WIDTH } from '@components/blocks/blocks.constants';
import { renderWhenTrueOtherwise } from '@utils/rendering';
import { HEADER_TOP } from '@components/header/header.constants';
import { ROUTE, Z_INDEX } from '@utils/constants';

import IconStar from '../../assets/images/icon-star.svg';

interface NavigationProps {
  openSublevel?: boolean;
  pathname?: string;
  noHover?: boolean;
  navbarColor?: string;
  isSearch?: boolean;
  navbarTextColor?: string;
  isPDP?: boolean;
  isActive?: boolean;
  isTransparentBackground?: boolean;
}

interface StylesProps {
  openSublevel: boolean;
}

export const blurredTransparentHeaderBackgroundStyles = css`
  background-color: ${COLORS.WHITE40};
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
`;

const isNotOpen = ifElse(propEq('openSublevel', true), always(false), always(true));

const isOpen = ifElse(propEq('openSublevel', true), always(true), always(false));

const getHideStyles = (animationSize: number) =>
  renderWhenTrueOtherwise(
    always(css`
      transform: translateY(calc(-306px - ${animationSize}px));

      ${media.tablet} {
        transform: translate(-50%, calc(-306px - ${animationSize}px));
      }
    `),
    always(css`
      transform: translateY(0);
    `)
  );

export const IconFavorite = styled(IconStar)`
  width: 14px;
  height: 14px;
  display: block;

  path {
    fill: none;

    &:last-child {
      fill: ${COLORS.BLACK};
    }
  }
`;

export const Container = styled.header<NavigationProps>``;

const backgroundOpenStyles = css`
  opacity: 1;
  pointer-events: auto;
`;

const getBackgroundOpenStyles = ifElse(propEq('openSublevel', true), always(backgroundOpenStyles), always(null));

export const HeaderBackground = styled.div<StylesProps>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${COLORS.WHITE40};
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  pointer-events: none;
  z-index: ${Z_INDEX.HEADER_BACKGROUND};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: ${COLORS.BLACK30};

    ${media.tablet} {
      background-color: ${COLORS.BLACK10};
    }
  }

  ${getBackgroundOpenStyles};
`;

const menuSearchPulsating = keyframes`
  0% {
    box-shadow: 0 2px 40px 1px ${COLORS.AZURE_RADIANCE50};
  }
  50% {
    box-shadow: 0 2px 20px 1px ${COLORS.AZURE_RADIANCE50};
  }
  100% {
    box-shadow: 0 2px 40px 1px ${COLORS.AZURE_RADIANCE50};
  }
`;

const pdpMobileHeaderBackgroundStyles = css`
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  background-color: rgba(220, 220, 220, 0.5);
`;

export const hoverLinkStyles = css`
  &:hover {
    color: ${COLORS.BLACK};
  }
`;

const getMobileHeaderBackgroundStyles = ifElse(
  both(propEq('headerTop', HEADER_TOP.PDP), isNotOpen),
  always(pdpMobileHeaderBackgroundStyles),
  always(null)
);

export const HeaderContainer = styled.div<{
  isSearch: boolean;
  headerTop?: number;
  hide: boolean;
  animationSize: number;
  openSublevel: boolean;
  openMobileMenu: boolean;
  pathname: string;
}>`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  width: calc(100vw - 20px);
  left: 10px;
  min-height: ${OFFSET.M};
  height: auto;
  top: 10px;
  z-index: ${Z_INDEX.HEADER};
  ${getMobileHeaderBackgroundStyles};
  box-shadow: ${({ pathname }) =>
    pathname === ROUTE.FEATURES ? `0px 0px 24px ${COLORS.BLACK15}` : `0 2px 80px 1px ${COLORS.BLACK20}`};

  ${media.mobile} {
    left: 12.5vw;
    width: 75vw;
  }

  ${media.tablet} {
    left: 50%;
    transform: ${({ headerTop, openSublevel }) =>
      headerTop === HEADER_TOP.PDP && openSublevel ? 'translate3d(-50%, 29px, 0)' : 'translate3d(-50%, 0, 0)'};
    top: ${({ headerTop }) => headerTop}px;
    ${({ headerTop }) => headerTop === HEADER_TOP.PDP && 'box-shadow: none'};
    width: ${NARROW_CONTAINER_WIDTH}px;
    min-height: 34px;
  }

  &:after {
    content: '';
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: ${({ isSearch }) => (isSearch ? 1 : 0)};
    transition: opacity 0.4s ease-in-out;
    box-shadow: 0 2px 40px 1px ${COLORS.EMERALD40};
    animation: ${menuSearchPulsating} 2s backwards ease-in-out infinite;

    ${media.tablet} {
      display: block;
    }
  }

  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  ${({ hide, animationSize }) => getHideStyles(animationSize)(hide)};
`;

export const Item = styled.li``;

export const linkStyles = css`
  text-decoration: none;
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  display: block;
  padding: 8px 8px;
  margin: 0 10px;
  color: inherit;

  ${media.tablet} {
    ${typography({ spacing: LETTER_SPACING.M })};
    padding: 9px 8px;
    margin: 0;
    padding: 9px 8px;
  }
`;

export const NavigationLink = styled.a<NavigationProps>`
  ${linkStyles}
  ${({ noHover }) => !noHover && hoverLinkStyles}
  ${({ isActive }) =>
    isActive
      ? css`
          background: ${COLORS.WHITE};
        `
      : css`
          background: transparent;
        `}
`;

export const NavigationButton = styled.button<NavigationProps>`
  ${linkStyles};
  ${({ noHover }) => !noHover && hoverLinkStyles}
`;

export const listStyle = css`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${typography({})};
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const List = styled.div`
  ${listStyle};
`;

const getHeaderBackgroudStyles = ifElse(
  isOpen,
  always(css`
    background-color: ${COLORS.WHITE25};
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    color: ${COLORS.BLACK};
  `),
  always(
    css<NavigationProps>`
      background-color: ${({ navbarColor, isSearch, pathname }) => {
        switch (true) {
          case !!navbarColor:
            return navbarColor;
          case pathname === ROUTE.HOME:
            return `${COLORS.WHITE}`;
          case isSearch:
            return `${COLORS.WHITE}`;
          default:
            return `${COLORS.WHITE40}`;
        }
      }};
      color: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
      svg {
        fill: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
        stroke: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
      }
      path {
        stroke: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
      }
      -webkit-backdrop-filter: blur(24px);
      backdrop-filter: blur(24px);
    `
  )
);

const searchOverflowAnimation = keyframes`
  0% {
    overflow: hidden
  }
  90% {
    overflow: hidden
  }
  100% {
    overflow: unset;
  }
`;

export const Navigation = styled(motion.div)<StylesProps & NavigationProps>`
  display: none;
  flex-direction: row;
  transition: all 0.6s ease-in-out;
  ${({ isSearch }) =>
    isSearch
      ? css`
          overflow: hidden;
        `
      : css`
          animation: ${searchOverflowAnimation} 1s ease-in-out;
        `}
  ${getHeaderBackgroudStyles}

  ${media.tablet} {
    display: flex;
    transform: ${({ openSublevel }) =>
      openSublevel ? `translate3d(0, calc(var(--vh) - ${CONTAINER_PADDING}px*3), 0)` : 'none'};
  }

  ${({ isTransparentBackground }) =>
    isTransparentBackground &&
    css`
      background: ${COLORS.WHITE};
    `}
`;

const getMainLogoBackground = ifElse(
  isNotOpen,
  always(css<NavigationProps>`
    background-color: ${(props) => (props.isSearch ? props.navbarColor || `${COLORS.WHITE}` : null)};
  `),
  always(css<NavigationProps>`
    background-color: ${(props) =>
      props.openSublevel
        ? props.isSearch
          ? props.navbarColor || `${COLORS.WHITE}`
          : `${COLORS.WHITE}`
        : `${COLORS.WHITE}`};
  `)
);

export const MainLogo = styled.div<StylesProps & NavigationProps>`
  margin-right: 4px;
  padding: 0 3px 0 4px;
  text-transform: uppercase;
  ${getMainLogoBackground}
  position: relative;
  z-index: 2;
`;

export const PrimaryNavigation = styled(motion.ul)`
  ${listStyle};
`;

export const SecondaryNavigation = styled(motion.ul)`
  ${listStyle};
  justify-content: flex-end;
  padding: 0 4px 0 6px;
  align-items: center;

  svg {
    margin-bottom: 2px;
  }
`;

export const Sublevel = styled.div<StylesProps>`
  position: absolute;
  top: ${OFFSET.M};
  width: 100%;
  height: calc(var(--vh) - 44px - 20px);
  left: 0;
  pointer-events: ${({ openSublevel }) => (openSublevel ? 'auto' : 'none')};
  overflow: hidden;
  z-index: ${Z_INDEX.SUBLEVEL};

  ${media.tablet} {
    top: ${({ openSublevel }) => (openSublevel ? '1px' : '0')};
    width: ${NARROW_CONTAINER_WIDTH}px;
    margin-left: -270px;
    height: calc(var(--vh) - ${CONTAINER_PADDING}px * 3);
    left: 50%;
  }
`;
const openStyles = css`
  transform: translate3d(0, 100%, 0);
`;
const getSublevelOpenStyles = ifElse(propEq('openSublevel', true), always(openStyles), always(null));

export const SublevelInner = styled.div<StylesProps>`
  position: absolute;
  left: 0;
  top: -100%;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: 100%;
  transition: transform 0.4s ease-in-out;
  overflow-y: auto;

  ${media.tablet} {
    transition: transform 0.6s ease-in-out;
  }

  ${getSublevelOpenStyles};
`;

export const SublevelLine = styled.div`
  ${media.tablet} {
    position: absolute;
    left: 13px;
    right: 13px;
    bottom: 0;
    height: 1px;
    background-color: ${COLORS.BLACK};
  }
`;

export const SublevelClose = styled.button`
  ${typography({ size: FONT_SIZE.XS })};
  position: absolute;
  left: 0;
  top: 22px;
  width: 44px;
  height: 44px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  padding: 0;

  ${media.tablet} {
    right: 0;
    left: auto;
    height: 34px;
    top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background-color: ${COLORS.WHITE};
    z-index: -1;
  }

  &:after {
    content: '\\2191';

    ${media.tablet} {
      content: '\\2715';
    }
  }

  &:hover {
    &:before {
      background-color: ${COLORS.WILD_SAND};
    }
  }
`;

export const CancelLink = styled.button`
  padding: 9px 13px;
  font-weight: ${FONT_WEIGHT.BOOK};
  max-height: 42px;
  letter-spacing: ${LETTER_SPACING.M};

  ${media.tablet} {
    padding: 8px 11px 8px;
  }

  &:hover {
    background-color: ${COLORS.BLACK};
    color: ${COLORS.PALE_LEAF};
  }
`;

const showSearchStyles = css`
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 100%, 0);

  ${media.tablet} {
    transform: unset;
  }
`;

const hideSearchStyles = css`
  opacity: 0;
  pointer-events: none;
`;

const getShowSearchStyles = ifElse(propEq('isSearch', true), always(showSearchStyles), always(hideSearchStyles));

export const SearchItem = styled.li<{ isSearch: boolean }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 272px;
  width: 362px;
  top: 0;
  transition: opacity 0.4s ease-in-out;
  height: 100%;

  ${getShowSearchStyles};
`;

export const LoaderContainer = styled.div<{ searchLoading: boolean }>`
  position: fixed;
  top: 98px;
  left: 10px;
  width: calc(100% - 20px);

  ${media.mobile} {
    left: 12.5vw;
    width: 75vw;
  }

  ${media.tablet} {
    top: ${({ searchLoading }) => (searchLoading ? 68 : 119)}px;
    left: 0;
    width: 100%;
  }
`;

export const BagCount = styled.span`
  margin-left: 8px;
`;

const getMobileNavigationBackgroundStyles = ifElse(
  propEq('headerTop', HEADER_TOP.PDP),
  always(
    css`
      background-color: transparent;
    `
  ),
  ifElse(
    isOpen,
    always(css`
      background-color: ${COLORS.WHITE};
      backdrop-filter: blur(24px);
      color: ${COLORS.BLACK};
    `),
    always(
      css<NavigationProps>`
        backdrop-filter: blur(24px);
        background-color: ${({ navbarColor }) => {
          switch (true) {
            case !!navbarColor:
              return navbarColor;
            default:
              return `${COLORS.WHITE40}`;
          }
        }};
        color: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
        svg {
          fill: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
          stroke: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
        }
        path {
          stroke: ${(props) => props.navbarTextColor || `${COLORS.BLACK}`};
        }
      `
    )
  )
);

export const MobileNavigation = styled.ul<{ headerTop: number } & NavigationProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;

  ${getMobileNavigationBackgroundStyles}

  ${media.tablet} {
    display: none;
  }
`;

export const MobileLogo = styled.li`
  margin-right: auto;
  padding: 9px 16px;
  box-sizing: border-box;
`;

export const MobileLogoLink = styled.a`
  ${linkStyles};
  ${typography({})};
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`;

export const MenuItem = styled.li<NavigationProps>`
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: ${OFFSET.M};

  svg {
    width: 17px;
    height: 17px;
  }
`;

export const DesktopMenuItem = styled.div<NavigationProps>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 2px;
  margin-left: 2px;

  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
  }
`;

export const MobileIconButton = styled.button`
  display: flex;
`;

export const MobileBagButtonInner = styled.button`
  margin-top: 1px;
  position: relative;
`;

export const DesktopBagButtonInner = styled.button`
  margin-top: -1px;
  position: relative;
`;

export const MobileBagCount = styled.span`
  position: absolute;
  left: 0;
  top: 8px;
  color: ${COLORS.BLACK};
  font-size: 8px;
  width: 100%;
  text-align: center;
`;

export const DesktopBagCount = styled.span`
  position: absolute;
  left: 0;
  top: 6.5px;
  color: ${COLORS.BLACK};
  font-size: 8px;
  width: 100%;
  text-align: center;
`;

export const MobileMenuSublevel = styled.div<{ openMobileMenu: boolean }>`
  position: absolute;
  top: ${OFFSET.M};
  width: 100%;
  height: calc(var(--vh) - 44px - 20px);
  left: 0;
  pointer-events: ${({ openMobileMenu }) => (openMobileMenu ? 'auto' : 'none')};
  overflow: hidden;
  z-index: ${Z_INDEX.SUBLEVEL};
  opacity: 1 !important;
  transform: translateY(0px) translateZ(0) !important;
`;

export const MobileMenuSublevelInner = styled.div<{ openMobileMenu: boolean; openSublevel: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: -100%;
  height: 100%;
  width: 100%;
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  background-color: ${({ openSublevel }) => (openSublevel ? COLORS.SILVER : COLORS.WHITE)};
  transform: ${({ openMobileMenu }) => (openMobileMenu ? 'translate3d(0, 100%, 0)' : 'none')};
  overflow: auto;
`;

export const MobilePrimaryNavigation = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 40px;

  a {
    ${typography({ size: FONT_SIZE.S })};
  }
`;

export const MobileSecondaryNavigation = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
`;

export const MobileSearchContainer = styled(motion.li)`
  height: ${OFFSET.M};
  width: 100%;
  display: flex;
  z-index: -1;
  position: absolute;
  top: 0;
  background: ${COLORS.GALLERY};
`;

export const MobileHeaderNavigation = styled.div`
  display: block;
  outline: none;

  ${media.tablet} {
    display: none;
  }
`;

export const DesktopHeaderNavigation = styled.div`
  display: none;
  z-index: 3;

  ${media.tablet} {
    display: block;
  }
`;

export const AccountLinkLabelPrefix = styled.span`
  display: inline;
  padding-right: 4px;

  ${media.tablet} {
    display: none;
  }
`;

export const MobileHeaderDropdown = styled.ul`
  list-style: none;

  li {
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 0.7;
    }
  }
`;
