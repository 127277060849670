import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Content = styled.div`
  ${typography({ spacing: LETTER_SPACING.M })};
  height: 100%;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.WILD_SAND};
  border-top: none;

  > div {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 20px 0 0 40px;
    margin: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    > div {
      overflow: visible;
    }

    ${media.tablet} {
      padding: 20px 0 0 0;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 13px;
    right: 13px;
    top: 0;
    height: 1px;
    background-color: ${COLORS.BLACK10};
  }
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  top: ${OFFSET.M};
  width: 100%;
  height: 170px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  overflow-x: auto;
  z-index: 1;

  ${Content} {
    transform: ${({ isOpen }) => (isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)')};
    transition: ${({ isOpen }) =>
      isOpen
        ? 'transform 0.3s ease-in-out, visibility 0s ease-in-out 0s'
        : 'transform 0.3s ease-in-out, visibility 0s ease-in-out 0.3s'};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  }
`;

export const Information = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 30px 30px;
  text-align: center;
`;
