import React from 'react';

import { BLOCK_TYPE, SIMPLE_LEGAL_BLOCK_TITLE } from '@components/blocks/blocks.constants';

import { Container, Title, Content } from './simpleLegalBlock.styles';

export interface SimpleLegalBlockProps {
  title: string;
  titleLineDisplayOption: SIMPLE_LEGAL_BLOCK_TITLE;
  body: string;
}

export const SimpleLegalBlock = ({ title, titleLineDisplayOption, body }: SimpleLegalBlockProps) => {
  return (
    <Container data-testid={BLOCK_TYPE.SIMPLE_LEGAL_BLOCK}>
      <Title displayOption={titleLineDisplayOption}>{title}</Title>
      <Content dangerouslySetInnerHTML={{ __html: body }} />
    </Container>
  );
};
