import Router, { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { find, propEq } from 'ramda';
import { useEffectOnce } from 'react-use';
import { useEffect } from 'react';

import { uiActions, uiSelectors } from '@modules/ui';
import { usePersist } from '@hooks/usePersist';
import { MAIN_ANIMATION } from '@utils/constants';

export const useScrollRestoration = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { isRehydrated } = usePersist();
  const scrollRestorations = useSelector(uiSelectors.selectScrollRestorations);

  useEffectOnce(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
      dispatch(uiActions.setScrollRestored(false));

      const setScrollRestoration = (nextUrl: string, { shallow }: { shallow: boolean }) => {
        if (shallow) {
          return;
        }

        dispatch(
          uiActions.setScrollRestoration({ url: Router.router?.asPath || '', x: window.scrollX, y: window.scrollY })
        );
      };

      Router.events.on('routeChangeStart', setScrollRestoration);

      return () => {
        Router.events.off('routeChangeStart', setScrollRestoration);
      };
    }
  });

  useEffect(() => {
    if (isRehydrated && router.isReady) {
      const scrollRestoration = find(propEq('url', Router.router?.asPath), scrollRestorations);
      if (scrollRestoration) {
        setTimeout(() => {
          window.scrollTo(scrollRestoration.x, scrollRestoration.y);
          dispatch(uiActions.setScrollRestored(true));
        }, MAIN_ANIMATION.DURATION * 1000);

        return;
      }
    }

    dispatch(uiActions.setScrollRestored(true));
  }, [isRehydrated, router.isReady]);
};
