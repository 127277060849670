import styled, { keyframes } from 'styled-components';

import { COLORS } from '@theme/colors';

import { LOADING_OVERLAY_VARIANT, LoadingOverlayProps } from './loadingOverlay.component';

const dotLoadingAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Overlay = styled.div<LoadingOverlayProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ variant }) => (variant === LOADING_OVERLAY_VARIANT.WHITE ? COLORS.WHITE80 : COLORS.BLACK)};

  &:after {
    background-color: ${({ variant }) => (variant === LOADING_OVERLAY_VARIANT.WHITE ? COLORS.BLACK : COLORS.WHITE)};
    content: '';
    display: block;
    z-index: 1;
    width: 6px;
    height: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -3px;
    margin-top: -3px;
    border-radius: 50%;
    animation: ${dotLoadingAnimation} 1s ease-in-out infinite;
  }

  &:hover {
    cursor: wait;
  }
`;
