import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-height: 100%;
  margin-top: 3%;
   @media (max-width: 768px) {
    margin-top: 5%;
  }
`;

export const Image = styled.img<{ blockWidth: string }>`
  cursor: pointer;
  object-fit: cover;
  width: ${({ blockWidth }) => blockWidth};
  max-width: ${({ blockWidth }) => blockWidth};
  max-height: 100%; 
  height: auto; 
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    max-height: 100%; 
    height: auto; 
  }
`;

export const CenteredTextContainer = styled.div<{
  calculatedWidth: number;
  textAlign: string;
  backgroundColor: string;
  fontSize: string;
}>`
  font-size: ${({ fontSize }) => fontSize};
  width: ${({ calculatedWidth }) => calculatedWidth}%;
  max-width: ${({ calculatedWidth }) => calculatedWidth}%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: ${({ textAlign }) => textAlign};
  text-align: ${({ textAlign }) => textAlign};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  padding: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  pointer-events: none;
  max-height: 100%;
  overflow: hidden;
  word-wrap: break-word; 
  box-sizing: border-box;
  display: block;

   @media (max-width: 768px) {
    width: 75%;
    max-width: 75%;
    max-height: 100%;
    font-size: ${({ fontSize }) => (fontSize === '48px' || fontSize === '72px' ? '32px' : fontSize)};
    overflow-y: auto;
    word-wrap: break-word; 
    padding: 20px; 
    box-sizing: border-box;
  }
`;
