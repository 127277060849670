import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';
import { always } from 'ramda';
import NextLink from 'next/link';

import { Button, BUTTON_SIZE, Error, formMessages, TextField } from '@components/form';
import { useYupValidationResolver } from '@hooks/useYup';
import api from '@services/nextAPI';
import reportError from '@utils/reportError';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { MainAnimateContainer } from '@components/mainAnimateContainer';
import { HOME_ROUTE } from '@utils/constants';
import { useTrackConversions } from '@hooks/useTrackConversions';
import { CONVERSIONS_EVENTS, CONVERSIONS_STATUS } from '@definitions/conversions.types';

import { Container, Content, Link, ButtonContainer, Description, RightContent } from './newsletterFooter.styles';

export const NEWSLETTER_EMAIL = 'email';

interface FormProps {
  [NEWSLETTER_EMAIL]: string;
}

export const NewsletterFooter = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { sendConversion } = useTrackConversions();
  const validationSchema = useMemo(
    () =>
      yup.object({
        [NEWSLETTER_EMAIL]: yup
          .string()
          .email(intl.formatMessage(formMessages.emailError))
          .required(intl.formatMessage(formMessages.required)),
      }),
    []
  );
  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit, control } = useForm<FormProps>({
    defaultValues: {
      [NEWSLETTER_EMAIL]: '',
    },
    resolver,
  });
  const { errors } = useFormState({ control });

  const handleNewsletterSubmit = async ({ email }: FormProps) => {
    try {
      setLoading(true);
      setError(false);
      await api.post('email/subscribe', { email, tags: ['Footer Newsletter'] });
      setSuccess(true);
      await sendConversion({
        eventType: CONVERSIONS_EVENTS.COMPLETE_REGISTRATION,
        status: CONVERSIONS_STATUS.NEWSLETTER,
      });
    } catch (e) {
      setError(true);
      reportError(e);
    } finally {
      setLoading(false);
    }
  };

  const renderError = renderWhenTrue(
    always(
      <Error>
        <FormattedMessage {...formMessages.generalError} />
      </Error>
    )
  );

  const renderForm = renderWhenTrueOtherwise(
    always(
      <MainAnimateContainer key="form">
        <Content>
          <Description>
            <FormattedMessage
              id="newsletterFooter.subscribe"
              defaultMessage="Subscribe to our newsletter for first access to new artworks & exclusive artist collaborations."
            />
          </Description>
          <form onSubmit={handleSubmit(handleNewsletterSubmit)}>
            <RightContent>
              <div>
                <TextField
                  nameSuffix="-footer"
                  register={register(NEWSLETTER_EMAIL)}
                  error={errors[NEWSLETTER_EMAIL]}
                  placeholder={intl.formatMessage(formMessages.email)}
                />
                {renderError(error)}
              </div>
              <ButtonContainer>
                <Button type="submit" disabled={loading} size={BUTTON_SIZE.L}>
                  <FormattedMessage id="newsletterFooter.signUp" defaultMessage="SIGN UP" />
                </Button>
              </ButtonContainer>
            </RightContent>
          </form>
        </Content>
      </MainAnimateContainer>
    ),
    always(
      <MainAnimateContainer key="successMessage">
        <Content>
          <div>
            <FormattedMessage id="newsletterFooter.successTitle" defaultMessage="Subscription confirmed" />
          </div>
          <RightContent>
            <div>
              <FormattedMessage
                id="newsletterFooter.description"
                defaultMessage="Thanks for joining us! You're on the list for first access to new artworks and exclusive content.
"
              />
            </div>
            <ButtonContainer>
              <a
                onClick={() => {
                  window.history.pushState({}, '', HOME_ROUTE);
                }}
                href={HOME_ROUTE}
              >
                <Link size={BUTTON_SIZE.L} onClick={() => setSuccess(false)}>
                  <FormattedMessage id="newsletterFooter.returnHome" defaultMessage="DISCOVER NOW →" />
                </Link>
              </a>
            </ButtonContainer>
          </RightContent>
        </Content>
      </MainAnimateContainer>
    )
  );

  return (
    <Container>
      <AnimatePresence initial={false} exitBeforeEnter>
        {renderForm(!success)}
      </AnimatePresence>
    </Container>
  );
};
