import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { always } from 'ramda';
import { useEffectOnce } from 'react-use';
import NextLink from 'next/link';

import { renderWhenTrue } from '@utils/rendering';
import { formatCartPrice } from '@utils/helpers';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useHeaderSublevel } from '@hooks/useUi';
import { GiftCertificate, RemoveCartPayload } from '@definitions/shoppingCart.types';
import { ROUTE } from '@utils/constants';

import {
  Container,
  GiftCardWrapper,
  GiftCard,
  Details,
  QuantityContainer,
  Quantity,
  RemoveButton,
  NameContainer,
  ProductTitle,
  PriceContainer,
  Price,
  Artist,
  GiftCardCopyWrapper,
  GiftCardCopy,
} from './shoppingCartItem.styles';

export interface ShoppingCartGiftCertificateProps {
  giftCertificate: GiftCertificate;
  removeItem: (data: RemoveCartPayload) => void;
  index: number;
}

export const ShoppingCartGiftCertificate = ({
  giftCertificate: { amount, name, quantity, image, id },
  removeItem,
  index,
}: ShoppingCartGiftCertificateProps) => {
  const { isMobile } = useBreakpoint();
  const intl = useIntl();
  const { closeHeaderSublevel } = useHeaderSublevel();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const renderPrice = renderWhenTrue(() => <Price>{!!amount && formatCartPrice(amount)}</Price>);
  const giftCardCopy = intl.formatMessage({ id: 'shoppingCartItem.giftCard', defaultMessage: 'Gift Card' });
  const renderRemoveButton = renderWhenTrue(
    always(
      <RemoveButton onClick={() => removeItem({ itemId: id })}>
        <FormattedMessage id="shoppingCartItem.removeButton" defaultMessage="Remove" />
      </RemoveButton>
    )
  );

  useEffectOnce(() => {
    if (!index) {
      linkRef.current?.focus();
    }
  });

  return (
    <Container key={index} data-testid={name}>
      <Details>
        <QuantityContainer>
          <Quantity>{quantity}</Quantity>
          {renderRemoveButton(!isMobile)}
        </QuantityContainer>
        <NameContainer>
          <Artist>{giftCardCopy}:</Artist>
          <ProductTitle>
            <i>{name}</i>
          </ProductTitle>
          {renderRemoveButton(isMobile)}
        </NameContainer>
        <PriceContainer>{renderPrice(!!amount)}</PriceContainer>
      </Details>
      <GiftCardWrapper>
        <a
          onClick={() => {
            window.history.pushState({}, '', ROUTE.GIFT_CARD);
          }}
          href={ROUTE.GIFT_CARD}
        >
          <GiftCard backgroundImage={image} onClick={closeHeaderSublevel}>
            <GiftCardCopyWrapper>
              <GiftCardCopy>
                <div>{giftCardCopy}</div>
                <div>${amount}</div>
              </GiftCardCopy>
            </GiftCardCopyWrapper>
          </GiftCard>
        </a>
      </GiftCardWrapper>
    </Container>
  );
};
