import styled, { css, keyframes } from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const shoppingCartFontStyles = css`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};

  ${media.tablet} {
    ${typography({ spacing: LETTER_SPACING.M })};
  }
`;

export const Container = styled.div<{ isEmptyCart: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isEmptyCart }) => (isEmptyCart ? 'flex' : 'block')};
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
  padding: 0 13px;
  overflow-y: auto;
`;

export const Title = styled.div`
  ${shoppingCartFontStyles};
  border-bottom: 1px solid ${COLORS.BLACK};
  text-align: center;
  margin-bottom: ${OFFSET.M};
  background-color: ${COLORS.WHITE};
  text-transform: uppercase;
  margin-top: ${OFFSET.XS};
  padding-bottom: 6px;
  display: none;

  ${media.tablet} {
    display: block;
  }
`;

export const MobileTopLine = styled.div`
  height: 1px;
  border-bottom: 1px solid ${COLORS.BLACK};
  margin-bottom: 44px;
  display: block;

  ${media.tablet} {
    display: none;
  }
`;

export const ProductsContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
`;

export const TotalContainer = styled.div`
  position: sticky;
  bottom: 48px;
  border-top: 1px solid ${COLORS.BLACK};
  background-color: ${COLORS.WHITE};
  padding: 8px 0 44px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const TotalNote = styled.p`
  ${shoppingCartFontStyles};
  margin: 0 0 ${OFFSET.XS} 0;

  ${media.tablet} {
    margin: 0 ${OFFSET.L} 0 0;
  }
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-shrink: 0;

  ${media.tablet} {
    width: 172px;
  }
`;

export const TotalCaption = styled.span`
  ${shoppingCartFontStyles};
`;

export const TotalPrice = styled.span`
  ${shoppingCartFontStyles};
`;

export const CheckoutButtonWrapper = styled.div`
  width: 100%;
  padding-bottom: 14px;
  position: sticky;
  bottom: 0;
  background-color: ${COLORS.WHITE};

  ${media.tablet} {
    padding-bottom: ${OFFSET.S};
  }
`;

const checkoutButtonHoverAnimation = keyframes`
  0% { 
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0); 
  }
`;

export const CheckoutBackground = styled.div`
  background: repeating-linear-gradient(to right, ${COLORS.BLACK} 0%, ${COLORS.SILVER} 3%, ${COLORS.BLACK} 6%);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 200%;
  display: none;
`;

export const CheckoutCopy = styled.span`
  position: relative;
`;

export const CheckoutButton = styled.button.attrs({ type: 'button' })`
  ${shoppingCartFontStyles};
  position: sticky;
  min-height: ${OFFSET.L};
  width: 100%;
  background: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  text-transform: uppercase;
  box-sizing: border-box;
  border: 1px solid ${COLORS.BLACK};
  overflow: hidden;

  &:hover {
    ${CheckoutBackground} {
      display: block;
      animation: ${checkoutButtonHoverAnimation} 2s linear infinite;
    }
  }
`;

export const PaymentProviders = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${OFFSET.L};

  svg {
    height: ${OFFSET.XS};
    padding-right: ${OFFSET.S};
  }
`;

export const Message = styled.span`
  ${typography({})};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
`;

export const Errors = styled.ul`
  border: 1px solid ${COLORS.GUARDSMAN_RED};
  color: ${COLORS.GUARDSMAN_RED};
  padding: 5px 5px 5px 25px;
  margin: 0;
`;

export const Error = styled.li``;
