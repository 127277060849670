import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { Link as FormLink } from '@components/form';
import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';
import { LETTER_SPACING, typography } from '@theme/font';

export const Container = styled.div`
  border-top: 1px solid ${COLORS.BLACK};
  padding: ${OFFSET.M} 0 ${OFFSET.S};
`;

export const Content = styled.div`
  ${typography({ spacing: LETTER_SPACING.S })};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${OFFSET.S};
  min-height: 52px;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${media.desktop} {
    grid-template-columns: 396px auto;
  }
`;

export const RightContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${OFFSET.S};

  ${media.mobile} {
    grid-template-columns: 1fr auto;
  }

  ${media.tablet} {
    grid-template-columns: 340px 1fr;
  }

  ${media.desktop} {
    grid-template-columns: 380px auto;
  }
`;

export const Link = styled(FormLink)`
  display: inline-block;
`;

export const ButtonContainer = styled.div`
  padding-bottom: ${OFFSET.S};

  button,
  a {
    white-space: nowrap;
  }
`;

export const Description = styled.div`
  ${media.desktop} {
    padding-right: ${OFFSET.M};
  }
`;
