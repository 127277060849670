import { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const useTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffectOnce(() => {
    // @ts-ignore
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
  });

  return { isTouchDevice };
};
