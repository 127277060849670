import { useEffect, useState } from 'react';

// all calculations are based on milliseconds
const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_HOUR = 1000 * 60 * 60;
const ONE_MINUTE = 1000 * 60;
const ONE_SECOND = 1000;

type UseCountDownOutput = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  hasExpired: boolean;
  noDays: boolean;
  noHours: boolean;
  noMinutes: boolean;
};

type UseCountDownProps = {
  targetDate: string;
  interval?: number;
};

export function useCountDown({ targetDate, interval = ONE_HOUR }: UseCountDownProps): UseCountDownOutput {
  const now: number = new Date().getTime();
  const timezoneOffset = new Date().getTimezoneOffset() * ONE_MINUTE;
  const countDownDate: number = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState<number>(countDownDate - now + timezoneOffset);
  const [currentInterval, setCurrentInterval] = useState<number>(interval);
  const { days, hours, minutes, seconds, noDays, noHours, noMinutes } = formatData(countDown);

  useEffect(() => {
    if (noDays) setCurrentInterval(ONE_HOUR);
    if (noHours) setCurrentInterval(ONE_MINUTE);
    if (noMinutes) setCurrentInterval(ONE_SECOND);
  }, [days, hours, minutes, seconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now: number = new Date().getTime();
      setCountDown(countDownDate - now + timezoneOffset);
    }, currentInterval);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return formatData(countDown);
}

function formatData(input: number): UseCountDownOutput {
  const { floor } = Math;
  const days = floor(input / ONE_DAY);
  const hours = floor((input % ONE_DAY) / ONE_HOUR);
  const minutes = floor((input % ONE_HOUR) / ONE_MINUTE);
  const seconds = floor((input % ONE_MINUTE) / ONE_SECOND);
  const hasExpired = days + hours + minutes + seconds <= 0;
  const noDays = days <= 0;
  const noHours = days <= 0 && hours <= 0;
  const noMinutes = days <= 0 && hours <= 0 && minutes <= 0;

  return { days, hours, minutes, seconds, hasExpired, noDays, noHours, noMinutes };
}
