import React, { FormEvent, useEffect, useReducer } from 'react';

import Modal from '@components/modal';
import { NotifyForm } from '@components/notifyForm';

const copy = {
  title: 'Waitlist:',
  infoForm:
    'Provide your email address to get updates from Platform and to be alerted if this or similar works become available.',
  infoConfirmation: "You've been added to the waitlist.",
  submit: 'ADD TO WAITLIST',
};

const copyPrelaunchable = {
  title: 'Coming soon:',
  infoForm:
    'This work is coming soon to Platform. Provide your email address for early access to this and other artworks. ',
  infoConfirmation: 'You will be notified when this artwork is available.',
  submit: 'NOTIFY ME',
};

const copyGetInTouch = {
  title: 'Get in touch:',
  infoForm: "This artwork is not currently live on Platform. Sign up and we'll let you if it's still available.",
  infoConfirmation: 'We will get in touch if this artwork is available.',
  submit: 'GET IN TOUCH',
};

interface WaitlistModalProps {
  onModalClose: () => void;
  onFormSubmit: (
    event: FormEvent,
    { email, firstName, lastName }: { email: string; firstName: string; lastName: string }
  ) => void;
  isModalOpen: boolean;
  isConfirmationVisible: boolean;
  isPrelaunchable?: boolean;
  shouldOverridePrelaunchableCopy?: boolean;
  isPostSellingDate?: boolean;
  texts?: {
    title: string;
    infoForm: string;
    infoConfirmation: string;
    submit: string;
  };
  initialFields?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    isChecked?: boolean;
  };
}

export const WaitlistModal = ({
  onModalClose,
  onFormSubmit,
  isModalOpen,
  isConfirmationVisible,
  initialFields,
  isPrelaunchable,
  shouldOverridePrelaunchableCopy,
  isPostSellingDate,
}: WaitlistModalProps) => {
  let texts = copy;

  switch (true) {
    case isPostSellingDate:
      texts = copyGetInTouch;
      break;
    case shouldOverridePrelaunchableCopy:
      texts = copy;
      break;
    case isPrelaunchable:
      texts = copyPrelaunchable;
      break;
  }

  return (
    <Modal
      wrapperId="react-portal-waitlist-modal-container"
      headerText={texts.title}
      handleOnClose={onModalClose}
      isOpen={isModalOpen}
    >
      <NotifyForm
        onFormSubmit={onFormSubmit}
        isConfirmationVisible={isConfirmationVisible}
        initialFields={initialFields}
        texts={{
          info: texts.infoForm,
          submit: texts.submit,
          confirmation: texts.infoConfirmation,
        }}
      />
    </Modal>
  );
};
