import React from 'react';

import { LoadingOverlay } from '@components/loadingOverlay';
import { LOADING_OVERLAY_VARIANT } from '@components/loadingOverlay/loadingOverlay.component';
import { renderWhenTrue } from '@utils/rendering';

import { Container, Value } from './quantityPicker.styles';
import { QuantityButton } from './quantityButton';
import { ICON_VARIANT } from './quantityButton/quantityButton.component';

export interface QuantityPickerProps {
  quantity: number;
  onIncrease: () => void;
  onDecrease: () => void;
  inventoryLevel: number;
  isLoading: boolean;
  maximumUnitsPerCart: number;
}

export const QuantityPicker = ({
  quantity,
  onIncrease,
  onDecrease,
  inventoryLevel,
  isLoading,
  maximumUnitsPerCart,
}: QuantityPickerProps) => {
  const isDecreaseDisabled = quantity === 1;
  const isIncreaseDisabled = quantity === inventoryLevel || quantity >= maximumUnitsPerCart;
  const renderWarning = (warningSuffix: string) => `You cannot select ${warningSuffix}`;
  const decreaseWarning = 'less than 1';
  const increaseWarning = `more than ${Math.min(maximumUnitsPerCart, inventoryLevel)}`;

  const renderLoader = renderWhenTrue(() => <LoadingOverlay variant={LOADING_OVERLAY_VARIANT.WHITE} />);

  return (
    <Container>
      <QuantityButton
        iconVariant={ICON_VARIANT.MINUS}
        onClick={onDecrease}
        isDisabled={isDecreaseDisabled}
        warning={renderWarning(decreaseWarning)}
      />

      <Value>{quantity}</Value>

      <QuantityButton
        iconVariant={ICON_VARIANT.PLUS}
        onClick={onIncrease}
        isDisabled={isIncreaseDisabled}
        warning={renderWarning(increaseWarning)}
      />
      {renderLoader(isLoading)}
    </Container>
  );
};
