import React, { useCallback } from 'react';
import NextLink from 'next/link';
import { always } from 'ramda';
import { format } from 'date-fns';

import { BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { useImage } from '@hooks/useImage';
import { renderWhenTrueOtherwise } from '@utils/rendering';
import { ArticleListItem } from '@definitions/articles.types';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { ellipsisText } from '@utils/helpers';
import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';

import LogoSVG from '../../../assets/images/logo.svg';

import {
  Container,
  InnerContainer,
  Link,
  Content,
  Title,
  Description,
  Thumbnail,
  Image,
  Placeholder,
  ContentMobile,
  ThumbnailMobile,
  ImageWrapper,
} from './archivedEditorialBlock.styles';

export const PLACEHOLDER_TEST_ID = 'placeholderTestId';

export const ArchivedEditorialBlock = ({
  image: desktopImage,
  mobileImage,
  title,
  description,
  slug,
  firstPublishedAt,
  category,
}: ArticleListItem) => {
  const image = useImage({ desktopImage, mobileImage });

  const { isMobile } = useBreakpoint();

  const renderThumbnail = renderWhenTrueOtherwise(
    always(
      <ImageWrapper>
        <OptimisedImageWrapper>
          <Image src={image.src} alt={image?.alt} />
        </OptimisedImageWrapper>
      </ImageWrapper>
    ),
    always(
      <Placeholder data-testid={PLACEHOLDER_TEST_ID}>
        <LogoSVG />
      </Placeholder>
    )
  );

  const formatDate = (date: string | undefined) => {
    if (date) {
      return format(new Date(date), 'MM.dd.yyyy');
    }
  };

  const formatCategory = useCallback((category: string | undefined) => {
    switch (category) {
      case 'Service':
        return '101';
      default:
        return category;
    }
  }, []);

  return (
    <Container data-testid={BLOCK_TYPE.ARCHIVED_EDITORIAL_BLOCK}>
      <a
        href={`/${slug || ''}`}
        onClick={() => {
          window.history.pushState({}, '', slug);
        }}
      >
        <Link>
          {isMobile ? (
            <InnerContainer>
              <ContentMobile>
                <Title>{title}</Title>
                <Description dangerouslySetInnerHTML={{ __html: ellipsisText(description, 70) }} />
                <span>{formatCategory(category)}</span>
              </ContentMobile>
              <ContentMobile>
                <ThumbnailMobile>{renderThumbnail(!!desktopImage)}</ThumbnailMobile>
                <span>{formatDate(firstPublishedAt)}</span>
              </ContentMobile>
            </InnerContainer>
          ) : (
            <InnerContainer>
              <Content>
                <Thumbnail>{renderThumbnail(!!desktopImage)}</Thumbnail>
              </Content>
              <Content>
                <span>{formatDate(firstPublishedAt)}</span>
                <span>{formatCategory(category)}</span>
              </Content>
              <Content>
                <Title>{title}</Title>
              </Content>
              <Content>
                <Description dangerouslySetInnerHTML={{ __html: description }} />
              </Content>
            </InnerContainer>
          )}
        </Link>
      </a>
    </Container>
  );
};
