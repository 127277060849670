import qs from 'querystring';

import React, { ReactNode, useEffect, useState } from 'react';
import NextLink from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import { always, cond, includes, isEmpty, omit, pathOr, propEq, T, values } from 'ramda';
import { useRouter } from 'next/router';
import { useAsync, useWindowScroll } from 'react-use';
import { useSelector } from 'react-redux';

import { ShoppingCart } from '@components/shoppingCart';
import { LoginForm } from '@components/loginForm';
import { ShippingCountries } from '@components/shippingCountries';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { useHeaderSublevel } from '@hooks/useUi';
import { DROPDOWN_QUERY_PARAM, HOME_ROUTE, ROUTE } from '@utils/constants';
import { Loader } from '@components/loader';
import { useFetchCart } from '@hooks/useShoppingCart';
import reportError from '@utils/reportError';
import { useHeaderMobileMenu } from '@hooks/useUi/useUi';
import { preventTabIndex } from '@utils/helpers';
import { headerMessages } from '@components/header/header.messages';
import { FavoriteDropdown } from '@components/favoriteDropdown';
import { DROPDOWN_TYPES } from '@components/PLP/artworksView/artworksView.component';
import { uiSelectors } from '@modules/ui';
import { FocusTrap } from '@components/common';
import { usePersist } from '@hooks/usePersist';
import { CART_RECOVERY_PARAM } from '@modules/shoppingCart/shoppingCart.constants';
import { useFetchArtworks } from '@hooks/useArtworks';
import { FeatureNavbar } from '@definitions/articles.types';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useCustomer } from '@hooks/useCustomer';
import { artworksDomain } from '@modules/artworks/artworks.selectors';
import { ACTION_PREFIX, PLP_VARIANT } from '@modules/plp/plp.constants';
import { ArtworksState } from '@definitions/artworks.types';
import { GlobalState } from '@modules/createStore';

import IconAccount from '../../assets/images/account-transparent.svg';

import {
  AccountLinkLabelPrefix,
  Container,
  HeaderBackground,
  HeaderContainer,
  List,
  NavigationLink,
  NavigationButton,
  LoaderContainer,
  DesktopMenuItem,
} from './header.styles';
import { HEADER_TEST_ID, HEADER_TOP, SUBLEVEL } from './header.constants';
import { DesktopHeader } from './desktopHeader';
import { MobileHeader } from './mobileHeader';

export interface HeaderProps {
  headerTop: HEADER_TOP;
  featureNavbar?: FeatureNavbar;
  childrenSize?: number;
  children?: ReactNode;
  isChildrenOpen?: boolean;
  handleChildrenClose?: () => void;
}

export const Header = ({
  headerTop,
  children,
  featureNavbar,
  childrenSize = 0,
  isChildrenOpen = false,
  handleChildrenClose = () => ({}),
}: HeaderProps) => {
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<DROPDOWN_TYPES | null>(null);
  const { isAuthed } = useCustomer();
  const { isMobile } = useBreakpoint();
  const { loading: artworksLoading, artworks } = useFetchArtworks(PLP_VARIANT.ARTWORKS);
  const previousPageUrl = useSelector(uiSelectors.selectPreviousPageUrl);
  const [yPos, setYPos] = useState(0);
  const [hide, setHide] = useState(false);
  const intl = useIntl();
  const router = useRouter();
  const { pathname } = router;
  const isAllArtworksLoading = router.route === ROUTE.ARTWORKS && artworksLoading;
  const {
    sublevel,
    sublevelOpen,
    openHeaderSublevel,
    closeHeaderSublevel,
    preventRedirectAfterLogin,
  } = useHeaderSublevel();
  const { isHeaderMobileMenuOpen, setHeaderMobileMenuOpen } = useHeaderMobileMenu();
  const isSearch = propEq('route', ROUTE.SEARCH, router);
  const { y } = useWindowScroll();
  const { fetchCart } = useFetchCart();
  const { isRehydrated } = usePersist();
  const isFavoriteOpen = dropdownOpen === DROPDOWN_TYPES.FAVOURITE;

  useEffect(() => {
    closeAllSublevels();
  }, [router]);

  useAsync(async () => {
    try {
      if (isRehydrated) {
        const { query, pathname } = router;
        const cartId = query[CART_RECOVERY_PARAM];

        if (cartId) {
          const newQuery = omit([CART_RECOVERY_PARAM], query);
          const queryString = isEmpty(newQuery) ? pathname : `${pathname}?${qs.stringify(newQuery)}`;
          history.replaceState(history.state, '', queryString);
        }

        await fetchCart(cartId);

        if (cartId) {
          openHeaderSublevel({ sublevel: SUBLEVEL.BAG });
        }
      }
    } catch (e) {
      const error = e as { cart: string; cartRecoveryId: string };
      if (error.cart !== null) {
        reportError(e);
        return;
      }

      if (error.cartRecoveryId) {
        openHeaderSublevel({ sublevel: SUBLEVEL.BAG });
      }
    }
  }, [isRehydrated]);

  useEffect(() => {
    if (yPos < y && y > 10 && !(sublevelOpen || isChildrenOpen)) {
      setHide(true);
    } else {
      setHide(false);
    }
    setYPos(y);
  }, [y]);

  useEffect(() => {
    if (sublevelOpen || isHeaderMobileMenuOpen || isChildrenOpen) {
      setHide(false);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [sublevelOpen, isHeaderMobileMenuOpen, isChildrenOpen]);

  useEffect(() => {
    if (router.isReady) {
      setTimeout(() => {
        const sublevel = pathOr(null, ['query', DROPDOWN_QUERY_PARAM], router);
        const isSublevel = includes(sublevel, values(SUBLEVEL));

        if (isSublevel && sublevel) {
          openHeaderSublevel({ sublevel });
          const { query, pathname } = router;
          const newQuery = omit([DROPDOWN_QUERY_PARAM], query);
          const queryString = isEmpty(newQuery) ? pathname : `${pathname}?${qs.stringify(newQuery)}`;

          history.replaceState(history.state, '', queryString);
        }
      });
    }
  }, [router.isReady]);

  const toggleDropdown = (type: DROPDOWN_TYPES) =>
    type === dropdownOpen ? setDropdownOpen(null) : setDropdownOpen(type);

  const renderFavoriteDropdown = renderWhenTrue(always(<FavoriteDropdown isOpen={isFavoriteOpen} />));

  const renderChildren = () => (children ? <List>{children}</List> : null);

  const renderLoading = renderWhenTrue(
    always(
      <LoaderContainer searchLoading={loading}>
        <Loader />
      </LoaderContainer>
    )
  );

  const toggleOpenSublevel = (sublevelToOpen: SUBLEVEL) =>
    sublevelOpen && sublevel === sublevelToOpen
      ? closeHeaderSublevel()
      : openHeaderSublevel({ sublevel: sublevelToOpen });

  const closeAllSublevels = () => {
    setHeaderMobileMenuOpen(false);
    closeHeaderSublevel();
    handleChildrenClose();
  };

  const handleSearchCancel = () => {
    closeAllSublevels();

    if (previousPageUrl && previousPageUrl !== router.asPath) {
      return router.push(previousPageUrl);
    }

    return router.push(HOME_ROUTE);
  };

  const searchPlaceholder = intl.formatMessage(headerMessages.searchPlaceholder);

  const renderSublevel = cond([
    // @ts-ignore
    [propEq('sublevel', SUBLEVEL.FAVORITES), always(<FavoriteDropdown isOpen />)],
    // @ts-ignore
    [propEq('sublevel', SUBLEVEL.COUNTRY), always(<ShippingCountries />)],
    [
      // @ts-ignore
      propEq('sublevel', SUBLEVEL.ACCOUNT),
      ({ isMobile = false }) => (
        <LoginForm
          closeAllSublevels={closeAllSublevels}
          isMobile={isMobile}
          preventRedirectAfterLogin={preventRedirectAfterLogin}
        />
      ),
    ],
    // @ts-ignore
    [propEq('sublevel', SUBLEVEL.BAG), always(<ShoppingCart />)],
    [T, always(null)],
  ]) as ({ sublevel, isMobile }: { sublevel: SUBLEVEL | null; isMobile?: boolean }) => ReactNode;

  const renderAccountLink = renderWhenTrueOtherwise(
    always(
      <a
        onClick={() => {
          window.history.pushState({}, '', ROUTE.ACCOUNT);
        }}
        href={ROUTE.ACCOUNT}
      >
        <DesktopMenuItem
          onClick={closeAllSublevels}
          tabIndex={preventTabIndex(isSearch)}
          pathname={pathname}
          openSublevel={sublevelOpen}
        >
          <IconAccount />
        </DesktopMenuItem>
      </a>
    ),
    always(
      <DesktopMenuItem
        onClick={() => openHeaderSublevel({ sublevel: SUBLEVEL.ACCOUNT })}
        tabIndex={preventTabIndex(isSearch)}
        pathname={pathname}
        openSublevel={sublevelOpen}
      >
        <AccountLinkLabelPrefix>
          <FormattedMessage {...headerMessages.accountMobilePrefix} />
        </AccountLinkLabelPrefix>
        <IconAccount />
      </DesktopMenuItem>
    )
  );

  const renderAccountLinkMobile = renderWhenTrueOtherwise(
    always(
      <NextLink href={ROUTE.ACCOUNT} passHref>
        <NavigationLink onClick={closeAllSublevels} tabIndex={preventTabIndex(isSearch)} pathname={pathname}>
          <FormattedMessage {...headerMessages.account} />
        </NavigationLink>
      </NextLink>
    ),
    always(
      <NavigationButton
        onClick={() => openHeaderSublevel({ sublevel: SUBLEVEL.ACCOUNT })}
        tabIndex={preventTabIndex(isSearch)}
        pathname={pathname}
      >
        <AccountLinkLabelPrefix>
          <FormattedMessage {...headerMessages.accountMobilePrefix} />
        </AccountLinkLabelPrefix>
        <FormattedMessage {...headerMessages.account} />
      </NavigationButton>
    )
  );

  const renderFocusTrap = renderWhenTrue(always(<FocusTrap onFocus={closeAllSublevels} />));

  return (
    <Container onFocus={() => setHide(false)}>
      <HeaderBackground
        openSublevel={sublevelOpen || isHeaderMobileMenuOpen || isChildrenOpen}
        onClick={closeAllSublevels}
      />
      <HeaderContainer
        hide={hide}
        openSublevel={sublevelOpen}
        openMobileMenu={isHeaderMobileMenuOpen}
        animationSize={childrenSize}
        headerTop={headerTop}
        isSearch={isSearch}
        data-testid={HEADER_TEST_ID}
        pathname={pathname}
      >
        {renderFocusTrap(sublevelOpen)}
        <DesktopHeader
          searchPlaceholder={searchPlaceholder}
          handleSearchCancel={handleSearchCancel}
          setLoading={setLoading}
          sublevel={sublevel}
          closeAllSublevels={closeAllSublevels}
          renderSublevel={renderSublevel}
          renderAccountLink={renderAccountLink}
          toggleOpenSublevel={toggleOpenSublevel}
          sublevelOpen={sublevelOpen}
          pathname={pathname}
          featureNavbar={featureNavbar}
          toggleDropdown={toggleDropdown}
        />
        {isMobile && (
          <MobileHeader
            searchPlaceholder={searchPlaceholder}
            handleSearchCancel={handleSearchCancel}
            setLoading={setLoading}
            sublevel={sublevel}
            closeAllSublevels={closeAllSublevels}
            renderSublevel={renderSublevel}
            renderAccountLinkMobile={renderAccountLinkMobile}
            toggleOpenSublevel={toggleOpenSublevel}
            sublevelOpen={sublevelOpen}
            setHeaderMobileMenuOpen={setHeaderMobileMenuOpen}
            mobileMenuOpen={isHeaderMobileMenuOpen}
            headerTop={headerTop}
            openHeaderSublevel={openHeaderSublevel}
            closeHeaderSublevel={closeHeaderSublevel}
            pathname={pathname}
            featureNavbar={featureNavbar}
          />
        )}
        {renderChildren()}
        {renderFavoriteDropdown(isAuthed)}
      </HeaderContainer>
      {renderLoading(loading || (!artworks.length && isAllArtworksLoading))}
    </Container>
  );
};
