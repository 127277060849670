import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';

import pintrk from '@utils/pinterestTag';
import { CONVERSIONS_EVENTS, EventPayload } from '@definitions/conversions.types';
import { conversionsActions } from '@modules/conversions';
import { GlobalState } from '@modules/createStore';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useTrackConversions = () => {
  const dispatch: AppDispatch = useDispatch();

  const sendConversion = (data: EventPayload) => dispatch(conversionsActions.sendEvent(data)).then(unwrapResult);

  return { sendConversion };
};

export const useTrackPageView = () => {
  const router = useRouter();
  const { sendConversion } = useTrackConversions();
  const handleRouteChange = () => {
    sendConversion({ eventType: CONVERSIONS_EVENTS.PAGE_VIEW }).finally(() => ({}));
    pintrk.pageView();
  };

  useEffect(() => {
    sendConversion({ eventType: CONVERSIONS_EVENTS.PAGE_VIEW }).finally(() => ({}));
    pintrk.pageView();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
