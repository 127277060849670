import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { footerActions, footerSelectors } from '@modules/footer';

export const useFooter = () => {
  const dispatch = useDispatch();
  const links = useSelector(footerSelectors.selectFooterLinks);

  useEffect(() => {
    if (!links) {
      dispatch(footerActions.fetchFooterLinks());
    }
  }, [links]);
};
