import React, { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Layout } from '@components/layout';
import reportError from '@utils/reportError';
import { Seo } from '@definitions/common.types';

export enum DROPDOWN_TYPES {
  FILTER = 'filter',
  SORT = 'sort',
  FAVOURITE = 'favourite',
}

export const FILTER_BUTTON_TEST_ID = 'filter-button-test-id';

export interface ArtworksViewProps {
  children: ReactNode;
  fetchFiltersFn: () => Promise<{
    filterOptions: any;
  }>;
  customHeader?: () => ReactNode;
  seoImageUrl: Seo | null;
}

export const ArtworksView = ({ children, fetchFiltersFn, customHeader, seoImageUrl }: ArtworksViewProps) => {
  const intl = useIntl();
  const headerTitle = intl.formatMessage({
    id: 'artworksView.headerTitle',
    defaultMessage: 'Buy Art Online | Online Art Gallery',
  });
  const headerDescription = intl.formatMessage({
    id: 'artworksView.headerDescription',
    defaultMessage:
      'Browse all works in our online art gallery on this page. Find something you love curated by experts at PLATFORM to easily and quickly buy art online.',
  });

  useEffect(() => {
    callFetchFilterOptions().finally(() => ({}));
  }, []);

  const callFetchFilterOptions = async () => {
    try {
      await fetchFiltersFn();
    } catch (e) {
      reportError(e);
    }
  };

  return (
    <Layout
      title={headerTitle}
      defaultDescription={headerDescription}
      customHeader={() => customHeader && customHeader()}
      firstProductImageURL={seoImageUrl}
    >
      {children}
    </Layout>
  );
};
