import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { shoppingCartFontStyles } from '@components/shoppingCart/shoppingCart.styles';
import { media } from '@theme/media';
import { LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  margin-bottom: ${OFFSET.M};
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 1px solid ${COLORS.BLACK};

  &:last-child {
    margin-bottom: 0;
    border: none;
  }
`;

export const ImageWrapper = styled.a`
  width: 100%;
  height: 250px;
  box-sizing: content-box;
  padding: ${OFFSET.S} 0;
`;

export const GiftCardWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  box-sizing: content-box;
  padding: ${OFFSET.S} 0;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const GiftCard = styled.a<{ backgroundImage: string }>`
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 65%;
  border-radius: ${OFFSET.XS};
  position: relative;
  display: block;
`;

export const GiftCardCopyWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GiftCardCopy = styled.div`
  ${typography({ spacing: LETTER_SPACING.S })};
  background-color: ${COLORS.WHITE};
  margin: 0 ${OFFSET.M};
  width: 100%;
  padding: 9px 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8px;
  gap: ${OFFSET.S};
`;

export const QuantityContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 13px;

  ${media.tablet} {
    margin-right: 0;
    width: 22%;
  }
`;

export const Quantity = styled.span`
  ${shoppingCartFontStyles};

  &:after {
    content: ' \\d7';
  }
`;

export const RemoveButton = styled.button.attrs({ type: 'button' })`
  ${shoppingCartFontStyles};
  padding: 0;

  ${media.tablet} {
    margin-left: ${OFFSET.S};
  }

  &:hover {
    border-bottom: 1px solid ${COLORS.GUARDSMAN_RED};
    color: ${COLORS.GUARDSMAN_RED};
  }
`;

export const NameContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${media.tablet} {
    align-items: center;
  }
`;

export const Artist = styled.span`
  ${shoppingCartFontStyles};
  text-transform: uppercase;
`;

export const ProductTitle = styled.a`
  ${shoppingCartFontStyles};
`;

export const PriceContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;

  ${media.tablet} {
    flex: 1;
    flex: unset;
    width: 22%;
  }

  button {
    margin: 0;
  }
`;

export const Price = styled.span`
  ${shoppingCartFontStyles};
`;

export const CheckoutButtonWrapper = styled.div`
  width: 100%;
  padding-bottom: ${OFFSET.S};
  position: sticky;
  bottom: 0;
  background-color: ${COLORS.WHITE};
`;

export const CheckoutButton = styled.button.attrs({ type: 'button' })`
  ${shoppingCartFontStyles};
  position: sticky;
  min-height: ${OFFSET.L};
  width: 100%;
  background-color: ${COLORS.BLACK};
  color: white;
  text-transform: uppercase;
  box-sizing: border-box;
`;

export const PaymentProviders = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: ${OFFSET.L};

  svg {
    height: ${OFFSET.XS};
    padding-right: ${OFFSET.S};
  }
`;

export const VariantOption = styled.div`
  color: ${COLORS.BLACK60};
`;

export const ExpiringDate = styled.div`
  color: ${COLORS.BLACK60};

  ${media.tablet} {
    text-align: center;
  }
`;
