import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';

import { shoppingCartSelectors, shoppingCartActions } from '@modules/shoppingCart';
import { GlobalState } from '@modules/createStore';
import { AddCartPayload, RemoveCartPayload, UpdateCartPayload } from '@definitions/shoppingCart.types';
import { QueryParam } from '@definitions/common.types';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useFetchCart = () => {
  const dispatch: AppDispatch = useDispatch();

  const fetchCart = (cartId: QueryParam) => dispatch(shoppingCartActions.fetchCart(cartId)).then(unwrapResult);
  const cart = useSelector(shoppingCartSelectors.selectCart);

  return { fetchCart, cart };
};

export const useGetCheckoutUrl = () => {
  const dispatch: AppDispatch = useDispatch();

  const getCheckoutUrl = () => dispatch(shoppingCartActions.getCheckoutUrl()).then(unwrapResult);

  return { getCheckoutUrl };
};

export const useRemoveShoppingCartItem = () => {
  const dispatch = useDispatch();

  const removeCartItem = (data: RemoveCartPayload) => dispatch(shoppingCartActions.removeCartItem(data));
  const cart = useSelector(shoppingCartSelectors.selectCart);

  return { removeCartItem, cart };
};

export const useUpdateShoppingCartItem = () => {
  const dispatch: AppDispatch = useDispatch();

  const updateCartItem = (data: UpdateCartPayload) =>
    dispatch(shoppingCartActions.updateCartItem(data)).then(unwrapResult);
  const cart = useSelector(shoppingCartSelectors.selectCart);

  return { updateCartItem, cart };
};

export const useAddShoppingCartItem = () => {
  const dispatch: AppDispatch = useDispatch();

  const addCartItem = (data: AddCartPayload) => dispatch(shoppingCartActions.addCartItem(data)).then(unwrapResult);
  const cart = useSelector(shoppingCartSelectors.selectCart);

  return { addCartItem, cart };
};

export const useGetShoppingCart = () => {
  const lineItems = useSelector(shoppingCartSelectors.selectLineItems);
  const isRecoveredCart = useSelector(shoppingCartSelectors.selectIsRecoveredCart);
  const lineItemsLength = lineItems.physicalItems.length + lineItems.giftCertificates.length;

  return { lineItems, lineItemsLength, isRecoveredCart };
};
