import React from 'react';

import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';

import { OuterContainer, CountDownContainer, Label, Segment } from './countDown.styles';

type CountDownProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isCompact?: boolean;
};

type Segment = {
  value: number;
  label: string;
};

export function CountDown({ days, hours, minutes, seconds, isCompact = true }: CountDownProps) {
  const segments: Segment[] = [
    { value: days, label: 'days' },
    { value: hours, label: 'hours' },
    { value: minutes, label: 'minutes' },
    { value: seconds, label: 'seconds' },
  ];

  function isSingular(value: number): boolean {
    return value === 1;
  }

  function determineEnding({ value, label }: Segment): string {
    const singular = label.slice(0, -1);
    const plural = label;

    return isSingular(value) ? singular : plural;
  }

  function formatSegment({ value, label }: Segment): string {
    return `${value} ${determineEnding({ value, label })} ${isCompact ? 'left' : ''}`;
  }

  const renderCountDown = renderWhenTrueOtherwise(
    () => {
      const [days, hours, minutes, seconds] = segments;
      const lessThanDay = hours.value + minutes.value + seconds.value > 0;
      const moreThanDay = days.value > 0;

      if (moreThanDay) return `${formatSegment(days)}`;
      if (lessThanDay) return 'less than 1 day left';
    },
    () => segments.map((segment) => <Segment key={segment.label}>{formatSegment(segment)}</Segment>)
  );

  const renderLabel = renderWhenTrue(() => <Label>Expires in:</Label>);

  return (
    <OuterContainer>
      {renderLabel(!isCompact)}
      <CountDownContainer>{renderCountDown(isCompact)}</CountDownContainer>
    </OuterContainer>
  );
}
