import React, { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { always } from 'ramda';
import { FormattedMessage, useIntl } from 'react-intl';

import { useWaitlist } from '@hooks/useWaitlist';
import reportError from '@utils/reportError';
import { LAYOUT_M_OPTIONS } from '@modules/plp';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { useCustomer } from '@hooks/useCustomer';
import { BUTTON_SIZE } from '@components/form';
import { formatStorePrice, ls } from '@utils/helpers';
import { useBreakpoint } from '@hooks/useBreakpoint';
import pintrk, { PINTEREST_EVENTS } from '@utils/pinterestTag';
import { useTrackConversions } from '@hooks/useTrackConversions';
import { CONVERSIONS_CONTENT_TYPE, CONVERSIONS_EVENTS } from '@definitions/conversions.types';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR } from '@utils/constants';
import api from '@services/nextAPI';
import { ACTION_PREFIX } from '@modules/plp/plp.constants';

import { DesktopCopy, MobileCopy, WaitListButton, Label, Price } from './waitlistButton.styles';
import { USER_WAITLISTED_ITEMS } from './waitlistButton.constants';
import { WaitlistModal } from './components/waitlistModal.component';

export interface WaitlistButtonProps {
  slug: string;
  waitlist: boolean;
  label: string;
  layoutM?: LAYOUT_M_OPTIONS;
  buttonSize?: BUTTON_SIZE;
  fullWidth?: boolean;
  price?: number | string;
  title: string;
  textColor?: string;
  backgroundColor?: string;
  bcId: number;
  className?: string;
  isPrelaunchable?: boolean;
  shouldOverridePrelaunchableCopy?: boolean;
  isPostSellingDate?: boolean;
}

type LocalStorageWaitlist = {
  email: string;
  firstName?: string;
  lastName?: string;
  items: {
    artworkId: number;
    artworkName: string;
  }[];
};

export const WaitlistButton = ({
  slug,
  waitlist,
  layoutM = LAYOUT_M_OPTIONS.COLUMN_1,
  buttonSize = BUTTON_SIZE.XXS,
  fullWidth = false,
  price,
  title,
  bcId,
  textColor = DEFAULT_TEXT_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  className,
  label,
  isPrelaunchable,
  shouldOverridePrelaunchableCopy,
  isPostSellingDate,
}: WaitlistButtonProps) => {
  const { addWaitlist, removeFromWaitlist } = useWaitlist(ACTION_PREFIX.ARTWORKS);
  const [isWaitlist, setIsWaitlist] = useState(waitlist);
  const [isWaitlistModalVisible, setIsWaitlistModalVisible] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const intl = useIntl();
  const { isAuthed, customer } = useCustomer();
  const { sendConversion } = useTrackConversions();
  const isArtworkWithMargin = [LAYOUT_M_OPTIONS.COLUMN_1_RIGHT_MARGIN, LAYOUT_M_OPTIONS.COLUMN_1_LEFT_MARGIN].includes(
    layoutM
  );
  const addLabel = intl.formatMessage({
    id: 'waitlistButton.addLabel',
    defaultMessage: 'Add the artwork to the waitlist',
  });
  const removeLabel = intl.formatMessage({
    id: 'waitlistButton.removeLabel',
    defaultMessage: 'Remove the artwork from the waitlist',
  });

  useEffect(() => {
    const localStorageWaitlist: LocalStorageWaitlist = ls.get(USER_WAITLISTED_ITEMS);
    const isEmailProvided = !!localStorageWaitlist?.email;
    setIsConfirmationVisible(isEmailProvided);
    const isItemWaitlisted =
      localStorageWaitlist?.items.filter(({ artworkId }: { artworkId: number }) => artworkId === bcId).length > 0;
    if (isItemWaitlisted) {
      setIsWaitlist(true);
    }
    window.addEventListener('storage', localStorageListener);

    return () => window.removeEventListener('storage', localStorageListener);
  }, []);

  const localStorageListener = () => {
    const isLocalStorageWaitlistRemoved = !window.localStorage[USER_WAITLISTED_ITEMS];
    if (isLocalStorageWaitlistRemoved) {
      setIsWaitlist(false);
    }
  };

  const addToWaitlist = async (email: string, firstName = '', lastName = '') => {
    try {
      await addWaitlist({ email, firstName, lastName, slug });
      await sendConversion({
        eventType: CONVERSIONS_EVENTS.ADD_TO_WAITLIST,
        contentType: CONVERSIONS_CONTENT_TYPE.PRODUCT,
        contents: [
          {
            title,
            quantity: 1,
            price: price || 0,
            id: bcId,
          },
        ],
      });

      pintrk.event(PINTEREST_EVENTS.LEAD, { lead_type: 'Waitlist' });
    } catch (e) {
      reportError(e);
    }
  };

  const removeWaitlist = async () => {
    try {
      await removeFromWaitlist({ slug });
      setIsWaitlist(false);
    } catch (e) {
      reportError(e);
    }
  };

  const handleWaitlistClick = async (e: MouseEvent) => {
    e.preventDefault();
    setIsConfirmationVisible(false);
    const localStorageWaitlist: LocalStorageWaitlist = ls.get(USER_WAITLISTED_ITEMS);
    if (localStorageWaitlist) {
      await formSubmissionLogic(
        localStorageWaitlist.email,
        localStorageWaitlist.firstName,
        localStorageWaitlist.lastName
      );
      setIsConfirmationVisible(true);
      setIsWaitlistModalVisible(true);
    } else {
      setIsWaitlistModalVisible(true);
    }
  };

  const handleWaitlistRemoveClick = async (e: MouseEvent) => {
    e.preventDefault();
    setIsWaitlist(false);
    if (isAuthed) {
      try {
        await removeWaitlist();
      } catch (e) {
        reportError(e);
      }
    }
    const localStorageWaitlist: LocalStorageWaitlist = ls.get(USER_WAITLISTED_ITEMS);
    if (localStorageWaitlist) {
      const { email, firstName, lastName } = localStorageWaitlist;
      const remainingItems = localStorageWaitlist?.items.filter(
        ({ artworkId }: { artworkId: number }) => artworkId !== bcId
      );
      if (remainingItems) {
        const updatedLocalStorageWaitlist: LocalStorageWaitlist = {
          email,
          ...(firstName && { firstName }),
          ...(lastName && { lastName }),
          items: remainingItems,
        };
        ls.set(USER_WAITLISTED_ITEMS, updatedLocalStorageWaitlist);
      }
    }
  };

  const formSubmissionLogic = async (email: string, firstName?: string, lastName?: string) => {
    const newLocalStorageWaitlist = {
      email,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      items: [
        {
          artworkId: bcId,
          artworkName: title,
        },
      ],
    };

    const localStorageWaitlist: LocalStorageWaitlist = ls.get(USER_WAITLISTED_ITEMS);
    if (localStorageWaitlist?.items.length > 0) {
      const previouslyAddedItems = localStorageWaitlist.items;
      const mergedLocalStorageWaitlist = {
        ...newLocalStorageWaitlist,
        items: [...previouslyAddedItems, ...newLocalStorageWaitlist.items],
      };

      ls.set(USER_WAITLISTED_ITEMS, mergedLocalStorageWaitlist);
    } else {
      ls.set(USER_WAITLISTED_ITEMS, newLocalStorageWaitlist);
      await api.post('email/subscribe', {
        email,
        ...(firstName && lastName && { name: `${firstName} ${lastName}` }),
        tags: ['Waitlist'],
      });
    }
    await addToWaitlist(email, firstName, lastName);
    setIsWaitlist(true);
  };

  const handleSubmit = async (e: FormEvent, formData: { email: string; firstName: string; lastName: string }) => {
    const { email, firstName, lastName } = formData;
    e.preventDefault();
    await formSubmissionLogic(email, firstName, lastName);
    setIsConfirmationVisible(true);
  };

  const renderPrice = renderWhenTrue(() => <Price>{price && formatStorePrice(price)}</Price>);

  const renderAddToWaitlist = always(
    <WaitListButton
      className={className}
      onClick={handleWaitlistClick}
      size={buttonSize}
      fullWidth={fullWidth}
      centered={!price}
      aria-label={addLabel}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <DesktopCopy isArtworkWithMargin={isArtworkWithMargin}>{label}</DesktopCopy>
      <MobileCopy isArtworkWithMargin={isArtworkWithMargin}>{label}</MobileCopy>
      {renderPrice(!!price)}
    </WaitListButton>
  );

  const renderActiveWaitlist = always(
    <WaitListButton
      className={className}
      active
      reverted={!price}
      onClick={handleWaitlistRemoveClick}
      size={buttonSize}
      fullWidth={fullWidth}
      centered={!price}
      aria-label={removeLabel}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <Label>
        <FormattedMessage id="artworkCard.activeWaitList" defaultMessage={label} />
      </Label>
      {renderPrice(!!price)}
    </WaitListButton>
  );

  const renderWaitlistModal = renderWhenTrue(() => (
    <WaitlistModal
      onModalClose={() => setIsWaitlistModalVisible(false)}
      isModalOpen={isWaitlistModalVisible}
      onFormSubmit={(e, formData) => handleSubmit(e, formData)}
      isConfirmationVisible={isConfirmationVisible}
      initialFields={{
        email: customer?.email,
        firstName: customer?.firstName,
        lastName: customer?.lastName,
      }}
      isPrelaunchable={isPrelaunchable}
      shouldOverridePrelaunchableCopy={shouldOverridePrelaunchableCopy}
      isPostSellingDate={isPostSellingDate}
    />
  ));

  const renderWaitListButton = renderWhenTrueOtherwise(renderActiveWaitlist, renderAddToWaitlist);

  return (
    <>
      {renderWaitListButton(isWaitlist)}
      {renderWaitlistModal(isWaitlistModalVisible)}
    </>
  );
};
