import React from 'react';

import { BLOCK_TYPE } from '@components/blocks/blocks.constants';

import { Container, BlockQuote } from './quote.styles';

export interface QuoteProps {
  content: string;
}

export const Quote = ({ content }: QuoteProps) => (
  <Container data-testid={BLOCK_TYPE.QUOTE}>
    <BlockQuote>{content}</BlockQuote>
  </Container>
);
