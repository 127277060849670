import React from 'react';
import { allPass, always, complement, isEmpty, isNil } from 'ramda';

import { BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { renderWhenTrue } from '@utils/rendering';
import { isNotEmpty } from '@utils/helpers';
import { Link as LinkType } from '@definitions/introText.types';
import { ReadMoreButton } from '@components/readMoreButton';

import { Container, InnerContainer, Title, Description, Cta } from './aboutUs.styles';

export interface AboutUsProps {
  title: string;
  description: string;
  link: LinkType;
}

export const AboutUs = ({ title, description, link }: AboutUsProps) => {
  const isValidLink = allPass([complement(isEmpty), complement(isNil), isNotEmpty('displayText'), isNotEmpty('slug')])(
    link
  );

  const renderCta = renderWhenTrue(
    always(
      <Cta>
        <ReadMoreButton {...link} />
      </Cta>
    )
  );

  return (
    <Container data-testid={BLOCK_TYPE.ABOUT_US}>
      <InnerContainer>
        <Title>{title}</Title>
        <Description>
          {description} {renderCta(isValidLink)}
        </Description>
      </InnerContainer>
    </Container>
  );
};
