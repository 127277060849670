import React from 'react';

import { Container } from './textBlockOnly.styles';
import { DESC_TEXT_ALIGN } from './textBlockOnly.constants';

export interface TextOnlyProps {
  description: string;
  fontSize: {
    size: string;
    spacing: string;
  };
  textAlign: DESC_TEXT_ALIGN;
  textBoxWidth: string;
  textBoxAlign: string;
}

export const TextOnlyBlock = ({ description, fontSize, textAlign, textBoxWidth, textBoxAlign }: TextOnlyProps) => {
  return (
    <Container
      width={textBoxWidth}
      fontSize={fontSize?.size}
      borderSpacing={fontSize?.spacing}
      margin={30}
      textAlign={textAlign}
      textBoxAlign={textBoxAlign}
    >
      <span dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  );
};
