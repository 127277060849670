import { Cart, GiftCertificate, PhysicalItem } from '@definitions/shoppingCart.types';
export const totalQuantity = (lineItems: Cart['lineItems']) => {
  const physicalItemsCount = lineItems?.physicalItems?.reduce(
    (accumulator: number, current: PhysicalItem) => accumulator + current.quantity,
    0
  );
  const giftCertifItemsCount = lineItems?.giftCertificates?.reduce(
    (accumulator: number, current: GiftCertificate) => accumulator + current.quantity,
    0
  );
  return physicalItemsCount + giftCertifItemsCount;
};