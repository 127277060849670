import React from 'react';

import { BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { Block } from '@definitions/common.types';

import { Title } from '../blocks.styles';

import {
  Container,
  OrganizedBlockContainer,
  OrganizedBlockTitle,
  OrganizedBlockBody,
} from './organizedLegalBlock.styles';

export interface OrganizedLegalBlockProps {
  title: string;
  blocks: Block[];
}

export const OrganizedLegalBlock = ({ title, blocks }: OrganizedLegalBlockProps) => {
  const renderOrganizedBlocks = (organizedBlock: Block, blockIndex: number) => (
    <OrganizedBlockContainer key={`organizedBlockIndex ${blockIndex}`}>
      <OrganizedBlockTitle>
        {blockIndex + 1}. {organizedBlock.value.subHeader}
      </OrganizedBlockTitle>
      <OrganizedBlockBody dangerouslySetInnerHTML={{ __html: organizedBlock.value.body }} />
    </OrganizedBlockContainer>
  );

  return (
    <Container data-testid={BLOCK_TYPE.ORGANIZED_LEGAL_BLOCK}>
      <Title>{title}</Title>
      {blocks.map(renderOrganizedBlocks)}
    </Container>
  );
};
