import { both, complement, equals, find, isEmpty, isNil, lt, pathOr, pick, pickBy, pipe, prop, propEq } from 'ramda';

import { Artwork, ArtworkDynamicData, ArtworkOptions, ArtworkVariant } from '@definitions/artworks.types';
import { useArtworksRoute } from '@hooks/useArtworks/useArtworks';

import { DYNAMIC_DATA, PLP_VARIANT } from './plp.constants';

export const getPhysicalItems = pathOr([], ['lineItems', 'physicalItems']);

export const pickDynamicData = pipe(pick(DYNAMIC_DATA), pickBy(both(complement(isEmpty), complement(isNil)))) as (
  artwork: Partial<Artwork>
) => ArtworkDynamicData;

export type CurrentPlp = typeof PLP_VARIANT.TRADE | typeof PLP_VARIANT.GROUPINGS | typeof PLP_VARIANT.ARTWORKS;
export type Context = { [key: string]: any };
export const determinePlpContext = (context: Context, currentPlp: CurrentPlp) => {
  return context[currentPlp];
};

export const getCurrentPLP = () => {
  const { isGroupingRoute, isTradeMemberRoute } = useArtworksRoute();
  if (isGroupingRoute) {
    return PLP_VARIANT.GROUPINGS;
  }
  if (isTradeMemberRoute) {
    return PLP_VARIANT.TRADE;
  }
  return PLP_VARIANT.ARTWORKS;
};

const getDefaultValues = (options: ArtworkOptions[]) =>
  options.reduce((prev, next) => {
    const defaultValue = find(propEq('isDefault', true), next.optionValues);
    return defaultValue ? { ...prev, [next.displayName]: defaultValue.label } : prev;
  }, {});

const getDefaultVariant = (variants: ArtworkVariant[], defaultValues: { [key: string]: string }) => {
  const defaultVariantByValues = find(
    pipe(
      (variant: ArtworkVariant) =>
        variant.optionValues.reduce((prev, next) => ({ ...prev, [next.optionDisplayName]: next.label }), {}),
      equals(defaultValues)
    ),
    variants
  );

  if (defaultVariantByValues && defaultVariantByValues.inventoryLevel) {
    return defaultVariantByValues;
  }

  const firstAvailableVariant = find(pipe(prop('inventoryLevel'), lt(0)), variants);

  if (firstAvailableVariant) {
    return firstAvailableVariant;
  }

  return variants[0];
};

export const getArtworkDynamicData = (artwork: Artwork, variantId?: string) => {
  if (isEmpty(artwork.variants)) {
    return { dynamicData: {}, defaultVariant: null };
  }

  const variant = find(propEq('bigCommerceId', variantId), artwork.variants);
  if (variant) {
    return { dynamicData: pickDynamicData(variant), defaultVariant: variant };
  }

  const defaultValues = getDefaultValues(artwork.options);
  const defaultVariant = getDefaultVariant(artwork.variants, defaultValues);

  return { dynamicData: pickDynamicData(defaultVariant), defaultVariant };
};
