import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div<{ hasSpacingTop: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${OFFSET.S};
  padding: 0 ${OFFSET.S};
  &:not(:first-child) {
    margin-top: ${({ hasSpacingTop }) => (hasSpacingTop ? OFFSET.L : '0')};
  }

  ${media.desktop} {
    gap: ${OFFSET.M};
    padding: 0 ${OFFSET.M};
    grid-template-columns: 1fr 1fr 1fr;

    &:not(:first-child) {
      margin-top: ${({ hasSpacingTop }) => (hasSpacingTop ? OFFSET.XL : '0')};
    }
  }
`;

export const imageStyles = css`
  height: calc(50vw - 30px);
  max-width: 100%;

  ${media.tablet} {
    height: calc(33.333vw - 40px - 15px);
  }
`;
