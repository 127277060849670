export const HEADER_TEST_ID = 'header-test-id';
export const SEARCH_TEST_ID = 'search-test-id';
export const BAG_TEST_ID = 'bag-test-id';

export enum SUBLEVEL {
  COUNTRY = 'country',
  BAG = 'bag',
  ACCOUNT = 'account',
  FAVORITES = 'favorites',
}

export enum HEADER_TOP {
  REGULAR = 34,
  PDP = 5,
}
