import React from 'react';

import { renderWhenTrue } from '@utils/rendering';

import Time from '../../assets/images/icon-time.svg';

import { OuterContainer, CountDownContainer } from './unitCountDown.styles';

type UnitCountDownProps = {
  inventoryLevel: number;
  withIcon?: boolean;
  isMobileVersion?: boolean;
  isPlpVariant?: boolean;
};

type Segment = {
  value: number;
};

export function UnitCountDown({
  isPlpVariant,
  inventoryLevel,
  withIcon = false,
  isMobileVersion = false,
}: UnitCountDownProps) {
  function determineEnding({ value }: Segment): string {
    return value === 1 ? 'unit' : 'units';
  }

  function formatMessage({ value }: Segment): string {
    if (isPlpVariant) return 'Only a few remaining';
    return `${value} ${determineEnding({ value })} left`;
  }

  const rednerIcon = renderWhenTrue(() => <Time />);

  return (
    <OuterContainer isMobileVersion={isMobileVersion} secondaryStyles={withIcon}>
      {rednerIcon(withIcon)}
      <CountDownContainer>{formatMessage({ value: inventoryLevel })}</CountDownContainer>
    </OuterContainer>
  );
}
