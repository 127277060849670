import { MetaTag, OpenGraphImages } from 'next-seo/lib/types';

import { ASSET_PLACEMENT, BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { HEADER_TYPE } from '@components/articles/articleHeader/articleHeader.constants';
import { MONOGRAM_APPEARANCE_TYPE } from '@components/monogram/monogram.constants';
import { Artwork } from '@definitions/artworks.types';

export interface ImageSetUrl {
  url: string;
  size: string;
}

export interface ImageSet {
  urls: ImageSetUrl[];
  title: string;
  alt: string;
}

export interface Meta {
  type: string;
  slug: string;
  htmlUrl: string;
}

export interface HeadingValue {
  masthead: string;
  headline: string;
  subhead: string;
  backgroundColor: string;
  textColor: string;
}

export interface HeroImageHeadingValue {
  masthead: string;
  headline: string;
  paragraph: string;
  textColor: string;
  secondaryTextColor?: string;
  image: ImageSet;
  mobileImage: ImageSet;
  backgroundColor: string;
}

export interface HeroVideoHeadingValue {
  masthead: string;
  headline: string;
  paragraph: string;
  textColor: string;
  secondaryTextColor: string;
  videoUrl: string;
  backgroundColor: string;
}

export interface Header {
  value: HeadingValue | HeroImageHeadingValue | HeroVideoHeadingValue;
  type: HEADER_TYPE;
}

export interface Block {
  type: BLOCK_TYPE;
  value: any;
  id: string;
}

export interface VideoBlock {
  type: BLOCK_TYPE;
  value: {
    videoUrl: string;
    alt: string;
    caption: string;
    placement: ASSET_PLACEMENT;
    autoplay: boolean;
  };
  id: string;
}

export interface BlockWithArtworks {
  type: BLOCK_TYPE;
  value: Artwork[];
}

export interface Monogram {
  monogramColor: string;
  monogramLetter: string;
  monogramAnimationType: MONOGRAM_APPEARANCE_TYPE;
}

export interface SeoImageUrl {
  size: string;
  url: string;
}

export interface SeoImage {
  alt: string;
  title: string;
  urls: SeoImageUrl[];
}

export interface Seo {
  tags: MetaTag[];
  title: string;
  noIndex?: boolean;
  noFollow?: boolean;
  image: OpenGraphImages;
}

export interface ResetPasswordPayload {
  email: string;
}

export interface ResetPasswordConfirmPayload {
  user: string;
  newPassword: string;
  token: string;
}

export interface LinkSet {
  displayText: string;
  slug?: string;
  urlPath?: string;
  type: LinkSetType;
}

export enum LinkSetType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export type QueryParam = string | string[] | undefined;

export enum Direction {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface DistanceProps {
  value: number;
  direction: Direction;
}
