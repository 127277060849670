import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const ErrorContainer = styled.div`
  min-height: var(--vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  line-height: normal;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;

  > h1 {
    ${typography({ size: FONT_SIZE.M, height: LINE_HEIGHT.S })};

    ${media.tablet} {
      ${typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.S })};
    }
  }
`;

export const ErrorNumber = styled.div`
  ${typography({ size: FONT_SIZE.L })};

  ${media.tablet} {
    ${typography({ size: FONT_SIZE.XL })};
  }
`;

export const LinkArrow = styled.span`
  &:before {
    content: '\\2192';
    font-size: ${FONT_SIZE.XS};
    margin-right: 2px;
  }
`;

export const TextMessage = styled.div`
  margin: ${OFFSET.S} 0;
`;

export const disabledButtonContainerStyles = css`
  cursor: not-allowed;
  > * {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const LineTitle = styled.div`
  ${typography({})};
  border-bottom: 1px solid ${COLORS.BLACK};
  margin-bottom: ${OFFSET.M};
  text-align: ${({ isLeft = false }: { isLeft?: boolean }) => (isLeft ? 'left' : 'center')};
  padding-top: ${OFFSET.XS};
  padding-bottom: 6px;
  text-transform: uppercase;

  ${media.mobile} {
    ${typography({ spacing: LETTER_SPACING.M })};
  }
`;

export const LineContainer = styled.div`
  border-top: 1px solid ${COLORS.BLACK};
`;

export const EmailLink = styled.a`
  color: ${COLORS.BLACK};
  border-bottom: 1px solid ${COLORS.BLACK40};
`;

export const FocusTrap = styled.div.attrs({ tabIndex: 0 })`
  position: absolute;
  opacity: 0;
`;
