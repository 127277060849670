import React, { useState } from 'react';
import { Variants } from 'framer-motion';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { Dropdown, Grouping } from '@definitions/header.types';
import { LinkSetType } from '@definitions/common.types';
import { ROUTE } from '@utils/constants';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';

import { Container, Trigger, List, Item, Link, CustomDiv } from './headerDropdown.styles';

interface iHeaderDropdown {
  items: Dropdown[];
  groupings: Grouping[];
  onMouseOver: () => void;
  onMouseOut: () => void;
}

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 250, damping: 25 },
  },
  closed: { opacity: 0, y: -20, transition: { duration: 0.3 } },
};

export const HeaderDropdown = ({ items, groupings, onMouseOver, onMouseOut }: iHeaderDropdown) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const renderTrigger = () => (
    <Trigger
      isActive={router.pathname.includes(ROUTE.ARTWORKS)}
      isOpen={isOpen}
      onMouseOver={() => {
        setIsOpen(true);
        onMouseOver();
      }}
    >
      Artworks
    </Trigger>
  );

  const renderArtworksLink = renderWhenTrueOtherwise(
    () =>
      groupings?.map(({ slug }, index) => {
        return (
          index === 0 && (
            <a
              onClick={() => {
                window.history.pushState({}, '', ROUTE.ARTWORKS);
                window.location.href = ROUTE.ARTWORKS;
              }}
              href={`${ROUTE.ARTWORKS}`}
            >
              {renderTrigger()}
            </a>
          )
        );
      }),
    () => (
      <a
        onClick={() => {
          window.history.pushState({}, '', ROUTE.ARTWORKS);
          window.location.href = ROUTE.ARTWORKS;
        }}
        href={`${ROUTE.ARTWORKS}`}
      >
        {renderTrigger()}
      </a>
    )
  );

  const renderList = renderWhenTrue(() => (
    <List
      initial={false}
      style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
      animate={isOpen ? 'open' : 'closed'}
      variants={{
        open: {
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0,
            delayChildren: 0,
            staggerChildren: 0,
          },
        },
        closed: {
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.3,
          },
        },
      }}
    >
      <CustomDiv>
        {items.map(({ link: { slug, displayText, type, urlPath } }, index) => (
          <Item variants={itemVariants} key={index}>
            <a
              onClick={(e) => {
                e.preventDefault();
                const targetUrl = type === LinkSetType.INTERNAL ? `/${slug}` : urlPath;
                window.history.pushState({}, '', targetUrl);
                window.location.href = targetUrl;
              }}
              href={type === LinkSetType.INTERNAL ? `/${slug}` : urlPath}
            >
              <Link style={{ paddingLeft: 122 }}>{displayText}</Link>
            </a>
          </Item>
        ))}
      </CustomDiv>
    </List>
  ));

  return (
    <Container
      onMouseLeave={() => {
        setIsOpen(false);
        onMouseOut();
      }}
    >
      {renderArtworksLink(groupings.length > 0)}
      {renderList(router.pathname !== ROUTE.TRADE)}
    </Container>
  );
};
