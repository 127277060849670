import React, { Fragment } from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';
import { propEq } from 'ramda';

import { renderWhenOtherwise } from '@utils/rendering';
import { HiddenContainer } from '@components/PLP/hiddenContainer';
import { ArtworkCard, ArtworkDetail } from '@components/artworkCard';
import { Artwork } from '@definitions/artworks.types';
import { PRODUCT_TYPE } from '@utils/constants';
import { GiftCertificateCard } from '@components/giftCertificateCard';

import { Item, defaultImageStyles } from './artworkList.styles';

export interface ArtworkListProps {
  artworks: Artwork[];
  imageStyles?: FlattenSimpleInterpolation;
  renderHiddenContainer?: boolean;
  textColor?: string;
  backgroundColor?: string;
  shoppable?: boolean;
  accessToken?: string;
}

export const ArtworkList = ({
  artworks,
  imageStyles = defaultImageStyles,
  renderHiddenContainer,
  textColor,
  backgroundColor,
  shoppable,
  accessToken,
}: ArtworkListProps) => {
  const renderArtwork = renderWhenOtherwise(
    propEq('type', PRODUCT_TYPE.GIFT_CERTIFICATE),
    (giftCertificate) => <GiftCertificateCard imageStyles={imageStyles} giftCertificate={giftCertificate} />,
    (artwork) => (
      <ArtworkCard
        artwork={artwork}
        imageStyles={imageStyles}
        textColor={textColor}
        backgroundColor={backgroundColor}
        shoppable={shoppable}
        accessToken={accessToken}
      >
        <ArtworkDetail>
          {artwork.title}, {artwork.year}
        </ArtworkDetail>
        <ArtworkDetail>{artwork.medium}</ArtworkDetail>
      </ArtworkCard>
    )
  );

  return (
    <Fragment>
      {artworks?.map((artwork, index) => (
        <Item key={index}>
          {renderHiddenContainer && <HiddenContainer index={index} />}
          {renderArtwork(artwork)}
        </Item>
      ))}
    </Fragment>
  );
};
