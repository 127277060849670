import React, { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { always } from 'ramda';

import { renderWhenTrueOtherwise } from '@utils/rendering';

import {
  Info,
  Form,
  Email,
  BottomRow,
  FirstName,
  LastName,
  SubmitButton,
  FieldWrapper,
  Checkbox,
  Label,
  Text,
} from './notifyForm.styles';
import { emailRegex } from './notifyForm.constants';

const copy = {
  checkboxLabel: "Yes, I accept Platform's terms and privacy policy and agree to receive emails.",
};

interface NotifyFormProps {
  onFormSubmit: (
    event: FormEvent,
    { email, firstName, lastName }: { email: string; firstName: string; lastName: string }
  ) => void;
  isConfirmationVisible: boolean;
  texts: {
    info: string;
    confirmation: string;
    submit: string;
  };
  initialFields?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    isChecked?: boolean;
  };
}

export const NotifyForm = ({ onFormSubmit, isConfirmationVisible, texts, initialFields }: NotifyFormProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isInitialFieldsPopulated, setIsInitialFieldsPopulated] = useState(false);

  useEffect(() => {
    if (initialFields?.email && !isInitialFieldsPopulated) {
      setEmail(initialFields.email || '');
      setFirstName(initialFields.firstName || '');
      setLastName(initialFields.lastName || '');
      setIsChecked(initialFields.isChecked || false);
      setIsInitialFieldsPopulated(true);
    }
  }, [initialFields]);

  const validateEmail = (email: string): boolean => emailRegex.test(email);

  const renderForm = always(
    <>
      <Info>{texts.info}</Info>

      <Form onSubmit={(e) => onFormSubmit(e, { email, firstName, lastName })}>
        <FieldWrapper isRequired>
          <Email value={email} onChange={(e) => setEmail(e.target.value)} />
        </FieldWrapper>

        <BottomRow>
          <FieldWrapper>
            <FirstName value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </FieldWrapper>

          <FieldWrapper>
            <LastName value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </FieldWrapper>
        </BottomRow>

        <Label>
          <Checkbox checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />
          <Text>{copy.checkboxLabel}</Text>
        </Label>

        <SubmitButton disabled={!(isChecked && validateEmail(email))} inverted fullWidth>
          <FormattedMessage id="addToWaitlist.submit" defaultMessage={texts.submit} />
        </SubmitButton>
      </Form>
    </>
  );

  const renderConfirmation = always(<Info>{texts.confirmation}</Info>);

  const renderContent = renderWhenTrueOtherwise(renderConfirmation, renderForm);

  return renderContent(isConfirmationVisible);
};
