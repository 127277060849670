import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';
import { CONTAINER_PADDING, CONTAINER_WIDTH, NARROW_CONTAINER_WIDTH } from '@components/blocks/blocks.constants';
import {
  colonSuffix,
  containerStyles,
  linkStyles,
  multilineTextLinkStyles,
  paragraphStyles,
  paragraphTextStyles,
} from '@components/blocks/blocks.styles';

export const Container = styled.div`
  ${paragraphTextStyles};
  margin: 0 auto;
  padding: 0 24px;
  margin: ${OFFSET.M} auto;

  ${media.desktop} {
    margin: ${OFFSET.L} auto;
    padding: 0;
    max-width: none;
    display: grid;
    grid-template-columns: calc(50% - ${CONTAINER_WIDTH / 2}px) ${CONTAINER_WIDTH}px auto;
  }
`;

export const QuestionContainer = styled.div`
  ${paragraphTextStyles};
  ${containerStyles};
  margin: ${OFFSET.M} auto;

  ${media.tablet} {
    margin: ${OFFSET.L} 0;
    display: grid;
    grid-template-columns: auto calc(${NARROW_CONTAINER_WIDTH}px + ${CONTAINER_PADDING}px * 3) auto;
    max-width: none;
  }
`;

export const QuestionContent = styled.div`
  margin-left: calc(${CONTAINER_PADDING}px * 1.5);

  ${media.tablet} {
    margin-left: calc(
      ((-100vw + ${NARROW_CONTAINER_WIDTH}px + ${CONTAINER_PADDING}px * 6) / 4) + ${CONTAINER_PADDING}px / 2
    );
  }
`;

export const AnswerContainer = styled.div`
  ${paragraphTextStyles};
  ${containerStyles};
  margin: ${OFFSET.M} auto;

  ${media.tablet} {
    margin: ${OFFSET.L} 0;
    display: grid;
    grid-template-columns: auto ${CONTAINER_WIDTH - CONTAINER_PADDING * 2}px auto;
    max-width: none;
  }
`;

export const Title = styled.h3`
  ${paragraphTextStyles};
  ${colonSuffix};

  ${media.desktop} {
    margin: 0 0 0 51px;
  }
`;

export const CollapsableParagraph = styled.div`
  padding-left: 0;

  a {
    ${linkStyles};
  }

  & > p {
    ${paragraphStyles};
  }

  --blur: 1px;
  position: relative;
  height: 5.5em;
  background: -webkit-linear-gradient(#000000, #000000, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  overflow: hidden;
  min-height: 1px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.desktop} {
    padding: 0 ${CONTAINER_PADDING}px;
  }
`;

export const Paragraph = styled.div`
  padding-left: 0;

  a {
    ${linkStyles};
  }

  & > p {
    ${paragraphStyles};
    min-height: 1px;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.desktop} {
      padding: 0 ${CONTAINER_PADDING}px;
    }

    a {
      ${multilineTextLinkStyles}
    }
  }
`;
export const AnswerTitle = styled.span`
  ${colonSuffix};
  margin-right: calc(${CONTAINER_PADDING}px * 0.5);

  ${media.tablet} {
    float: left;
    margin-left: calc(((-100vw + ${NARROW_CONTAINER_WIDTH}px + ${CONTAINER_PADDING}px * 6) / 2));
  }
`;

export const QuestionTitle = styled(AnswerTitle)`
  margin-right: calc(${OFFSET.M} * 0.5);
  margin-left: calc(-${OFFSET.M} * 1.5);

  ${media.tablet} {
    margin-left: calc(
      ((-100vw + ${NARROW_CONTAINER_WIDTH}px + ${CONTAINER_PADDING}px * 2) / 4) + ${CONTAINER_PADDING}px / 2
    );
  }
`;

export const QuestionParagraph = styled.span`
  > p {
    margin: 0 0 ${OFFSET.M};
    min-height: 1px;
  }
`;
