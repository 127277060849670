import { css } from 'styled-components';

export enum OFFSET {
  XS = '10px',
  S = '18px',
  M = '36px',
  L = '72px',
  XL = '108px',
}

export enum DISPLAY {
  FLEX = 'flex',
  INLINE = 'inline-flex',
}

export enum ALIGN {
  UNSET = 'unset',
  CENTER = 'center',
  BASELINE = 'baseline',
  START = 'flex-start',
  END = 'flex-end',
}

export enum JUSTIFY {
  UNSET = 'unset',
  CENTER = 'center',
  AROUND = 'space-around',
  BETWEEN = 'space-between',
  EVEN = 'space-evenly',
  START = 'flex-start',
  END = 'flex-end',
}

export enum DIRECTION {
  ROW = 'row',
  COLUMN = 'column',
  REVERSE = 'row-reverse',
}

export enum SHRINK {
  DISABLED = 0,
  ENABLED = 1,
  INCREASED = 2,
}

export enum GROW {
  DISABLED = 0,
  ENABLED = 1,
  INCREASED = 2,
  MAXED = 3,
}

export enum WRAP {
  ENABLED = 'wrap',
  DISABLED = 'nowrap',
}

export type FlexProps = {
  display?: DISPLAY;
  align?: ALIGN;
  justify?: JUSTIFY;
  direction?: DIRECTION;
  shrink?: SHRINK;
  grow?: GROW;
  wrap?: WRAP;
};

export const flex = ({
  display = DISPLAY.FLEX,
  align = ALIGN.CENTER,
  justify = JUSTIFY.CENTER,
  direction = DIRECTION.ROW,
  shrink = SHRINK.ENABLED,
  grow = GROW.DISABLED,
  wrap = WRAP.DISABLED,
}: FlexProps) => css`
  display: ${display};
  align-items: ${align};
  justify-content: ${justify};
  flex-direction: ${direction};
  flex-shrink: ${shrink};
  flex-grow: ${grow};
  flex-wrap: ${wrap};
`;
