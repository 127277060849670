import React from 'react';

import { renderWhenTrueOtherwise } from '@utils/rendering';

import PlusIcon from '../../../../assets/images/icon-plus.svg';
import MinusIcon from '../../../../assets/images/icon-minus.svg';

import { Button, ButtonContainer, InfoTooltip } from './quantityButton.styles';

export enum ICON_VARIANT {
  PLUS = 'plus',
  MINUS = 'minus',
}

export interface QuantityButtonProps {
  isDisabled: boolean;
  onClick: () => void;
  warning: string;
  iconVariant: ICON_VARIANT;
}

export const QuantityButton = ({ isDisabled, onClick, warning, iconVariant }: QuantityButtonProps) => {
  const hasPlusIcon = iconVariant === ICON_VARIANT.PLUS;

  const renderIcon = renderWhenTrueOtherwise(
    () => <PlusIcon />,
    () => <MinusIcon />
  );

  return (
    <ButtonContainer isDisabled={isDisabled}>
      <Button onClick={onClick}>{renderIcon(hasPlusIcon)}</Button>
      <InfoTooltip>{warning}</InfoTooltip>
    </ButtonContainer>
  );
};
