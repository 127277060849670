import { always } from 'ramda';

import { renderWhenTrueOtherwise } from '@utils/rendering';

import { OfferedInContainer, OuterContainer, TimeIcon, IconContainer } from './offeredIn.styles';

export interface OfferedInProps {
  liveEndAt: string;
  hideIcon?: boolean;
}

export const OfferedIn = ({ liveEndAt, hideIcon }: OfferedInProps) => {
  const date = new Date(liveEndAt).toLocaleString('en-us', { month: 'long', year: 'numeric' });
  const content = `Offered in ${date}`;

  const renderOfferedIn = renderWhenTrueOtherwise(
    always(<OfferedInContainer>{content}</OfferedInContainer>),
    always(
      <IconContainer>
        <TimeIcon />
        {content}
      </IconContainer>
    )
  );

  return <OuterContainer>{renderOfferedIn(hideIcon)}</OuterContainer>;
};
