import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { COLORS } from '@theme/colors';

export const Container = styled.div`
  position: relative;
`;

export const List = styled(motion.ul)`
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 34px;
  padding-top: 3px;
`;

export const Item = styled(motion.li)`
  background: ${COLORS.WHITE};
  padding: 1px 0px 5px 0px;
`;

export const Link = styled.a`
  padding: 5.5px 8px;
  color: ${COLORS.BLACK};

  &:hover {
    color ${COLORS.BLACK};
  }
`;

export const Trigger = styled.a<{ isOpen: boolean; isActive: boolean }>`
  padding: 9px 8px;
  display: flex;
  z-index: 2;
  position: relative;

  ${({ isOpen }) =>
    isOpen &&
    css`
      background: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
    `}

  &:hover {
    color ${COLORS.BLACK};
  }
`;

export const CustomDiv = styled.div`
  margin-left: -114px;
  width: 540px;
  margin-top: -1px;
`;
