import React, { InputHTMLAttributes } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import { renderWhenTrue } from '@utils/rendering';

import { InputWrapper, Input, Label, Caption, FlexStyle, ErrorTextStyle } from './radiobuttonField.styles';

export interface RadioButtonFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  label: string;
  className?: string;
  register: UseFormRegisterReturn;
}

export const RadioButtonField = ({
  label,
  error,
  className,
  register,
  id,
  value,
  type = 'checkbox',
}: RadioButtonFieldProps) => {
  const { name } = register;
  const renderError = renderWhenTrue(() => <ErrorTextStyle>{error?.message}</ErrorTextStyle>);
  return (
    <InputWrapper className={className}>
      <FlexStyle>
        <Label htmlFor={id || name}>
          <Caption dangerouslySetInnerHTML={{ __html: label }} />
          <Input type={type} id={id || name} value={value} {...register} />
        </Label>
        {renderError(!!error)}
      </FlexStyle>
    </InputWrapper>
  );
};
