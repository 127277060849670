import React from 'react';
import NextLink from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { always } from 'ramda';

import { ROUTE } from '@utils/constants';
import { GiftCertificateProduct } from '@definitions/giftCertificate.types';
import { renderWhenTrueOtherwise } from '@utils/rendering';
import { changeImageSize } from '@components/optimisedImageWrapper/optimisedImageWrapper.component';

import {
  Title,
  Link,
  Caption,
  BackgroundImage,
  ImageContainer,
  CardCopy,
  CardCopyWrapper,
  PlatformCopy,
  LeftCopy,
  PriceCopy,
  TitleCopy,
  Card,
  CardDollar,
} from './giftCertificateCard.styles';

export interface GiftCertificateCardProps {
  giftCertificate: GiftCertificateProduct;
  imageHeight?: string;
  imageWidth?: string;
  smallCard?: boolean;
  imageStyles?: FlattenSimpleInterpolation;
}

export const GiftCertificateCard = ({
  giftCertificate,
  imageHeight,
  imageWidth,
  smallCard = false,
  imageStyles = css``,
}: GiftCertificateCardProps) => {
  const intl = useIntl();
  const titleCopy = intl.formatMessage({ id: 'giftCertificateCard.title', defaultMessage: 'Gift Card' });
  const priceCopy = intl.formatMessage({ id: 'giftCertificateCard.price', defaultMessage: '$100-$10000' });
  const renderCopy = renderWhenTrueOtherwise(
    always(
      <CardDollar>
        <FormattedMessage id="giftCertificate.dollar" defaultMessage="$" />
      </CardDollar>
    ),
    always(
      <CardCopy>
        <LeftCopy>
          <PlatformCopy>
            <FormattedMessage id="giftCertificate.platform" defaultMessage="Platform:" />
          </PlatformCopy>
          <TitleCopy>{titleCopy}</TitleCopy>
        </LeftCopy>
        <PriceCopy>{priceCopy}</PriceCopy>
      </CardCopy>
    )
  );

  return (
    <a
      onClick={() => {
        window.history.pushState({}, '', ROUTE.GIFT_CARD);
        window.location.href = ROUTE.GIFT_CARD;
      }}
    >
      <Link>
        <ImageContainer customWidth={imageWidth} customHeight={imageHeight} customStyles={imageStyles}>
          <Card>
            <div>
              {giftCertificate.variants.map(({ value }, index) => (
                <BackgroundImage
                  backgroundImage={changeImageSize(value.image, 800)}
                  key={index}
                  index={index}
                  variantsCount={giftCertificate.variants.length}
                />
              ))}
            </div>
            <CardCopyWrapper>{renderCopy(smallCard)}</CardCopyWrapper>
          </Card>
        </ImageContainer>
        <Caption>
          <Title>{titleCopy}</Title>
          <div>{priceCopy}</div>
        </Caption>
      </Link>
    </a>
  );
};
