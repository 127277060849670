import React from 'react';

import { HeadingValue } from '@definitions/common.types';
import { HEADER_TYPE } from '@components/articles/articleHeader/articleHeader.constants';
import { getLongestWordLength } from '@utils/helpers';
import { MAX_HEADING_WORD_LENGTH } from '@components/blocks/heroHeading/heroHeading.constants';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR } from '@utils/constants';

import { Container, Masthead, Headline, Subhead, InnerContainer } from './defaultHeader.styles';

export interface DefaultHeaderProps {
  value: HeadingValue;
}

export const DefaultHeader = ({
  value: { masthead, headline, subhead, backgroundColor = DEFAULT_BACKGROUND_COLOR, textColor = DEFAULT_TEXT_COLOR },
}: DefaultHeaderProps) => {
  const isLongWordInHeadline = !!headline && getLongestWordLength(headline) > MAX_HEADING_WORD_LENGTH;

  return (
    <Container data-testid={HEADER_TYPE.HEADING} backgroundColor={backgroundColor} textColor={textColor}>
      <InnerContainer>
        <Masthead>{masthead}</Masthead>
        <Headline isLongWord={isLongWordInHeadline} dangerouslySetInnerHTML={{ __html: headline }} />
        <Subhead>{subhead}</Subhead>
      </InnerContainer>
    </Container>
  );
};
