import React from 'react';

import { Artwork } from '@definitions/artworks.types';
import { BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { ArtworkList } from '@components/artworkList';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR } from '@utils/constants';

import { Container, imageStyles } from './productsBlock.styles';

export interface ProductsBlockProps {
  artworks: Artwork[];
  textColor?: string;
  backgroundColor?: string;
  hasSpacingTop?: boolean;
  shoppable?: boolean;
  accessToken?: string;
}

export const ProductsBlock = ({
  artworks,
  hasSpacingTop = true,
  textColor = DEFAULT_TEXT_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  shoppable,
  accessToken,
}: ProductsBlockProps) => (
  <Container data-testid={BLOCK_TYPE.PRODUCTS} hasSpacingTop={hasSpacingTop}>
    <ArtworkList
      artworks={artworks}
      imageStyles={imageStyles}
      textColor={textColor}
      backgroundColor={backgroundColor}
      shoppable={shoppable}
      accessToken={accessToken}
    />
  </Container>
);
