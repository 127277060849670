import styled, { css } from 'styled-components';

import { Button as UnstyledButton } from '@components/form';
import { disabledButtonContainerStyles } from '@components/common';
import { OFFSET, flex } from '@theme/helpers';
import { COLORS } from '@theme/colors';
import { transition } from '@utils/helpers';

export const InfoTooltip = styled.div`
  position: absolute;
  top: -${OFFSET.M};
  box-shadow: 0 0 ${OFFSET.S} 0 ${COLORS.BLACK20};
  padding: 6px;
  background: ${COLORS.WHITE};
  transform: scale(0);
  opacity: 0;
  ${transition()};
  transform-origin: left;
  pointer-events: none;
  white-space: nowrap;
  &::selection {
    background: transparent;
  }
`;

export const ButtonContainer = styled.div<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${disabledButtonContainerStyles}

      &:hover {
        ${InfoTooltip} {
          transform: scale(1);
          opacity: 1;
        }
      }
    `};
`;

export const Button = styled(UnstyledButton)`
  ${flex({})};
  border: 0;
  padding: 0;
  height: unset;

  &:hover {
    background: transparent;
  }
`;
