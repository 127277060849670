import styled from 'styled-components';

import { legalPageFontStyles, Title } from '@components/blocks/blocks.styles';
import { OFFSET } from '@theme/helpers';

import { legalPageRichTextStyles } from '../simpleLegalBlock/simpleLegalBlock.styles';

export const Container = styled.div`
  ${legalPageFontStyles};
`;

export const FaqBlockTitle = styled(Title)`
  margin-bottom: 0;
`;

export const FaqContentBlockTitle = styled.div`
  padding-top: ${OFFSET.S};
  margin-bottom: 15px;
`;

export const FaqContentBlockBody = styled.div`
  ${legalPageRichTextStyles};
`;

export const TocItem = styled.div`
  display: flex;
  padding: 6px 0;
  align-items: center;
  cursor: pointer;

  & > svg {
    height: 8px;
    margin-right: 7px;
  }
`;
export const TocTitle = styled.div``;
