import React, { Fragment } from 'react';
import Head from 'next/head';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import { NextSeo, DefaultSeo } from 'next-seo';
import { useIntl } from 'react-intl';
import { always } from 'ramda';
import { useRouter } from 'next/router';

import { Header } from '@components/header';
import { Footer } from '@components/footer';
import { useFooter } from '@hooks/useFooter';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { MainAnimateContainer } from '@components/mainAnimateContainer';
import { HEADER_TOP } from '@components/header/header.constants';
import { Seo } from '@definitions/common.types';
import { FeatureNavbar } from '@definitions/articles.types';
import { getMetaTags, isBrowser } from '@utils/helpers';
import { usePreviewLogin } from '@hooks/useCmsUserAuth';
import { PreviewMark } from '@components/previewMark';
import { LANDMARK_ROLES } from '@utils/constants';
import { useScrollRestoration } from '@hooks/useScrollRestoration';
import { useGdprConsents } from '@hooks/useGdprConsents';
import { CONSENT_OPTION, COOKIE_TYPES } from '@definitions/gdprConsents.types';

import { Container, Content, SkipLink } from './layout.styles';

declare const process: {
  env: {
    NEXT_PUBLIC_FACEBOOK_PIXEL_ID: string;
    NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID: string;
  };
};

const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
const GOOGLE_OPTIMIZE_ID = process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_ID;

const RETRY_INTERVAL = 3000;

export interface LayoutProps {
  children: React.ReactNode;
  featureNavbar?: FeatureNavbar;
  title?: string;
  defaultDescription?: string;
  firstProductImageURL?: Seo | null;
  seo?: Seo | null;
  headerTop?: number;
  customHeader?: () => React.ReactNode;
  noAnimateContainer?: boolean;
  microDataTags?: { property: string; content: string }[];
}

export const Layout = ({
  children,
  featureNavbar,
  headerTop = HEADER_TOP.REGULAR,
  title,
  seo,
  defaultDescription = '',
  customHeader,
  noAnimateContainer = false,
  microDataTags = [],
  firstProductImageURL,
}: LayoutProps) => {
  useFooter();
  const intl = useIntl();
  const { gdprConsent, gdprConsents } = useGdprConsents();
  const { isAuthed } = usePreviewLogin();
  const origin = isBrowser() ? location?.origin : '';
  const router = useRouter();
  useScrollRestoration();
  const isExclusive = router.pathname === '/exclusive/[slug]';
  const defaultTitle = intl.formatMessage({
    id: 'layout.defaultTitle',
    defaultMessage: 'PLATFORM',
  });

  const headerTitle = intl.formatMessage(
    {
      id: 'layout.seoTitle',
      defaultMessage: '{title} | PLATFORM',
    },
    { title: seo?.title?.length ? seo.title : title }
  );

  const seoImageTitle = intl.formatMessage({
    id: 'layout.defaultSeoImageTitle',
    defaultMessage: 'Platform David Zwirner',
  });

  const seoImage = seo?.image
    ? [seo.image]
    : firstProductImageURL
    ? [firstProductImageURL.image]
    : [{ url: '', alt: seoImageTitle, width: 1200, height: 630 }];

  const { ogTitle = headerTitle, ogDescription, description = defaultDescription, additionalMetaTags } = getMetaTags(
    seo?.tags
  );

  const initTrackingTools = () => {
    if (FB_PIXEL_ID) {
      try {
        (window as any).fbq('consent', 'grant');
      } catch (err) {
        setTimeout(() => {
          initTrackingTools();
        }, RETRY_INTERVAL);
      }
    }
  };

  useEffectOnce(() => {
    if (gdprConsent === CONSENT_OPTION.ALLOW) {
      initTrackingTools();
    }
  });

  useDeepCompareEffect(() => {
    if (gdprConsents[COOKIE_TYPES.OTHER_COOKIES]) {
      initTrackingTools();
    }
  }, [gdprConsents]);

  const renderHeader = () =>
    customHeader ? customHeader() : <Header featureNavbar={featureNavbar} headerTop={headerTop} />;
  const renderChildren = renderWhenTrueOtherwise(
    always(children),
    always(<MainAnimateContainer>{children}</MainAnimateContainer>)
  );
  const renderPreviewMark = renderWhenTrue(always(<PreviewMark />));

  const renderFacebookPixel = renderWhenTrue(
    always(
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${FB_PIXEL_ID}');
                fbq.disablePushState = true;
                fbq('consent', 'revoke');
              `,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
          />
        </noscript>
      </Fragment>
    )
  );

  const renderGoogleOptimize = renderWhenTrue(
    always(
      <Fragment>
        <script async src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE_ID}`}></script>
      </Fragment>
    )
  );

  return (
    <Container>
      <DefaultSeo defaultTitle={defaultTitle} />
      <NextSeo
        title={headerTitle}
        description={description}
        openGraph={{
          site_name: 'PLATFORM',
          title: ogTitle,
          description: ogDescription || description || defaultDescription,
          images: seoImage,
          type: 'website',
          url: router.asPath,
        }}
        additionalMetaTags={[...additionalMetaTags, ...microDataTags]}
        noindex={seo?.noIndex || isExclusive}
        nofollow={seo?.noFollow || isExclusive}
        canonical={router.asPath}
      />
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        {renderGoogleOptimize(!!GOOGLE_OPTIMIZE_ID)}
        {renderFacebookPixel(!!FB_PIXEL_ID)}
      </Head>
      {renderPreviewMark(isAuthed)}
      <SkipLink href="#main-content">Skip Navigation</SkipLink>
      {renderHeader()}
      <Content id="main-content" role={LANDMARK_ROLES.MAIN}>
        {renderChildren(noAnimateContainer)}
      </Content>
      <Footer />
    </Container>
  );
};
