import styled from 'styled-components';

import { Title } from '@components/shippingCountries/shippingCountries.styles';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  padding: 0 ${OFFSET.S};
  height: 100%;
`;

export const ButtonsContainer = styled.div`
  padding: ${OFFSET.S} 0 ${OFFSET.M};

  > * {
    margin-top: ${OFFSET.S};
  }
`;

export const FormTitle = styled(Title)``;
