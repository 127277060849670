export enum PINTEREST_EVENTS {
  ADD_TO_CART = 'addtocart',
  PAGE_VISIT = 'pagevisit',
  SIGNUP = 'signup',
  LEAD = 'lead',
}

interface Options {
  lead_type?: string;
  value?: number;
  order_quantity?: number;
  currency?: string;
  product_id?: string | number;
  line_items?: {
    product_name: string;
    product_id: string;
    product_price: number;
    product_quantity: number;
  }[];
}

const pageView = () => {
  if ((window as any).pintrk) {
    (window as any).pintrk('track', PINTEREST_EVENTS.PAGE_VISIT);
  }
};

const event = (name: PINTEREST_EVENTS, options: Options = {}) => {
  if ((window as any).pintrk) {
    (window as any).pintrk('track', name, options);
  }
};

export default { pageView, event };
