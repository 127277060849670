import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';

import { RequestAddFavoritePayload } from '@definitions/artworks.types';
import { GlobalState } from '@modules/createStore';
import { plpActions, PLP_PREFIXES } from '@modules/plp';
import { CurrentPlp, determinePlpContext } from '@modules/plp/plp.helpers';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useFavorite = (currentPlp: CurrentPlp) => {
  const dispatch: AppDispatch = useDispatch();

  const prefix: string = determinePlpContext(PLP_PREFIXES, currentPlp);

  const addFavorite = (data: RequestAddFavoritePayload) =>
    dispatch(plpActions.addToFavorite(prefix)(data)).then(unwrapResult);

  const removeFavorite = (data: RequestAddFavoritePayload) =>
    dispatch(plpActions.removeFromFavorite(prefix)(data)).then(unwrapResult);

  return { addFavorite, removeFavorite };
};
