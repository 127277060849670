import { LinkSet, LinkSetType } from '@definitions/common.types';

export interface UseLinkProps {
  link?: LinkSet;
}

export const useLink = ({ link }: UseLinkProps) => {
  const isExternalLink = link?.type === LinkSetType.EXTERNAL;
  const href = isExternalLink ? `${link?.urlPath || ''}` : `/${link?.slug || ''}`;
  const target = isExternalLink ? '_blank' : '';

  return {
    isExternalLink,
    href,
    target,
  };
};
