import styled, { css } from 'styled-components';

import { COLORS } from '@theme/colors';
import { typography } from '@theme/font';

export const copyStyles = css`
  ${typography({})};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 200ms ease-in-out;
`;

export const LogoutCopy = styled.button`
  ${copyStyles};
  opacity: 0;
`;

export const PreviewCopy = styled.button`
  ${copyStyles};
  opacity: 1;
`;

export const Container = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 30px;
  text-align: center;
  z-index: 99999;
  background-color: ${COLORS.WHITE40};
  border: 1px solid ${COLORS.BLACK};
  color: ${COLORS.BLACK};
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${COLORS.BLACK};

    ${LogoutCopy} {
      opacity: 1;
      color: ${COLORS.WHITE};
    }

    ${PreviewCopy} {
      opacity: 0;
    }
  }
`;
