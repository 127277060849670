import styled, { css } from 'styled-components';
import { always, both, cond, ifElse, propEq, T } from 'ramda';

import {
  CONTAINER_PADDING,
  IMAGE_WITH_TEXT_IMAGE_OPTION,
  INTRO_TEXT_FONT_SIZE,
  INTRO_TEXT_POSITION,
  NARROW_CONTAINER_WIDTH,
} from '@components/blocks/blocks.constants';
import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, FONT_WEIGHT, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

interface StylesProps {
  fontSize: INTRO_TEXT_FONT_SIZE;
  headingPlacement: INTRO_TEXT_POSITION;
  isWhite: boolean;
  textColor?: string;
  displayImageOption: IMAGE_WITH_TEXT_IMAGE_OPTION;
}

export const Heading = styled.h2`
  font-weight: ${FONT_WEIGHT.BOOK};
  text-transform: uppercase;
  margin-top: 0;
`;

export const Title = styled.span`
  display: block;
`;

export const Description = styled.p`
  margin: 0 0 ${OFFSET.XS};
`;

export const Cta = styled.div``;

const smallFont = css`
  ${typography({ spacing: LETTER_SPACING.M })};
`;

const largeFont = css`
  ${typography({ size: FONT_SIZE.M })};
`;

const getFontStyles: ({ fontSize }: StylesProps) => string = ifElse(
  propEq('fontSize', INTRO_TEXT_FONT_SIZE.SMALL),
  always(smallFont),
  always(largeFont)
);

const getCTAMarginLeftDesktop = cond([
  [
    both(propEq('headingPlacement', INTRO_TEXT_POSITION.LEFT), propEq('fontSize', INTRO_TEXT_FONT_SIZE.SMALL)),
    always('-14px'),
  ],
  [propEq('headingPlacement', INTRO_TEXT_POSITION.TOP), always('auto')],
  [T, always('initial')],
]) as (props: StylesProps) => string;

const getCTAMarginLeftMobile: ({ fontSize }: StylesProps) => string = ifElse(
  propEq('fontSize', INTRO_TEXT_FONT_SIZE.SMALL),
  always('-14px'),
  always('initial')
);

const getDescriptionMaxWidth = cond([
  [
    both(propEq('headingPlacement', INTRO_TEXT_POSITION.LEFT), propEq('fontSize', INTRO_TEXT_FONT_SIZE.SMALL)),
    always(194),
  ],
  [
    both(propEq('headingPlacement', INTRO_TEXT_POSITION.LEFT), propEq('fontSize', INTRO_TEXT_FONT_SIZE.LARGE)),
    always(224),
  ],
  [T, always(NARROW_CONTAINER_WIDTH)],
]) as (props: StylesProps) => string;

export const Container = styled.div<StylesProps>`
  ${getFontStyles};

  margin: 0 ${CONTAINER_PADDING}px;
  display: flex;
  flex-direction: column;
  color: ${({ isWhite, textColor }) => (isWhite ? COLORS.WHITE : textColor)};

  ${media.tablet} {
    flex-direction: ${({ headingPlacement }) => (headingPlacement === INTRO_TEXT_POSITION.LEFT ? 'column' : 'row')};
  }

  ${Heading} {
    max-width: ${({ fontSize }) => (fontSize === INTRO_TEXT_FONT_SIZE.SMALL ? 194 : 224)}px;
    ${getFontStyles};
    ${media.tablet} {
      margin-right: ${({ headingPlacement }) =>
        headingPlacement === INTRO_TEXT_POSITION.TOP ? `${CONTAINER_PADDING * 2}px` : null};
    }
  }

  ${Description} {
    display: ${({ displayImageOption }) =>
      displayImageOption === IMAGE_WITH_TEXT_IMAGE_OPTION.FULL_BLEED ? 'none' : 'block'};
    ${media.tablet} {
      display: block;
      max-width: ${getDescriptionMaxWidth}px;
    }
  }

  ${Cta} {
    margin-left: ${getCTAMarginLeftMobile};

    ${media.tablet} {
      margin-left: ${getCTAMarginLeftDesktop};
    }
  }
`;
