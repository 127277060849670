import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, prop, sortBy, toLower, equals } from 'ramda';

import { ShippingCountry } from '@definitions/shipping.types';
import { useShippingCountry } from '@hooks/useShipping';
import { useHeaderSublevel } from '@hooks/useUi';
import { ALLOWED_SHIPPING_COUNTRIES } from '@modules/shipping/shipping.constants';
import { LANDMARK_ROLES } from '@utils/constants';

import { SHIPPING_COUNTRIES_TEST_ID } from './shippingCountries.constants';
import { Container, Title, CountryList, CountryListItem, CountryLink } from './shippingCountries.styles';

export const ShippingCountries = () => {
  const sortedCountries = sortBy(compose(toLower, prop('name')), ALLOWED_SHIPPING_COUNTRIES);
  const { shippingCountryCode, setShippingCountryCode } = useShippingCountry();
  const { closeHeaderSublevel } = useHeaderSublevel();

  return (
    <Container data-testid={SHIPPING_COUNTRIES_TEST_ID} role={LANDMARK_ROLES.REGION}>
      <Title>
        <FormattedMessage id="shippingCountries.title" defaultMessage="We currently ship to:" />
      </Title>

      <CountryList>
        {sortedCountries.map((country: ShippingCountry, index) => (
          <CountryListItem
            key={country.code}
            onClick={() => {
              setShippingCountryCode({ shippingCountryCode: country.code });
              closeHeaderSublevel();
            }}
          >
            <CountryLink isActive={equals(country.code, shippingCountryCode)} autoFocus={!index}>
              {country.name}
            </CountryLink>
          </CountryListItem>
        ))}
      </CountryList>
    </Container>
  );
};
