import React from 'react';
import { allPass, always, complement, isNil, propEq } from 'ramda';
import NextLink from 'next/link';

import { Artwork } from '@definitions/artworks.types';
import { IntroText as IntroTextTypes } from '@definitions/introText.types';
import { CustomHorizontalScrollContainer } from '@components/customHorizontalScrollContainer';
import { IntroText } from '@components/introText';
import { renderWhenOtherwise, renderWhenTrue } from '@utils/rendering';
import { ArtworkCard, ArtworkDetail } from '@components/artworkCard';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { isNotEmpty } from '@utils/helpers';
import { PRODUCT_TYPE } from '@utils/constants';
import { GiftCertificateCard } from '@components/giftCertificateCard';
import { BLOCK_TYPE, INTRO_TEXT_POSITION } from '@components/blocks';

import {
  Container,
  ArtworksContainer,
  BrowseButton,
  ButtonContainer,
  getImageStyles,
  ArtworkContainer,
  TopIntroTextContainer,
  LeftIntroTextContainer,
  getGiftCardStyles,
} from './productsWithTitle.styles';

export interface ProductsWithTitleProps extends IntroTextTypes {
  products: Artwork[];
  textColor?: string;
  backgroundColor?: string;
  shoppable?: boolean;
  accessToken?: string;
}

export const ProductsWithTitle = ({
  products,
  textColor,
  backgroundColor,
  shoppable,
  accessToken,
  ...restProps
}: ProductsWithTitleProps) => {
  const isLeftPlacement = restProps.headingPlacement === INTRO_TEXT_POSITION.LEFT;
  const { isMobile } = useBreakpoint();

  const renderTopIntroText = renderWhenTrue(
    always(
      <TopIntroTextContainer>
        <IntroText {...restProps} textColor={textColor} />
      </TopIntroTextContainer>
    )
  );
  const renderLeftIntroText = renderWhenTrue(
    always(
      <LeftIntroTextContainer>
        <IntroText {...restProps} textColor={textColor} />
      </LeftIntroTextContainer>
    )
  );

  const isValidLink = allPass([
    complement(isNil),
    propEq('type', 'internal'),
    isNotEmpty('displayText'),
    isNotEmpty('slug'),
  ])(restProps.link);

  const renderCta = renderWhenTrue(
    always(
      <ButtonContainer>
        <a
          onClick={() => {
            window.history.pushState({}, '', `/${restProps.link?.slug}`);
          }}
          href={`/${restProps.link?.slug}`}
        >
          <BrowseButton aria-label={restProps.title}>{restProps.link?.displayText}</BrowseButton>
        </a>
      </ButtonContainer>
    )
  );

  const renderArtwork = renderWhenOtherwise(
    propEq('type', PRODUCT_TYPE.PRODUCT),
    (artwork) => (
      <ArtworkCard
        artwork={artwork}
        imageStyles={getImageStyles(isLeftPlacement)}
        textColor={textColor}
        backgroundColor={backgroundColor}
        shoppable={shoppable}
        accessToken={accessToken}
      >
        <ArtworkDetail>
          {artwork.title}, {artwork.year}
        </ArtworkDetail>
        <ArtworkDetail>{artwork.medium}</ArtworkDetail>
      </ArtworkCard>
    ),
    (giftCertificate) => (
      <GiftCertificateCard imageStyles={getGiftCardStyles(isLeftPlacement)} giftCertificate={giftCertificate} />
    )
  );

  return (
    <Container data-testid={BLOCK_TYPE.PRODUCTS_WITH_TITLE}>
      <div>{renderTopIntroText(!isLeftPlacement || isMobile)}</div>
      <CustomHorizontalScrollContainer itemsLength={products.length}>
        <ArtworksContainer isLeftPlacement={isLeftPlacement}>
          <div>{renderLeftIntroText(isLeftPlacement && !isMobile)}</div>
          {products?.map((artwork, index) => (
            <ArtworkContainer key={index} isLeftPlacement={isLeftPlacement}>
              {renderArtwork(artwork)}
            </ArtworkContainer>
          ))}
          {renderCta(isValidLink && isLeftPlacement)}
        </ArtworksContainer>
      </CustomHorizontalScrollContainer>
    </Container>
  );
};
