import React, { InputHTMLAttributes } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import { renderWhenTrue } from '@utils/rendering';

import { ErrorMessage } from '../form.styles';

import { InputWrapper, Input, Label, Caption } from './checkboxField.styles';

export interface CheckboxFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError;
  label: string;
  className?: string;
  register: UseFormRegisterReturn;
}

export const CheckboxField = ({
  label,
  error,
  className,
  register,
  id,
  value,
  type = 'checkbox',
}: CheckboxFieldProps) => {
  const renderError = renderWhenTrue(() => <ErrorMessage>{error?.message}</ErrorMessage>);
  const { name } = register;

  return (
    <InputWrapper className={className}>
      <Label htmlFor={id || name}>
        <Caption dangerouslySetInnerHTML={{ __html: label }} />
        <Input type={type} id={id || name} value={value} {...register} />
      </Label>
      {renderError(!!error)}
    </InputWrapper>
  );
};
