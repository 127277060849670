import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { ImageSet } from '@definitions/common.types';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { getImageSrc, getImageSrcSet } from '@utils/helpers';

export interface UseImageProps {
  desktopImage: ImageSet | null;
  mobileImage?: ImageSet;
}

export const useImage = ({ desktopImage, mobileImage }: UseImageProps) => {
  const { isMobile } = useBreakpoint();
  const [image, setImage] = useState({ src: '', srcSet: '', alt: '', title: '' });
  const shouldUseMobileImage = mobileImage && isMobile;

  useDeepCompareEffect(() => {
    const imageData = shouldUseMobileImage ? mobileImage : desktopImage;

    if (imageData) {
      const src = getImageSrc(imageData);
      const srcSet = getImageSrcSet(imageData);
      setImage({ src, srcSet, alt: imageData?.alt, title: imageData?.title });
    }
  }, [shouldUseMobileImage, desktopImage]);

  return image;
};
