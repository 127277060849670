import React, { Fragment } from 'react';
import { allPass, always, complement, equals, isEmpty, isNil } from 'ramda';

import { IntroText as IntroTextTypes } from '@definitions/introText.types';
import { LinkSetType } from '@definitions/common.types';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import {
  IMAGE_WITH_TEXT_IMAGE_OPTION,
  INTRO_TEXT_FONT_SIZE,
  INTRO_TEXT_POSITION,
} from '@components/blocks/blocks.constants';
import { ReadMoreButton } from '@components/readMoreButton';
import { isNotEmpty } from '@utils/helpers';
import { COLORS } from '@theme/colors';
import { DEFAULT_TEXT_COLOR } from '@utils/constants';

import { Container, Heading, Title, Description, Cta } from './introText.styles';

export interface IntroTextProps extends IntroTextTypes {
  isWhite?: boolean;
  displayImageOption?: IMAGE_WITH_TEXT_IMAGE_OPTION;
  textColor?: string;
}

export const IntroText = ({
  title,
  subtitle,
  description,
  link = { slug: '', displayText: '', urlPath: '', type: 'internal' },
  displayTextOption = INTRO_TEXT_FONT_SIZE.SMALL,
  headingPlacement = INTRO_TEXT_POSITION.TOP,
  isWhite = false,
  displayImageOption = IMAGE_WITH_TEXT_IMAGE_OPTION.CENTERED,
  textColor = DEFAULT_TEXT_COLOR,
}: IntroTextProps) => {
  const hideTitle = equals(displayImageOption, IMAGE_WITH_TEXT_IMAGE_OPTION.CENTERED_WITH_TITLE);
  const renderSubtitle = renderWhenTrue(always(<Title>{subtitle}</Title>));
  const renderCopy = renderWhenTrue(always(<Description>{description}</Description>));
  const isValidLink = allPass([complement(isEmpty), complement(isNil), isNotEmpty('displayText'), isNotEmpty('slug')])(link);
  const url = link?.type === LinkSetType.INTERNAL ? `/${link?.slug ?? ''}` : link?.urlPath ?? '';

  const renderCta = renderWhenTrue(
    always(
      <Cta>
        <ReadMoreButton color={isWhite ? COLORS.WHITE : textColor} {...link} ariaLabel={title} />
      </Cta>
    )
  );

  const renderLink = renderWhenTrueOtherwise(
    always(
      <a onClick={() => { if(link.slug){ window.history.pushState({}, '', url); } window.location.href = url; }} href={`${url}`} >
        <Title>{title}</Title>
        {renderSubtitle(!!subtitle)}
      </a>
    ),
    always(
      <Fragment>
        <Title>{title}</Title>
        {renderSubtitle(!!subtitle)}
      </Fragment>
    )
  );

  const renderHeading = renderWhenTrue(always(<Heading>{renderLink(isValidLink)}</Heading>));

  return (
    <Container
      fontSize={displayTextOption}
      headingPlacement={headingPlacement}
      isWhite={isWhite}
      displayImageOption={displayImageOption}
    >
      {renderHeading(!hideTitle)}
      {renderCopy(!!description)}
      {renderCta(isValidLink)}
    </Container>
  );
};
