import React, { useState } from 'react';
import { always, ifElse, is, pick } from 'ramda';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FlattenSimpleInterpolation, css } from 'styled-components';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import SlickSlider, { Settings } from 'react-slick';

import { Artwork, Image, IMAGE_URL_TYPE } from '@definitions/artworks.types';
import { FavoriteButton } from '@components/favoriteButton';
import { CountDown } from '@components/countDown';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { formatStorePrice, getFullArtistName, isInSellingPeriod } from '@utils/helpers';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR, ROUTE } from '@utils/constants';
import { useTouchDevice } from '@hooks/useDevice';
import { useCountDown } from '@hooks/useCountDown';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useSellingState, useWaitlistButtonLabel } from '@hooks/useArtworks/useArtworks';
import { OptimisedImage } from '@components/optimisedImage';
import { OfferedIn } from '@components/offeredIn';
import { UnitCountDown } from '@components/unitCountDown';

import {
  Caption,
  Link,
  ArtworkArtist,
  ArtworkDetail,
  FavoriteButtonContainer,
  Placeholder,
  ImageWrapper,
  HoverImageWrapper,
  SliderWrapper,
  Wrapper,
  StyledWaitlistButton,
  GrayLabel,
  ImagePlaceholder,
  PlaceHolderWrapper,
  PlaceHolderText,
} from './artworkCard.styles';

export const HOVER_IMAGE_TEST_ID = 'hoverImageTestId';

export interface ArtworkCardProps {
  artwork: Artwork;
  imageHeight?: string;
  imageWidth?: string;
  imageStyles?: FlattenSimpleInterpolation;
  children?: React.ReactNode;
  imageUrlType?: IMAGE_URL_TYPE;
  textColor?: string;
  backgroundColor?: string;
  shoppable?: boolean;
  accessToken?: string;
  withSlickSlider?: boolean;
  hasFavoriteOption?: boolean;
  isPastArtwork?: boolean;
  displayPrice?: boolean;
}

export const ArtworkCard = ({
  artwork,
  imageHeight,
  imageWidth,
  textColor = DEFAULT_TEXT_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  children = null,
  imageStyles = css``,
  shoppable,
  accessToken,
  withSlickSlider,
  hasFavoriteOption = true,
  isPastArtwork = false,
  displayPrice = true,
}: ArtworkCardProps) => {
  const router = useRouter();
  const { isTouchDevice } = useTouchDevice();
  const { isMobile } = useBreakpoint();
  const {
    artist,
    images,
    primaryImage,
    layoutM,
    bcId,
    title,
    price,
    inventoryLevel,
    favorite,
    slug,
    addToWaitlist,
    waitlist,
    liveEndAt,
    liveStartAt,
    lowInventoryLevel,
    artistDesignedPlpLink,
    variants,
  } = artwork;

  const withinSellingPeriod = isInSellingPeriod({ liveEndAt, liveStartAt });
  const isExclusive = router.pathname === '/exclusive/[slug]';
  const isArtistDesignedCurrentLayout = router.asPath === ROUTE.ARTIST_DESIGNED;
  const exclusiveToken = accessToken ? accessToken : 'true';
  const hoverImage = images.find(({ isThumbnail }) => !isThumbnail);
  const isHoverAble = !!hoverImage && !isTouchDevice;
  const query = isExclusive ? { ...router.query, exclusive: exclusiveToken } : router.query;
  const { isEnding, isPostSellingDate, isBeforeSellingDate } = useSellingState(artwork);
  const label = useWaitlistButtonLabel({
    isShoppable: !!shoppable,
    isExclusive: isExclusive,
    isPdp: false,
    isPostSellingDate,
  });

  const { days, hours, minutes, seconds } = useCountDown({ targetDate: liveEndAt });
  const [isDragging, setIsDragging] = useState(false);
  const isTradeMemberRoute = router.pathname === ROUTE.TRADE;
  const isArtistDesignedFutureItem = router.asPath === ROUTE.ARTIST_DESIGNED && dayjs(liveStartAt).isAfter(dayjs());
  const artistDesignedDisplayText = artistDesignedPlpLink?.displayText;

  const slickSliderSettings: Settings = {
    arrows: false,
    dots: true,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
    swipeToSlide: true,
  };

  const renderImage = ({ urlStandard, urlTiny, description }: Image) => (
    <OptimisedImage
      src={urlStandard}
      placeholderSrc={urlTiny}
      alt={description}
      customHeight={imageHeight}
      customWidth={imageWidth}
      customStyles={imageStyles}
      defaultDimensions="auto"
    />
  );

  const renderPlaceholder = () => (
    <Placeholder customHeight={imageHeight} customWidth={imageWidth} customStyles={imageStyles}>
      <FormattedMessage id="artworkCard.placeholder" defaultMessage="Placeholder" />
    </Placeholder>
  );

  const renderDefaultImage: (item: Image) => React.ReactNode = ifElse(is(Object), renderImage, renderPlaceholder);
  const renderHoverImage = renderWhenTrue(
    always(
      <HoverImageWrapper data-testid={HOVER_IMAGE_TEST_ID}>
        <OptimisedImage
          src={hoverImage ? hoverImage.urlStandard : ''}
          placeholderSrc={hoverImage ? hoverImage.urlTiny : ''}
          alt={hoverImage ? hoverImage.description : ''}
          customStyles={imageStyles}
          customWidth={imageWidth}
        />
      </HoverImageWrapper>
    )
  );
  const renderWaitListButton = renderWhenTrue(
    always(
      <StyledWaitlistButton
        isArtistDesignedCurrentLayout={isArtistDesignedCurrentLayout}
        layoutM={layoutM}
        slug={slug}
        waitlist={waitlist}
        bcId={bcId}
        title={title}
        textColor={textColor}
        backgroundColor={backgroundColor}
        label={label}
        isPrelaunchable={isBeforeSellingDate && isExclusive && !shoppable}
        shouldOverridePrelaunchableCopy={addToWaitlist}
        isPostSellingDate={isPostSellingDate}
      />
    )
  );

  const renderPrice = renderWhenTrue(() => <ArtworkDetail>{formatStorePrice(price)}</ArtworkDetail>);
  const renderSoldCopy = renderWhenTrue(() => (
    <ArtworkDetail>
      <FormattedMessage
        id="artworkCard.sold"
        defaultMessage={artwork.category.toLowerCase() === 'products' ? 'Sold Out' : 'Sold'}
      />
    </ArtworkDetail>
  ));

  const renderImages = renderWhenTrueOtherwise(
    always(
      <SliderWrapper>
        <SlickSlider {...slickSliderSettings}>
          <ImageWrapper>{primaryImage && renderDefaultImage(primaryImage)}</ImageWrapper>
          {hoverImage ? <ImageWrapper>{renderHoverImage(!!hoverImage)}</ImageWrapper> : null}
        </SlickSlider>
      </SliderWrapper>
    ),
    always(
      <ImageWrapper>
        {primaryImage && renderDefaultImage(primaryImage)}
        {renderHoverImage(isHoverAble)}
      </ImageWrapper>
    )
  );

  const renderDatePlaceholder = (dateString: string) => {
    const date = dayjs(dateString).format('MMM YYYY');
    return renderTextPlaceholder(date);
  };

  const renderTextPlaceholder = (text: string) => {
    return (
      <PlaceHolderWrapper>
        <ImagePlaceholder>
          <PlaceHolderText>{text}</PlaceHolderText>
        </ImagePlaceholder>
      </PlaceHolderWrapper>
    );
  };

  const renderComingSoon = renderWhenTrue(always(<GrayLabel>Coming soon</GrayLabel>));

  const renderTimeCountDown = renderWhenTrue(() => (
    <CountDown days={days} hours={hours} minutes={minutes} seconds={seconds} />
  ));

  const renderUnitCountDown = renderWhenTrue(() => (
    <UnitCountDown isPlpVariant={variants?.length > 1} inventoryLevel={inventoryLevel} />
  ));

  const renderWhenOffered = renderWhenTrue(() => <OfferedIn liveEndAt={liveEndAt} hideIcon />);

  const renderFavoriteOption = renderWhenTrue(() => (
    <FavoriteButtonContainer>
      <FavoriteButton favorite={favorite} slug={slug} textColor={textColor} />
    </FavoriteButtonContainer>
  ));

  const renderPlaceholderItem = renderWhenTrueOtherwise(
    () => renderTextPlaceholder(artistDesignedDisplayText || ''),
    () => renderDatePlaceholder(artwork.liveStartAt)
  );

  const renderImageItem = renderWhenTrueOtherwise(
    () => renderImages(isMobile && withSlickSlider),
    () => renderPlaceholderItem(artistDesignedDisplayText && artistDesignedDisplayText.length > 0)
  );

  return (
    <Wrapper>
      <NextLink
        href={{
          pathname:
            artistDesignedPlpLink && artistDesignedPlpLink.slug.length > 0
              ? `/${artistDesignedPlpLink?.slug}`
              : `${ROUTE.ARTWORKS}/${artist.slug}/${slug}`,
          query: { ...pick(['exclusive'], query), ...(shoppable && { isShoppable: !!shoppable }) },
        }}
        passHref
      >
        <Link
          href={
            artistDesignedPlpLink && artistDesignedPlpLink.slug.length > 0
              ? `/${artistDesignedPlpLink?.slug}`
              : `${ROUTE.ARTWORKS}/${artist.slug}/${slug}`
          }
          isHoverAble={isHoverAble}
          onClick={(e) => {
            isDragging && e.preventDefault();
            window.history.pushState(
              {},
              '',
              artistDesignedPlpLink && artistDesignedPlpLink.slug.length > 0
                ? `/${artistDesignedPlpLink?.slug}`
                : `${ROUTE.ARTWORKS}/${artist.slug}/${slug}`
            );
          }}
        >
          {renderImageItem(!!primaryImage)}
          <Caption>
            <ArtworkArtist>{getFullArtistName(artist)}</ArtworkArtist>
            {children}
            {renderPrice(displayPrice && !!price && inventoryLevel > 0)}
            {renderSoldCopy(!price && withinSellingPeriod)}
            {renderComingSoon(isExclusive && !shoppable)}
            {renderTimeCountDown(isEnding && inventoryLevel > 0 && withinSellingPeriod && lowInventoryLevel === 0)}
            {renderUnitCountDown(inventoryLevel > 0 && withinSellingPeriod && inventoryLevel <= lowInventoryLevel)}
            {renderWhenOffered(isPostSellingDate && !isExclusive)}
            {renderFavoriteOption(hasFavoriteOption)}
          </Caption>
        </Link>
      </NextLink>
      {renderWaitListButton(
        !isArtistDesignedFutureItem &&
          !isPastArtwork &&
          !isTradeMemberRoute &&
          ((isExclusive && !shoppable) ||
            (isExclusive && shoppable && inventoryLevel === 0) ||
            inventoryLevel === 0 ||
            addToWaitlist ||
            waitlist ||
            (!isExclusive && dayjs().isAfter(dayjs(liveEndAt))))
      )}
    </Wrapper>
  );
};
