import styled, { css } from 'styled-components';
import { always, cond, propEq } from 'ramda';

import { media } from '@theme/media';
import { SIMPLE_LEGAL_BLOCK_TITLE } from '@components/blocks';
import { COLORS } from '@theme/colors';
import { OFFSET } from '@theme/helpers';
import { LINE_HEIGHT } from '@theme/font';

import { linkStyles, legalPageFontStyles } from '../blocks.styles';

export const legalPageRichTextStyles = css`
  p {
    margin: ${OFFSET.XS} 0;

    ${media.mobile} {
      margin: ${OFFSET.S} 0;
    }
  }

  a {
    ${linkStyles};
  }

  ul {
    margin: 0 0 0 ${OFFSET.M};
    padding: 0 0 0 ${OFFSET.S};
    list-style: none;
    line-height: ${LINE_HEIGHT.L};

    & > li {
      position: relative;

      &:before {
        content: '\\2013';
        position: absolute;
        left: -${OFFSET.S};
        top: 0;
      }
    }
  }
`;

export const Container = styled.div`
  ${legalPageFontStyles};
`;

interface TitleProps {
  displayOption: SIMPLE_LEGAL_BLOCK_TITLE;
}

const titleBlankLineStyles = css`
  margin: ${OFFSET.M} 0;
`;

const titleBottomLineStyles = css`
  border-bottom: 1px solid ${COLORS.BLACK};
  margin-bottom: ${OFFSET.M};
`;

const titleTopLineStyles = css`
  border-top: 1px solid ${COLORS.BLACK};
  margin-bottom: ${OFFSET.S};
  margin-top: ${OFFSET.L};

  ${media.mobile} {
    margin-top: ${OFFSET.M};
  }
`;

// @ts-ignore
const getTitleLineStyles: ({ displayOption }: TitleProps) => string = cond([
  [propEq('displayOption', SIMPLE_LEGAL_BLOCK_TITLE.TOP), always(titleTopLineStyles)],
  [propEq('displayOption', SIMPLE_LEGAL_BLOCK_TITLE.BOTTOM), always(titleBottomLineStyles)],
  [propEq('displayOption', SIMPLE_LEGAL_BLOCK_TITLE.BLANK), always(titleBlankLineStyles)],
]);

export const Title = styled.div<TitleProps>`
  ${legalPageFontStyles};
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding-top: 9px;
  padding-bottom: 6px;
  ${getTitleLineStyles};
`;

export const Content = styled.div`
  ${legalPageRichTextStyles};
`;
