import React, { InputHTMLAttributes } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import { renderWhenTrue } from '@utils/rendering';

import { Input, ErrorTextStyle, FlexStyle } from './textInput.styles';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  error?: FieldError;
  optional?: boolean;
  asterisk?: boolean;
  className?: string;
  nameSuffix?: string;
  autoFocus?: boolean;
  textarea?: boolean;
  register: UseFormRegisterReturn;
}

export const TextInput = ({
  nameSuffix = '',
  placeholder,
  error,
  type = 'text',
  optional = false,
  asterisk = false,
  autoFocus = false,
  className,
  textarea = false,
  register,
  ...restProps
}: TextInputProps) => {
  const renderError = renderWhenTrue(() => <ErrorTextStyle>{error?.message}</ErrorTextStyle>);
  return (
    <FlexStyle>
      <Input
        type={type}
        id={`${register.name}${nameSuffix}`}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...register}
        {...restProps}
      />
      {renderError(!!error)}
    </FlexStyle>
  );
};
