import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

import { breakpoints, heightBreakpoints, BreakpointType, DESKTOP, MOBILE, TABLET, BIG_DESKTOP } from '@theme/media';
import { isBrowser } from '@utils/helpers';

const getDeviceConfig = (width: number): BreakpointType => {
  if (width < breakpoints.tablet) {
    return MOBILE;
  }

  if (width >= breakpoints.tablet && width < breakpoints.desktop) {
    return TABLET;
  }

  if (width >= breakpoints.desktop && width < breakpoints.bigDesktop) {
    return DESKTOP;
  }

  return BIG_DESKTOP;
};

export const useBreakpoint = () => {
  if (isBrowser()) {
    const [breakpoint, setBreakpoint] = useState<BreakpointType>(() => getDeviceConfig(window.innerWidth));
    const isMobile = breakpoint === MOBILE;
    const isMobileHeight = window.innerHeight < heightBreakpoints.tablet;

    useEffect(() => {
      const calcInnerWidth = throttle(() => {
        setBreakpoint(getDeviceConfig(window.innerWidth));
      }, 200);
      window.addEventListener('resize', calcInnerWidth);
      return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return { breakpoint, isMobile, isMobileHeight };
  }

  return { breakpoint: DESKTOP, isMobile: false, isMobileHeight: false };
};
