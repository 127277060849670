import { useDispatch, useSelector } from 'react-redux';

import { shippingActions, shippingSelectors } from '@modules/shipping';
import { ShippingCountryPayload } from '@definitions/shipping.types';

export const useShippingCountry = () => {
  const dispatch = useDispatch();

  const shippingCountryCode = useSelector(shippingSelectors.selectShippingCountryCode);
  const setShippingCountryCode = (code: ShippingCountryPayload) =>
    dispatch(shippingActions.setShippingCountryCode(code));

  return { shippingCountryCode, setShippingCountryCode };
};
