import { createAsyncThunk } from '@reduxjs/toolkit';

import { EventPayload } from '@definitions/conversions.types';
import nextAPI from '@services/nextAPI';

const PREFIX = 'CONVERSIONS';
const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const sendEvent = createAsyncThunk<any, EventPayload>(`${PREFIX}/SEND_EVENT`, async (data) => {
  if (!FB_PIXEL_ID) {
    return null;
  }

  return await nextAPI.post('conversions', data);
});
