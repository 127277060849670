import styled from 'styled-components';

import { OFFSET, flex } from '@theme/helpers';

export const Container = styled.div`
  ${flex({})}
  position: relative;
  height: ${OFFSET.S};
`;

export const Value = styled.span`
  padding: 0 4px;
  min-width: 18px;
  text-align: center;
`;
