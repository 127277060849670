import React from 'react';
import { Element, Link } from 'react-scroll';

import { BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { Block } from '@definitions/common.types';
import { FAQ_BLOCK_COPY, FAQ_CONTENT_BLOCK_COPY } from '@components/blocks/faqLegalBlock/faqLegalBlock.constants';

import ArrowDown from '../../../assets/images/arrow-down.svg';
import { Title } from '../blocks.styles';

import {
  Container,
  FaqContentBlockTitle,
  FaqBlockTitle,
  FaqContentBlockBody,
  TocItem,
  TocTitle,
} from './faqLegalBlock.styles';

export interface FaqLegalBlockProps {
  title: string;
  blocks: Block[];
}

export const FaqLegalBlock = ({ title, blocks }: FaqLegalBlockProps) => {
  const renderTableOfContent = (faqBlock: Block, index: number) => (
    <Link to={`${FAQ_BLOCK_COPY}${index}`} smooth duration={500} offset={-60} key={index}>
      <TocItem>
        <ArrowDown />
        <TocTitle>
          {index + 1}. {faqBlock.value.header}
        </TocTitle>
      </TocItem>
    </Link>
  );

  const renderFaqBlocks = (faqBlock: Block, index: number) => (
    <div key={`${FAQ_BLOCK_COPY}${index}`}>
      <Element name={`${FAQ_BLOCK_COPY}${index}`}>
        <FaqBlockTitle>
          {index + 1}. {faqBlock.value.header}
        </FaqBlockTitle>
      </Element>
      <div>
        {faqBlock.value.contentStreamBlock.map((faqContentBlock: Block, contentBlockIndex: number) => (
          <div key={`${FAQ_CONTENT_BLOCK_COPY}${contentBlockIndex}`}>
            <FaqContentBlockTitle>
              {index + 1}.{contentBlockIndex + 1}. {faqContentBlock.value.subHeader}
            </FaqContentBlockTitle>
            <FaqContentBlockBody dangerouslySetInnerHTML={{ __html: faqContentBlock.value.body }} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Container data-testid={BLOCK_TYPE.FAQ_LEGAL_BLOCK}>
      <Title>{title}</Title>
      {blocks.map(renderTableOfContent)}
      {blocks.map(renderFaqBlocks)}
    </Container>
  );
};
