import styled, { FlattenSimpleInterpolation } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

interface StyledOptimisedImageProps {
  readonly customHeight?: string;
  readonly customWidth?: string;
  readonly customStyles?: FlattenSimpleInterpolation;
  readonly defaultDimensions?: string;
  isFavorite?: boolean;
}

export const StyledOptimisedImage = styled.img<StyledOptimisedImageProps>`
  object-fit: contain;
  width: ${({ customWidth, defaultDimensions }) => {
    return customWidth ? customWidth : defaultDimensions;
  }};
  height: ${({ customHeight, defaultDimensions }) => {
    return customHeight ? customHeight : defaultDimensions;
  }};

  transition: opacity 400ms ease-in-out;

  ${({ customStyles }) => customStyles}
`;
