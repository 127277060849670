import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  padding-top: 20px;
  margin: -${OFFSET.M} -${OFFSET.S} 0;
  overflow: hidden;

  ${media.tablet} {
    margin: -${OFFSET.M} 0 0;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: ${OFFSET.S};

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.tablet} {
    margin-left: 0;
  }
`;

export const ArtworkContainer = styled.a`
  padding: 12px 0 0;
  margin: 0 ${OFFSET.XS};
  display: block;
  flex-direction: column;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const ImageContainer = styled.div`
  height: auto;
  width: 100%;
  margin: 0 auto;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 90px;
  height: 90px;
  margin: 0 auto;
  display: block;
`;

export const Caption = styled.div`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  padding-top: 7px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const favoriteButtonCustomStyles = css`
  margin: 0;
  padding: 0;

  svg {
    width: 8px;
  }
`;

export const Price = styled.span`
  padding: 2px 0 0 6px;
`;

export const ArrowRight = styled.span`
  padding-left: ${OFFSET.XS};
  &:after {
    content: '\\2192';
    font-size: ${FONT_SIZE.XS};
    display: inline-block;
    transition: transform 0.2s ease-in-out;
  }
`;

export const ViewAll = styled.a`
  padding: 12px ${OFFSET.XS} 0 0;
  display: block;
  min-width: 100px;
  margin: 0;
  text-align: center;

  &:hover ${ArrowRight}:after {
    transform: translate3d(6px, 0, 0);
  }
`;

export const ViewAllCopy = styled.span`
  display: inline-block;
  margin-top: 40px;
  position: relative;
`;
