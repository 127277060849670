import styled from 'styled-components';

import { OFFSET } from '@theme/helpers';

import { containerStyles, paragraphTextStyles } from '../blocks.styles';

export const Container = styled.div`
  ${containerStyles};
  ${paragraphTextStyles};
  text-align: center;
  white-space: pre-wrap;
  padding-top: ${OFFSET.M};
`;
