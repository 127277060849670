import qs from 'querystring';

import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { useEffectOnce } from 'react-use';
import { always, isEmpty, map, omit, pathOr, prop } from 'ramda';

import { SHOPPING_CART_TEST_ID } from '@components/shoppingCart/shoppingCart.constants';
import { useGetCheckoutUrl, useGetShoppingCart, useRemoveShoppingCartItem } from '@hooks/useShoppingCart';
import { renderWhenTrue } from '@utils/rendering';
import { ShoppingCartPhysicalItem, ShoppingCartGiftCertificate } from '@components/shoppingCartItem';
import { formatCartPrice } from '@utils/helpers';
import reportError from '@utils/reportError';
import { useTrackConversions } from '@hooks/useTrackConversions';
import { CONVERSIONS_CONTENT_TYPE, CONVERSIONS_EVENTS } from '@definitions/conversions.types';

import VisaLogo from '../../assets/images/visa-logo.svg';
import MastercardLogo from '../../assets/images/mastercard-logo.svg';
import JcbLogo from '../../assets/images/jcb-logo.svg';
import PaypalLogo from '../../assets/images/paypal-logo.svg';
import KlarnaLogo from '../../assets/images/klarna_black.svg';

import {
  Container,
  Title,
  ProductsContainer,
  TotalContainer,
  TotalNote,
  TotalPriceContainer,
  TotalPrice,
  TotalCaption,
  CheckoutButtonWrapper,
  CheckoutButton,
  CheckoutBackground,
  CheckoutCopy,
  PaymentProviders,
  Message,
  MobileTopLine,
  Errors,
  Error,
} from './shoppingCart.styles';

export const ShoppingCart = () => {
  const router = useRouter();
  const [errors, setErrors] = useState<string[]>([]);
  const messageRef = useRef<HTMLDivElement>(null);
  const { removeCartItem, cart } = useRemoveShoppingCartItem();
  const { lineItemsLength, isRecoveredCart } = useGetShoppingCart();
  const { getCheckoutUrl } = useGetCheckoutUrl();
  const isEmptyCart = !lineItemsLength;
  const loading = !isEmptyCart && isEmptyCart;
  const totalPrice = formatCartPrice(cart.baseAmount);
  const { sendConversion } = useTrackConversions();
  const shoppingCartRef = useRef<HTMLDivElement>(null);

  const handleCheckout = async () => {
    try {
      const url = await getCheckoutUrl();
      const { physicalItems } = cart.lineItems;
      const ids = map(prop('productId'), physicalItems);
      await sendConversion({
        eventType: CONVERSIONS_EVENTS.INITIATE_CHECKOUT,
        contentType: CONVERSIONS_CONTENT_TYPE.PRODUCT_GROUP,
        contentIds: ids,
        numItems: physicalItems.length,
        value: cart.baseAmount,
        currency: cart.currency.code,
      });
      await router.push(url);
    } catch (e) {
      reportError(e);
    }
  };

  useEffect(() => {
    if (shoppingCartRef.current?.scrollTo) {
      shoppingCartRef?.current?.scrollTo(0, 0);
    }
  }, []);

  useEffectOnce(() => {
    if (messageRef.current) {
      messageRef.current.focus();
    }
  });

  useEffectOnce(() => {
    const statusMessage = pathOr([], ['query', 'statusMessage'], router);
    if (isEmpty(statusMessage)) {
      return;
    }
    const statusErrors = typeof statusMessage === 'string' ? [statusMessage] : statusMessage;
    setErrors(statusErrors);
    const { query, pathname } = router;
    const newQuery = omit(['statusMessage'], query);
    const queryString = isEmpty(newQuery) ? pathname : `${pathname}?${qs.stringify(newQuery)}`;

    history.replaceState(history.state, '', queryString);
  });

  const renderLoader = renderWhenTrue(() => (
    <Message>
      <FormattedMessage id="shoppingCart.loading" defaultMessage="Loading..." />
    </Message>
  ));

  const renderNoProductsMessage = renderWhenTrue(() => (
    <Message tabIndex={0} ref={messageRef}>
      <FormattedMessage id="shoppingCart.noProducts" defaultMessage="There are no products in the bag." />
    </Message>
  ));

  const renderNoAvailableMessage = renderWhenTrue(() => (
    <Message tabIndex={0} ref={messageRef}>
      <FormattedMessage id="shoppingCart.noAvailable" defaultMessage="The artworks are no longer available." />
    </Message>
  ));

  const renderErrors = renderWhenTrue(
    always(
      <Errors>
        {errors.map((message, index) => (
          <Error key={index}>{message}</Error>
        ))}
      </Errors>
    )
  );

  return (
    <Container data-testid={SHOPPING_CART_TEST_ID} isEmptyCart={isEmptyCart} ref={shoppingCartRef}>
      <Title>
        <FormattedMessage id="shoppingCart.title" defaultMessage="Your shopping bag:" />
      </Title>
      <MobileTopLine />
      {renderErrors(!!errors.length)}

      <ProductsContainer>
        {cart.lineItems.physicalItems.map((physicalItem, index) => (
          <ShoppingCartPhysicalItem key={index} physicalItem={physicalItem} removeItem={removeCartItem} index={index} />
        ))}
        {cart.lineItems.giftCertificates.map((giftCertificate, index) => (
          <ShoppingCartGiftCertificate
            key={index}
            giftCertificate={giftCertificate}
            removeItem={removeCartItem}
            index={index}
          />
        ))}

        {renderLoader(loading)}
        {renderNoProductsMessage(isEmptyCart && !isRecoveredCart)}
        {renderNoAvailableMessage(isEmptyCart && isRecoveredCart)}
      </ProductsContainer>

      <TotalContainer>
        <TotalNote>
          <FormattedMessage
            id="shoppingCart.totalNote"
            defaultMessage="There's no guarantee that we can hold this work for you until you complete checkout."
          />
        </TotalNote>
        <TotalPriceContainer>
          <TotalCaption>
            <FormattedMessage id="shoppingCart.subtotalCaption" defaultMessage="Subtotal" />
          </TotalCaption>
          <TotalPrice key={totalPrice}>{totalPrice}</TotalPrice>
        </TotalPriceContainer>
      </TotalContainer>

      <CheckoutButtonWrapper>
        <CheckoutButton disabled={isEmptyCart} onClick={handleCheckout} autoFocus={!lineItemsLength}>
          <CheckoutBackground />
          <CheckoutCopy>
            <FormattedMessage id="shoppingCart.checkoutButton" defaultMessage="Checkout" />
          </CheckoutCopy>
        </CheckoutButton>
      </CheckoutButtonWrapper>

      <PaymentProviders>
        <VisaLogo />
        <MastercardLogo />
        <JcbLogo />
        <PaypalLogo />
        <KlarnaLogo />
      </PaymentProviders>
    </Container>
  );
};
