import styled from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const InputWrapper = styled.div`
  display: flex;
  margin: 0 0 ${OFFSET.S};
  position: relative;
  height: ${OFFSET.M};
`;

export const Input = styled.input`
appearance: none;
margin: 0;
width: 20px;
height: 20px;
border-radius: 50%;
transition: all 0.1s ease-in-out;
margin: 0 ${OFFSET.XS} 0 0;
background-color: transparent;
border: 1px solid currentColor;
box-shadow: none;

&::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 4px;
  top : 5px;
}
&:checked::after {
  background-color: ${COLORS.BLACK};
}

  ${media.mobile} {
    width: 20px;
    height: ${OFFSET.XS};
    border-radius: 5px;

    &:after {
      width: 4px;
      height: 4px;
    }
`;

export const Caption = styled.div`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  order: 2;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${OFFSET.S} ${OFFSET.XS} 14px;

  ${media.tablet} {
    padding: ${OFFSET.XS} 0 7px;
  }

  ${Caption} a {
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }
`;

export const FlexStyle = styled.div`
  flex: column;
  width: 100%;
`;

export const ErrorTextStyle = styled.div`
  color: ${COLORS.RED};
  fontsize: ${FONT_SIZE.XS};
`;
