export enum CONVERSIONS_EVENTS {
  SEARCH = 'Search',
  ADD_TO_CART = 'AddToCart',
  ADD_TO_WAITLIST = 'AddToWishlist',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  LEAD = 'Lead',
  PAGE_VIEW = 'PageView',
  VIEW_CONTENT = 'ViewContent',
}

export enum VIEW_CONTENT_TYPES {
  PDP = 'PDP',
  ARTIST_PLP = 'artist PLP',
  GALLERY_PLP = 'gallery PLP',
  CHARACTERISTIC_PLP = 'characteristic PLP',
}

export enum CONVERSIONS_CONTENT_TYPE {
  PRODUCT = 'product',
  PRODUCT_GROUP = 'product_group',
}

export enum CONVERSIONS_STATUS {
  NEWSLETTER = 'Registered to newsletter',
  ACCOUNT_CREATED = 'Account created',
}

export interface ConversionsContent {
  title: string;
  id: string | number;
  quantity: number;
  price: string | number;
}

export interface ConversionsData {
  value?: number;
  numItems?: number;
  stringSearch?: string;
  contentType?: CONVERSIONS_CONTENT_TYPE;
  contentName?: string;
  currency?: string;
  contents?: ConversionsContent[];
  contentIds?: string[] | number[];
  status?: CONVERSIONS_STATUS;
}

export interface EventPayload extends ConversionsData {
  eventType: CONVERSIONS_EVENTS;
}
