import styled from 'styled-components';

import { OFFSET } from '@theme/helpers';

import { legalPageRichTextStyles } from '../simpleLegalBlock/simpleLegalBlock.styles';
import { legalPageFontStyles } from '../blocks.styles';

export const Container = styled.div`
  ${legalPageFontStyles};
`;

export const OrganizedBlockContainer = styled.div``;

export const OrganizedBlockTitle = styled.div`
  padding: ${OFFSET.S} 15px 15px 0;
`;

export const OrganizedBlockBody = styled.div`
  ${legalPageRichTextStyles};

  ul {
    list-style: circle;

    & > li {
      &:before {
        display: none;
      }
    }
  }
`;
