export const MIN_PASSWORD_LENGTH = 8;
export const PASSWORD_REGEX = new RegExp('^(?=.*?[a-zA-Z])(?=.*?[0-9]).{7,}$');
export const PHONE_REGEX = new RegExp(
  '^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$'
);

export enum BUTTON_SIZE {
  XXS = 'xxs',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}
