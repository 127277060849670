import React, { cloneElement, ReactElement, useCallback, useState } from 'react';
import { CSSProperties } from 'styled-components';

import {
  GIF_MAX_WIDTH,
  GIF_OFFSET,
  MOBILE_OFFSET,
  OFFSET,
} from '@components/optimisedImageWrapper/optimisedImageWrapper.constants';

interface optimisedImageProps {
  children: ReactElement;
  customWidth?: number | null;
  customStyles?: CSSProperties | undefined;
  className?: string;
}

export const changeImageSize = (originalSrc: string, width: number, offset = OFFSET) => {
  const totalWidth = width * offset;
  if (originalSrc.includes('.gif')) {
    return originalSrc.concat('?w=', Math.min(totalWidth, GIF_MAX_WIDTH).toString());
  }
  return width ? originalSrc.concat('?w=', totalWidth.toString()) : originalSrc;
};

export const OptimisedImageWrapper = ({
  customWidth = null,
  customStyles,
  children,
  className,
}: optimisedImageProps) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const originalSrc: string = children?.props?.src;
  const div: (node: Element) => void = useCallback(
    (node: Element) => {
      if (node !== null) {
        setWidth(customWidth ? customWidth : node.getBoundingClientRect().width);
        setHeight(node.getBoundingClientRect().height);
      }
    },
    [customWidth]
  );

  const determineOffset = (): number => {
    const isDesktop = height < width;
    const isGif = originalSrc.includes('.gif');
    if (isDesktop) return OFFSET;
    if (isGif) return GIF_OFFSET;
    return MOBILE_OFFSET;
  };

  const clone = cloneElement(children, {
    src: changeImageSize(originalSrc, width, determineOffset()),
  });

  return (
    <>
      {/*@ts-ignore*/}
      <div className={className} ref={div} style={customStyles}>
        {clone}
      </div>
    </>
  );
};
