import styled from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const InputWrapper = styled.div`
  display: flex;
  margin: 0 0 ${OFFSET.S};
  position: relative;
  height: ${OFFSET.M};
`;

export const Input = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid currentColor;
  box-shadow: none;
  padding: 0;
  width: 24px;
  height: 12px;
  border-radius: 6px;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  margin: 0 ${OFFSET.XS} 0 0;

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: transparent;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: block;
    border: 1px solid currentColor;
    transition: transform 0.2s ease-in-out;
  }

  &:checked {
    background-color: ${COLORS.BLACK};
    box-shadow: none;
    order: 1;

    &:after {
      transform: translate3d(12px, 0, 0);
      background-color: ${COLORS.WILD_SAND};
      border-color: ${COLORS.WILD_SAND};
    }
  }

  ${media.mobile} {
    margin: 4px ${OFFSET.XS} 0 0;
    width: 20px;
    height: ${OFFSET.XS};
    border-radius: 5px;

    &:after {
      width: 4px;
      height: 4px;
    }

    &:checked {
      &:after {
        transform: translate3d(${OFFSET.XS}, 0, 0);
      }
    }
  }
`;

export const Caption = styled.div`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  order: 2;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.WILD_SAND};
  width: 100%;
  padding: ${OFFSET.S} ${OFFSET.XS} 14px;

  ${media.tablet} {
    padding: ${OFFSET.XS} ${OFFSET.S} 7px;
  }

  ${Caption} a {
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }
`;
