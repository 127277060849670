import React from 'react';
import NextLink from 'next/link';
import { always, equals } from 'ramda';

import { Link as LinkType } from '@definitions/introText.types';
import { renderWhenTrue } from '@utils/rendering';
import { ImageSet } from '@definitions/common.types';
import { IntroText } from '@components/introText';
import { useImage } from '@hooks/useImage';
import { LinkSetType } from '@definitions/common.types';
import {
  ASSET_PLACEMENT,
  BLOCK_TYPE,
  IMAGE_WITH_TEXT_IMAGE_OPTION,
  INTRO_TEXT_FONT_COLOR,
  INTRO_TEXT_FONT_SIZE,
  INTRO_TEXT_POSITION,
} from '@components/blocks/blocks.constants';
import { useOptimisedImageSize } from '@hooks/useOptimisedImageSize';

import {
  CenteredHeading,
  Container,
  StyledVideoBlock,
  Image,
  ImageContainer,
  Inner,
  IntroTextContainer,
  Title,
} from './imageWithText.styles';

export interface ImageWithTextProps {
  image?: {
    image: ImageSet;
    mobileImage: ImageSet;
  };
  mobileImage: ImageSet;
  title: string;
  subtitle: string;
  description: string;
  link: LinkType;
  displayImageOption: IMAGE_WITH_TEXT_IMAGE_OPTION;
  fontSize: INTRO_TEXT_FONT_SIZE;
  fontColor: INTRO_TEXT_FONT_COLOR;
  textColor?: string;
  videoUrl?: string;
}

export const ImageWithText = ({
  image,
  mobileImage: oldMobileImage,
  title,
  subtitle,
  description,
  link,
  displayImageOption,
  fontSize,
  fontColor,
  textColor,
  videoUrl,
}: ImageWithTextProps) => {
  const isIntroTextWhite =
    equals(fontColor, INTRO_TEXT_FONT_COLOR.WHITE) &&
    equals(displayImageOption, IMAGE_WITH_TEXT_IMAGE_OPTION.FULL_BLEED);
  const hasCenteredTitle = equals(displayImageOption, IMAGE_WITH_TEXT_IMAGE_OPTION.CENTERED_WITH_TITLE);
  const renderCentredTitle = renderWhenTrue(
    always(
      <CenteredHeading>
        <Title>{title}</Title>
        <Title>{subtitle}</Title>
      </CenteredHeading>
    )
  );

  const renderImage = (image?: { image: ImageSet; mobileImage: ImageSet }) => {
    if (!image) return null;
    const { image: desktopImage, mobileImage } = image;
    const imageProps = useImage({ desktopImage, mobileImage: mobileImage || oldMobileImage });
    const { optimisedURL, ref } = useOptimisedImageSize({ src: imageProps.src });

    return (
      <ImageContainer imageOption={displayImageOption} ref={ref}>
        <a
          href={String(link.type === LinkSetType.INTERNAL ? `/${link.slug}` : link.urlPath)}
          onClick={() => {
            window.history.pushState(
              {},
              '',
              String(link.type === LinkSetType.INTERNAL ? `/${link.slug}` : link.urlPath)
            );
          }}
        >
          <Image src={optimisedURL} alt={imageProps.alt} imageOption={displayImageOption} />
        </a>
      </ImageContainer>
    );
  };

  const renderVideo = (videoUrl?: string) => {
    if (!videoUrl) return null;

    const determinePlacement = (): ASSET_PLACEMENT => {
      return displayImageOption === IMAGE_WITH_TEXT_IMAGE_OPTION.FULL_BLEED
        ? ASSET_PLACEMENT.WIDE
        : ASSET_PLACEMENT.NORMAL;
    };

    return <StyledVideoBlock placement={determinePlacement()} autoplay={true} videoUrl={videoUrl} />;
  };

  return (
    <Container data-testid={BLOCK_TYPE.IMAGE_WITH_TEXT}>
      <Inner>
        {renderImage(image)}
        {renderVideo(videoUrl)}
        <IntroTextContainer imageOption={displayImageOption}>
          {renderCentredTitle(hasCenteredTitle)}
          <IntroText
            title={title}
            subtitle={subtitle}
            description={description}
            link={link}
            displayTextOption={fontSize}
            headingPlacement={INTRO_TEXT_POSITION.LEFT}
            isWhite={isIntroTextWhite}
            displayImageOption={displayImageOption}
            textColor={textColor}
          />
        </IntroTextContainer>
      </Inner>
    </Container>
  );
};
