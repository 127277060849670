import styled from 'styled-components';

import { CONTAINER_PADDING } from '@components/blocks/blocks.constants';
import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';

import { containerStyles, paragraphTextStyles } from '../blocks.styles';

export const Container = styled.div`
  ${containerStyles};
`;

export const BlockQuote = styled.blockquote`
  ${paragraphTextStyles};

  margin: ${OFFSET.M} 0 ${OFFSET.M} ${CONTAINER_PADDING * 1.5}px;

  ${media.tablet} {
    margin-right: ${CONTAINER_PADDING * 1.5}px;
  }
`;
