import { useDispatch, useSelector } from 'react-redux';

import { MEASURES, uiActions, uiSelectors } from '@modules/ui';
import { SUBLEVEL } from '@components/header/header.constants';

export const useHeaderSublevel = () => {
  const dispatch = useDispatch();

  const sublevel = useSelector(uiSelectors.selectHeaderSublevelType);
  const sublevelOpen = useSelector(uiSelectors.selectHeaderSublevelOpen);
  const preventRedirectAfterLogin = useSelector(uiSelectors.selectPreventRedirectAfterLogin);
  const openHeaderSublevel = (data: { sublevel: SUBLEVEL; preventRedirectAfterLogin?: boolean }) =>
    dispatch(uiActions.openHeaderSublevel(data));
  const closeHeaderSublevel = () => dispatch(uiActions.closeHeaderSublevel());

  return { sublevel, sublevelOpen, preventRedirectAfterLogin, openHeaderSublevel, closeHeaderSublevel };
};

export const useMeasure = () => {
  const dispatch = useDispatch();

  const toggleMeasure = () => dispatch(uiActions.toggleMeasure());
  const measure = useSelector(uiSelectors.selectMeasure);

  return {
    toggleMeasure,
    isCentimeters: measure === MEASURES.CM,
    isInches: measure === MEASURES.INCH,
    measure,
  };
};

export const useHeaderMobileMenu = () => {
  const dispatch = useDispatch();

  const isHeaderMobileMenuOpen = useSelector(uiSelectors.selectHeaderMobileMenuOpen);
  const setHeaderMobileMenuOpen = (isHeaderMobileMenuOpen: boolean) =>
    dispatch(uiActions.setHeaderMobileMenuOpen(isHeaderMobileMenuOpen));

  return { isHeaderMobileMenuOpen, setHeaderMobileMenuOpen };
};
