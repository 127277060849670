import styled, { css } from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { typography } from '@theme/font';
import { Button } from '@components/form';
import { OFFSET } from '@theme/helpers';

export const Text = styled.p`
  ${typography({})};
  margin: 0;
`;

export const Info = styled(Text)`
  text-align: center;
  max-width: 380px;
  margin: ${OFFSET.M} auto;
`;

export const Form = styled.form`
  width: 100%;
`;

const commonInputStyles = css`
  height: ${OFFSET.M};
  border-radius: 0;
  border: 0;
  background: ${COLORS.BLACK10};
  ${typography({})};
  padding: 0 ${OFFSET.S};
  color: black;
  display: block;
  width: 100%;

  &::-webkit-input-placeholder {
    color: ${COLORS.BLACK};
  }
`;

export const Email = styled.input.attrs({
  type: 'email',
  placeholder: 'Email address',
  required: true,
})`
  ${commonInputStyles};

  &::after {
    content: 'Required';
    display: flex;
    position: absolute;
    top: 50%;
    right: ${OFFSET.S};
    width: 100px;
    height: 100%;
    ${typography({})};
    color: ${COLORS.BLACK20};
    justify-content: right;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const FieldWrapper = styled.div<{ isRequired?: boolean }>`
  position: relative;
  display: block;
  width: 100%;
  height: ${OFFSET.M};
  margin-bottom: ${OFFSET.XS};

  &::after {
    content: '${({ isRequired }) => (isRequired ? 'Required' : 'Optional')}';
    display: flex;
    position: absolute;
    top: 50%;
    right: ${OFFSET.S};
    width: 100px;
    height: 100%;
    ${typography({})};
    color: ${COLORS.BLACK20};
    justify-content: right;
    align-items: center;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  gap: 0;
  flex-direction: column;

  ${media.tablet} {
    gap: ${OFFSET.S};
    flex-direction: row;
  }
`;

export const FirstName = styled.input.attrs({
  type: 'text',
  placeholder: 'First name',
})`
  ${commonInputStyles};
`;

export const LastName = styled.input.attrs({
  type: 'text',
  placeholder: 'Last name',
})`
  ${commonInputStyles};
`;

export const SubmitButton = styled(Button).attrs({
  type: 'submit',
})`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      filter: contrast(0.3);
    `}
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0 0 ${OFFSET.M};
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  appearance: none;
  background-color: transparent;
  border: 1px solid ${COLORS.BLACK};
  box-shadow: none;
  padding: 0;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  margin: 0 ${OFFSET.XS} 0 0;

  ${media.tablet} {
    margin: 0 ${OFFSET.S} 0 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    background-color: transparent;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    background-color: ${COLORS.BLACK};
    opacity: 0;
    transform: scale(0);
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }

  &:checked {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
