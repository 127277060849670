import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

type TransitionProps = {
  state: string;
};

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${COLORS.BLACK20};
`;

export const ModalContainer = styled.div<TransitionProps>`
  position: fixed;
  inset: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  z-index: 999;
  padding: 20px 10px;
  height: 100vh;

  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};

  ${media.tablet} {
    display: flex;
    display: ${({ state }) => (state === 'exited' ? 'none' : 'flex')};
    padding: 40px 20px 20px;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  display: flex;
  align-items: center;
  justify-content: center;
  ${typography({ size: FONT_SIZE.M })}
  box-shadow: 0px 10px 40px ${COLORS.BLACK15};
  position: relative;
  padding: ${OFFSET.M} 12px 12px;
  flex-direction: column;

  ${media.tablet} {
    width: 540px;
  }
`;

export const CloseButton = styled.button`
  ${typography({ size: FONT_SIZE.XS })};
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  height: 34px;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  padding: 0;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background-color: ${COLORS.WHITE};
    z-index: -1;
  }

  &:after {
    content: '\\2715';
  }

  &:hover {
    &:before {
      background-color: ${COLORS.WILD_SAND};
    }
  }
`;

export const ModalHeader = styled.header`
  ${typography({})};
  display: flex;
  height: 34px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 12px;
  width: calc(100% - 24px);
  right: 12px;
  border-bottom: 1px solid ${COLORS.BLACK};
`;
