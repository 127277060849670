import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

const inputStyles = css`
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: ${OFFSET.XS};
  ${typography({})};

  ${media.mobile} {
    ${typography({ spacing: LETTER_SPACING.M })};
  }

  &::placeholder {
    ${media.mobile} {
      opacity: 0;
    }
  }
`;
export const Input = styled.input`
  ${inputStyles};
`;

export const Textarea = styled.textarea`
  ${inputStyles};
  min-height: ${OFFSET.XL};
  resize: none;
`;

export const TextAreaOptional = styled.div`
  color: ${COLORS.BLACK30};
`;

export const Label = styled.label`
  ${typography({})};
  min-width: 136px;
  padding: ${OFFSET.XS};
  display: none;
  white-space: nowrap;

  ${media.mobile} {
    display: block;
  }
`;

export const InputWrapper = styled.div`
  background-color: ${COLORS.WILD_SAND};
  position: relative;
  height: ${OFFSET.M};

  margin-bottom: ${OFFSET.S};
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:focus-within {
    background-color: ${COLORS.MERCURY};
  }
`;
