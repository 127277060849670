import styled, { css } from 'styled-components';
import { ifElse, equals, always, propEq } from 'ramda';

import { media } from '@theme/media';
import { defaultButtonStyles } from '@components/form';
import { CONTAINER_PADDING, NARROW_CONTAINER_WIDTH } from '@components/blocks/blocks.constants';
import { typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

export const ArtworksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 40px;
  padding-left: ${OFFSET.S};

  > div:first-child {
    padding-left: 0;
  }

  ${media.tablet} {
    padding: ${OFFSET.L} ${OFFSET.M} ${OFFSET.S};
    margin-top: ${({ isLeftPlacement }: { isLeftPlacement: boolean }) => (isLeftPlacement ? 80 : 0)}px;
  }
`;

export const ButtonContainer = styled.div`
  ${typography({})};
  display: flex;
  min-height: 100%;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  flex-basis: calc(100vw - 40px);
  flex-shrink: 0;
  max-width: 500px;
  margin-bottom: calc(${CONTAINER_PADDING}px * 2);
  width: calc(100vw - 40px);

  ${media.tablet} {
    min-width: 400px;
    flex-basis: calc(50vw - ${NARROW_CONTAINER_WIDTH}px / 2 - ${CONTAINER_PADDING}px * 1.5);
  }
`;

export const BrowseButton = styled.a`
  ${defaultButtonStyles};
  width: auto;
  margin: 0 auto;
`;

const leftContainerStyles = css`
  flex-basis: 90vw;
  max-width: 500px;

  ${media.tablet} {
    width: 33vw;
    max-width: 500px;
    min-width: 400px;
  }
`;

const bigImageStyles = css`
  height: 85vw;
  max-height: 420px;
  max-width: 420px;

  ${media.mobile} {
    min-height: 360px;
    min-width: 360px;
  }

  ${media.tablet} {
    height: calc(33.333vw - 40px - 15px);
    max-width: 100%;
  }
`;

const smallImageStyles = css`
  height: 250px;
  max-width: 500px;

  ${media.tablet} {
    height: 350px;
  }
`;

export const getImageStyles = ifElse(equals(true), always(bigImageStyles), always(smallImageStyles));
export const getGiftCardStyles = (isLeftPlacement: boolean) => css`
  ${isLeftPlacement ? bigImageStyles : smallImageStyles};
  width: 250px;

  ${media.tablet} {
    width: 300px;
  }

  ${media.desktop} {
    width: 500px;
  }
`;

export const getArtworkContainerStyles: ({ isLeftPlacement }: { isLeftPlacement: boolean }) => string = ifElse(
  propEq('isLeftPlacement', true),
  always(leftContainerStyles),
  always('')
);

export const ArtworkContainer = styled.div`
  padding: 0 10px;

  ${media.tablet} {
    padding: 0 30px;
  }

  ${getArtworkContainerStyles};
`;

export const TopIntroTextContainer = styled.div`
  padding-top: ${CONTAINER_PADDING * 2}px;

  ${media.tablet} {
    padding-top: 51px;
  }
`;

export const LeftIntroTextContainer = styled.div`
  margin-top: -70px;
  width: calc(50vw - ${NARROW_CONTAINER_WIDTH}px / 2 - ${CONTAINER_PADDING}px * 2);
  max-width: 380px;
  min-width: 260px;

  div {
    margin-left: 0;
  }
`;
