import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { OFFSET } from '@theme/helpers';
import { FONT_SIZE, typography } from '@theme/font';

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CountDownContainer = styled.div`
  position: relative;
  display: flex;
  gap: ${OFFSET.XS};
  color: ${COLORS.SILVER_CHALICE};
  ${typography({ size: FONT_SIZE.XS })};
`;

export const Segment = styled.div`
  max-width: 45px;
  ${typography({ size: FONT_SIZE.XS })};
`;

export const Label = styled.div`
  ${typography({ size: FONT_SIZE.XS })};
`;
