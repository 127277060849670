import React from 'react';
import { always } from 'ramda';

import { ImageSet, LinkSet, LinkSetType } from '@definitions/common.types';
import { ASSET_PLACEMENT, BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { useImage } from '@hooks/useImage';
import { useLink } from '@hooks/useLink';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';

import { Container, Figure, Image, Caption, PlainImage } from './imageBlock.styles';

export interface ImageBlockProps {
  image: ImageSet;
  mobileImage: ImageSet;
  caption?: string;
  placement?: ASSET_PLACEMENT;
  isPlainImage?: boolean;
  link?: LinkSet;
}

export const ImageBlock = ({
  image: desktopImage,
  mobileImage,
  caption,
  placement = ASSET_PLACEMENT.NORMAL,
  isPlainImage = false,
  link,
}: ImageBlockProps) => {
  const { target, href } = useLink({ link });
  const image = useImage({ desktopImage, mobileImage });

  const renderCaption = renderWhenTrue(always(<Caption dangerouslySetInnerHTML={{ __html: caption || '' }} />));

  const renderLink = renderWhenTrueOtherwise(
    always(
      <a
        href={`${href || ''}`}
        onClick={() => {
          window.history.pushState({}, '', href);
        }}
      >
        <OptimisedImageWrapper>
          <Image src={image.src} alt={image?.alt} />
        </OptimisedImageWrapper>
      </a>
    ),
    always(
      <OptimisedImageWrapper>
        <Image src={image.src} alt={image?.alt} />
      </OptimisedImageWrapper>
    )
  );

  const renderBlock = renderWhenTrueOtherwise(
    always(
      <OptimisedImageWrapper>
        <PlainImage src={image.src} alt={image?.alt} />
      </OptimisedImageWrapper>
    ),
    always(
      <Container data-testid={BLOCK_TYPE.IMAGE_BLOCK}>
        <Figure placement={placement}>
          {renderLink(!!href)}
          {renderCaption(!!caption)}
        </Figure>
      </Container>
    )
  );

  return renderBlock(isPlainImage);
};
