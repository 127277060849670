import styled from 'styled-components';

import { Link } from '@components/form';

export const Container = styled.div``;

export const Content = styled.main`
  min-height: var(--vh);
`;

export const SkipLink = styled(Link)`
  position: absolute;
  z-index: 2;
  left: -999px;
  width: 1px;
  height: 1px;

  &:focus {
    top: 20px;
    left: 20px;
    width: auto;
    height: auto;
  }
`;
