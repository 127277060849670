import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Element } from 'react-scroll';
import { useSelector } from 'react-redux';
import { always } from 'ramda';
import { parse } from 'query-string';

import { updatePageQuery } from '@utils/helpers';
import { ARTWORKS_LIMIT, ARTWORKS_PAGE_PREFIX } from '@modules/plp/plp.constants';
import { uiSelectors } from '@modules/ui';
import { getCurrentPLP } from '@modules/plp/plp.helpers';
import { renderWhenTrueOtherwise } from '@utils/rendering';
import { useSetCurrentPage } from '@hooks/useArtworks';

import { Container } from './hiddenContainer.styles';

export interface HiddenContainerProps {
  index: number;
}

export const HiddenContainer = ({ index }: HiddenContainerProps) => {
  const [containerRef, inView] = useInView();
  const currentPLP = getCurrentPLP();

  const { setCurrentPage } = useSetCurrentPage(currentPLP);

  const scrollRestored = useSelector(uiSelectors.selectScrollRestored);
  const page = Math.floor(index / ARTWORKS_LIMIT) + 1;

  useEffect(() => {
    const query = parse(location.search.replace('?', ''));
    if (inView && scrollRestored && page !== Number(query.page)) {
      updatePageQuery(index, page, setCurrentPage);
    }
  }, [inView]);

  const renderContainer = renderWhenTrueOtherwise(
    always(
      <Element name={`${ARTWORKS_PAGE_PREFIX}${page}`}>
        <Container ref={containerRef} />
      </Element>
    ),
    always(<Container ref={containerRef} />)
  );

  return renderContainer(!(index % ARTWORKS_LIMIT));
};
