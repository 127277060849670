import styled from 'styled-components';

import { media } from '@theme/media';
import { LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Input = styled.input`
  ${typography({ spacing: LETTER_SPACING.M })};
  background-color: transparent;
  padding: 9px 0 9px ${OFFSET.S};
  width: 100%;
  border: none;
  appearance: none;
  outline: none;

  ${media.tablet} {
    padding: 0 0 1px 6px;
  }
`;
