import React from 'react';

import { Overlay } from './loadingOverlay.styles';

export enum LOADING_OVERLAY_VARIANT {
  WHITE = 'white',
  BLACK = 'black',
}

export interface LoadingOverlayProps {
  variant: LOADING_OVERLAY_VARIANT;
}

export const LoadingOverlay = ({ variant }: LoadingOverlayProps) => <Overlay variant={variant} />;
