import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

import Time from '../../assets/images/icon-time.svg';

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OfferedInContainer = styled.div`
  ${typography({ size: FONT_SIZE.XS })};
  position: relative;
  display: flex;
  gap: 10px;
  color: ${COLORS.SILVER_CHALICE};
`;

export const TimeIcon = styled(Time)`
  display: block;
  margin: 0 8px 0 0;

  ${media.mobile} {
    margin: 0 auto 4px;
  }
`;

export const IconContainer = styled.div`
  padding: ${OFFSET.S} ${OFFSET.XS};
  margin: ${OFFSET.S};
  border-top: 1px solid ${COLORS.SILVER};
  border-bottom: 1px solid ${COLORS.SILVER};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile} {
    margin: ${OFFSET.S} 0;
    flex-direction: column;
  }
`;
