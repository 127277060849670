import styled from 'styled-components';

export const Container = styled.div<{
  width?: string;
  fontSize?: string;
  borderSpacing?: string;
  margin?: number;
  textAlign?: string;
  textBoxAlign?: string;
}>`
  width: ${({ width }) => width || '95%'};
  font-size: ${({ fontSize }) => fontSize};
  border-spacing: ${({ borderSpacing }) => borderSpacing || '0'};
  margin: 25px;
  padding-top: 60px;
  text-align: ${({ textAlign }) => textAlign};

  ${({ textBoxAlign }) => {
    switch (textBoxAlign) {
      case 'left':
        return `
          margin-left: 40px;
          margin-right: auto;
        `;
      case 'right':
        return `
          margin-left: auto;
          margin-right: 40px;
        `;
      case 'center':
        return `
          margin: 0 auto;
        `;
      default:
        return `
          margin: 0 auto;
        `;
    }
  }}

  ol, ul {
    margin: 1;
    padding: 0;
    list-style-position: inside;
  }

  li {
    margin: 1;
    padding: 0;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin: 25px;
    width: 88%;
    flexdirection: row;
    font-size: ${({ fontSize }) => (fontSize === '48px' || fontSize === '72px' ? '32px' : fontSize)};
    padding-top: 0px;
  }
`;

export const Image = styled.img<{
  desktopWidth?: string;
}>`
  object-fit: contain;
  width: ${({ desktopWidth }) => desktopWidth}%;

  @media (max-width: 768px) {
    width: ${({ desktopWidth }) => {
      if (desktopWidth === '25' || desktopWidth === '33') {
        return '33';
      }
      if (desktopWidth === '50') {
        return '45';
      }
      if (desktopWidth === '75' || desktopWidth === '67') {
        return '63';
      }
      return 100;
    }}%;
  }
`;

export const TextImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ImageTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ImageImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
`;

export const TextTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const LeftColumnText = styled.span<{
  width: string;
  fontSize: string;
  textAlign: string;
  verticalAlign: string;
}>`
  width: ${({ width }) => width};
  display: inline-block;
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ textAlign }) => textAlign};
  align-self: ${({ verticalAlign }) => verticalAlign};

  @media (max-width: 768px) {
    font-size: ${({ fontSize }) => (fontSize === '48px' || fontSize === '72px' ? '32px' : fontSize)};
    margin-right: 10px;
    max-width: 100%;
    word-wrap: break-word;
  }
`;

export const RightColumnText = styled.span<{
  width: string;
  fontSize: string;
  textAlign: string;
  verticalAlign: string;
}>`
  width: ${({ width }) => width};
  display: inline-block;
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ textAlign }) => textAlign};
  align-self: ${({ verticalAlign }) => verticalAlign};

  @media (max-width: 768px) {
    font-size: ${({ fontSize }) => (fontSize === '48px' || fontSize === '72px' ? '32px' : fontSize)};
    max-width: 100%;
    word-wrap: break-word;
  }
`;

// ---------------
export const StackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
