import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  emailError: {
    id: 'form.emailError',
    defaultMessage: 'Incorrect email',
  },
  required: {
    id: 'form.required',
    defaultMessage: 'Required',
  },
  matchPassword: {
    id: 'form.matchPassword',
    defaultMessage: 'Password must match',
  },
  shortPassword: {
    id: 'form.shortPassword',
    defaultMessage: 'This password is too short. It must contain at least {number} characters.',
  },
  passwordError: {
    id: 'form.passwordError',
    defaultMessage: 'This passwords must be at least 7 characters and contain both alphabetic and numeric characters',
  },
  email: {
    id: 'form.email',
    defaultMessage: 'Email address:',
  },
  name: {
    id: 'form.name',
    defaultMessage: 'Name:',
  },
  password: {
    id: 'form.password',
    defaultMessage: 'Password:',
  },
  newPassword: {
    id: 'form.newPassword',
    defaultMessage: 'New password:',
  },
  createUserError: {
    id: 'form.createUserError',
    defaultMessage: "The email address you've entered is either invalid or already associated with an account.",
  },
  loginError: {
    id: 'form.loginError',
    defaultMessage: "Your password and email don't match. Please try again or reset your password.",
  },
  confirmPassword: {
    id: 'form.confirmPassword',
    defaultMessage: 'Confirm:',
  },
  currentPassword: {
    id: 'form.currentPassword',
    defaultMessage: 'Current password:',
  },
  generalError: {
    id: 'form.generalError',
    defaultMessage: 'Something went wrong. Please try again later.',
  },
  invalidPassword: {
    id: 'form.invalidPassword',
    defaultMessage: 'Invalid current password.',
  },
  firstName: {
    id: 'form.firstName',
    defaultMessage: 'First name:',
  },
  lastName: {
    id: 'form.lastName',
    defaultMessage: 'Last name:',
  },
  company: {
    id: 'form.company',
    defaultMessage: 'Company:',
  },
  phoneNumber: {
    id: 'form.phoneNumber',
    defaultMessage: 'Phone number:',
  },
  phoneError: {
    id: 'form.phoneError',
    defaultMessage: 'Phone number is not valid',
  },
  subscribeNewsletter: {
    id: 'form.subscribeNewsletter',
    defaultMessage: 'Subscribe to newsletter',
  },
  terms: {
    id: 'form.terms',
    defaultMessage:
      'I have read, understood and accepted the <terms>terms and conditions</terms> and the <privacy>privacy policy</privacy>',
  },
  termsAndNewsletter: {
    id: 'form.subscribeNewsletter',
    defaultMessage:
      'I accept the user <terms>terms</terms> & would like to sign up for Platform emails about new artwork launches',
  },
  termsOnly: {
    id: 'form.terms',
    defaultMessage: 'I accept the user <terms>terms</terms> only',
  },
  termsRequired: {
    id: 'form.termsRequired',
    defaultMessage: 'The terms and conditions and the privacy policy must be accepted',
  },
  optional: {
    id: 'form.optional',
    defaultMessage: 'optional',
  },
  address1: {
    id: 'form.address1',
    defaultMessage: 'Street address:',
  },
  address2: {
    id: 'form.address2',
    defaultMessage: 'Address 2:',
  },
  country: {
    id: 'form.country',
    defaultMessage: 'Country:',
  },
  state: {
    id: 'form.state',
    defaultMessage: 'State:',
  },
  city: {
    id: 'form.city',
    defaultMessage: 'City:',
  },
  postalCode: {
    id: 'form.postalCode',
    defaultMessage: 'ZIP code:',
  },
});
