import styled, { css } from 'styled-components';
import { always, cond, ifElse, propEq } from 'ramda';

import {
  ASSET_PLACEMENT,
  CONTAINER_PADDING,
  IMAGE_WITH_TEXT_IMAGE_OPTION,
  NARROW_CONTAINER_WIDTH,
} from '@components/blocks/blocks.constants';
import { media } from '@theme/media';
import { FONT_SIZE, LINE_HEIGHT, typography } from '@theme/font';
import { masterBlockBottomLine } from '@components/blocks/blocks.styles';

import { VideoBlock } from '../videoBlock';

interface StylesProps {
  imageOption: IMAGE_WITH_TEXT_IMAGE_OPTION;
}

interface VideoStylesProps {
  placement: ASSET_PLACEMENT;
}

export const StyledVideoBlock = styled(VideoBlock)<VideoStylesProps>`
  ${({ placement }) =>
    placement === ASSET_PLACEMENT.WIDE &&
    css`
      margin: 0;

      > div {
        margin: 0;
      }
    `}
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  ${masterBlockBottomLine};

  ${media.tablet} {
    height: ${NARROW_CONTAINER_WIDTH + CONTAINER_PADDING * 4}px;
    overflow: hidden;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const centeredImageContainerStyle = css`
  width: 100%;
  text-align: center;

  ${media.tablet} {
    height: calc(100% - ${CONTAINER_PADDING}px * 4);
    position: absolute;
    left: 0;
    top: calc(${CONTAINER_PADDING}px * 2);
  }
`;

const fullBleedImageContainerStyle = css`
  width: 100%;
  height: 540px;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  ${media.tablet} {
    height: 100%;
    position: absolute;
  }
`;

const getImageContainerStyles: ({ imageOption }: StylesProps) => string = ifElse(
  propEq('imageOption', IMAGE_WITH_TEXT_IMAGE_OPTION.FULL_BLEED),
  always(fullBleedImageContainerStyle),
  always(centeredImageContainerStyle)
);

export const ImageContainer = styled.div`
  ${getImageContainerStyles};
`;

const centeredImageStyle = css`
  width: calc(100% - ${CONTAINER_PADDING * 2}px);
  margin: ${CONTAINER_PADDING}px auto 0;

  ${media.tablet} {
    width: calc(${NARROW_CONTAINER_WIDTH}px + ${CONTAINER_PADDING}px * 6);
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
  }
`;

const fullBleedImageStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const getImageStyles: ({ imageOption }: StylesProps) => string = ifElse(
  propEq('imageOption', IMAGE_WITH_TEXT_IMAGE_OPTION.FULL_BLEED),
  always(fullBleedImageStyle),
  always(centeredImageStyle)
);

export const Image = styled.img`
  ${getImageStyles};
`;

// @ts-ignore
const getIntroTextPosition: ({ imageOption }: StylesProps) => string = cond([
  [propEq('imageOption', IMAGE_WITH_TEXT_IMAGE_OPTION.FULL_BLEED), always('absolute')],
  [propEq('imageOption', IMAGE_WITH_TEXT_IMAGE_OPTION.CENTERED), always('absolute')],
  [propEq('imageOption', IMAGE_WITH_TEXT_IMAGE_OPTION.CENTERED_WITH_TITLE), always('inherit')],
]);

export const IntroTextContainer = styled.div`
  width: 100%;
  margin: ${CONTAINER_PADDING}px 0 ${CONTAINER_PADDING * 2}px;
  position: ${getIntroTextPosition};
  z-index: 1;
  min-width: 260px;

  ${media.tablet} {
    width: calc(50vw - ${NARROW_CONTAINER_WIDTH}px / 2 - ${CONTAINER_PADDING}px * 2);
    margin: ${CONTAINER_PADDING * 2}px 0;
  }
`;

export const CenteredHeading = styled.h2`
  ${typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.S })};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: none;
  pointer-events: none;

  ${media.tablet} {
    ${typography({ size: FONT_SIZE.XXL, height: LINE_HEIGHT.S })};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

export const Title = styled.span`
  display: block;

  ${media.tablet} {
    width: ${NARROW_CONTAINER_WIDTH}px;
  }
`;
