import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { always } from 'ramda';
import { FlattenSimpleInterpolation } from 'styled-components';

import { renderWhenTrueOtherwise } from '@utils/rendering';

import { StyledLazyImage, InViewThreshold, Container } from './lazyImage.styles';

export interface LazyImageProps {
  src: string;
  placeholderSrc: string;
  alt: string;
  threshold?: number;
  customWidth?: string;
  customHeight?: string;
  customStyles?: FlattenSimpleInterpolation;
  defaultDimensions?: string;
}

export const LazyImage = ({
  src,
  placeholderSrc,
  alt,
  threshold = -70,
  customWidth,
  customHeight,
  customStyles,
  defaultDimensions = '100%',
}: LazyImageProps) => {
  const [imageRef, inView] = useInView({ triggerOnce: true });
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (inView) {
      const standardImage = new Image();
      standardImage.src = src;
      standardImage.onload = () => {
        setImageLoaded(true);
      };
    }
  }, [inView]);

  const imageStylesProps = {
    customWidth,
    customHeight,
    customStyles,
    defaultDimensions,
  };

  const renderImage = renderWhenTrueOtherwise(
    always(<StyledLazyImage src={src} alt={alt} {...imageStylesProps} />),
    always(<StyledLazyImage src={placeholderSrc} alt={alt} {...imageStylesProps} />)
  );

  return (
    <Container>
      <InViewThreshold ref={imageRef} threshold={threshold} />
      {renderImage(imageLoaded)}
    </Container>
  );
};
