import React, { ReactNode, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Portal from '@components/portal';

import { CloseButton, ModalContainer, ModalContent, ModalHeader, ModalOverlay } from './modal.styles';

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  handleOnClose: () => void;
  wrapperId: string;
  headerText: string;
};

function Modal({ children, isOpen, handleOnClose, wrapperId, headerText }: ModalProps) {
  const nodeRef = useRef(null);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleOnClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleOnClose]);

  return (
    <Portal wrapperId={wrapperId}>
      <CSSTransition in={isOpen} appear timeout={500} unmountOnExit ref={nodeRef}>
        {(state) => (
          <ModalContainer state={state} ref={nodeRef}>
            <ModalOverlay onClick={handleOnClose} />
            <ModalContent className="modal-content">
              <ModalHeader>
                {headerText} <CloseButton onClick={handleOnClose} />
              </ModalHeader>
              {children}
            </ModalContent>
          </ModalContainer>
        )}
      </CSSTransition>
    </Portal>
  );
}

export default Modal;
