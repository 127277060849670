import styled, { css } from 'styled-components';
import { propEq, ifElse, always } from 'ramda';

import { media } from '@theme/media';
import { CONTAINER_PADDING, CONTAINER_WIDTH, ASSET_PLACEMENT } from '@components/blocks/blocks.constants';
import { paragraphTextStyles } from '@components/blocks/blocks.styles';
import { OFFSET } from '@theme/helpers';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';

interface StylesProps {
  placement: ASSET_PLACEMENT;
}

const normalImageStyle = css`
  ${paragraphTextStyles};
  max-width: ${CONTAINER_WIDTH - 2 * CONTAINER_PADDING}px;
  margin: ${OFFSET.L} auto ${OFFSET.M};
  figcaption {
    margin: ${OFFSET.XS} 0 0;
  }
`;

const wideImageStyle = css`
  ${paragraphTextStyles};
  margin: ${OFFSET.L} -${CONTAINER_PADDING}px ${OFFSET.M};
  figcaption {
    margin: ${OFFSET.XS} ${CONTAINER_PADDING}px 0;
  }
`;

const getFigureStyles: ({ placement }: StylesProps) => string = ifElse(
  propEq('placement', ASSET_PLACEMENT.NORMAL),
  always(normalImageStyle),
  always(wideImageStyle)
);

export const Container = styled.div`
  margin: 0 ${CONTAINER_PADDING}px;

  ${media.tablet} {
    margin: 0 ${CONTAINER_PADDING * 1.5}px;
  }
`;

export const Figure = styled.figure`
  ${getFigureStyles};

  ${media.tablet} {
    margin: ${OFFSET.L} auto ${OFFSET.M};

    figcaption {
      margin: ${OFFSET.XS} 0 0;
    }
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Caption = styled.figcaption`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};

  a {
    border-bottom: 1px solid currentColor;
  }

  p {
    margin: 0;
  }
`;

export const PlainImage = styled.img`
  width: 100%;
  display: block;
`;
