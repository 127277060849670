import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { always, isEmpty } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';

import { useFetchFavoriteArtworks } from '@hooks/useArtworks';
import reportError from '@utils/reportError';
import { SmallArtworkList } from '@components/smallArtworkList';
import { useArtworksRoute } from '@hooks/useArtworks/useArtworks';
import { getCurrentPLP } from '@modules/plp/plp.helpers';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { formMessages } from '@components/form';
import { FILTER_OPTIONS } from '@modules/plp';

import { Container, Content, Information } from './favoriteDropdown.styles';

export interface FavoriteDropdownProps {
  isOpen: boolean;
}

export const FAVOURITE_DROPDOWN_TEST_ID = 'favourite-dropdown-test-id';

export const FavoriteDropdown = ({ isOpen }: FavoriteDropdownProps) => {
  const baseParameters = {
    page: 0,
    filterOptions: { filter: FILTER_OPTIONS.FAVORITES },
    limit: 4,
  };
  const router = useRouter();
  const { isGroupingRoute } = useArtworksRoute();
  const currentPLP = getCurrentPLP();

  const { favoriteArtworks, fetchFavoriteArtworks } = useFetchFavoriteArtworks(currentPLP);

  const groupingSlug = router.query.grouping as string;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const hasArtworks = !isEmpty(favoriteArtworks);

  useAsync(async () => {
    try {
      setError(false);
      setLoading(true);
      const parameters = isGroupingRoute ? { ...baseParameters, slug: groupingSlug } : baseParameters;
      await fetchFavoriteArtworks(parameters);
    } catch (e) {
      reportError(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  });

  const renderInformation = renderWhenTrueOtherwise(
    always(<FormattedMessage {...formMessages.generalError} />),
    always(
      <FormattedMessage
        id="favoriteDropdown.noArtworks"
        defaultMessage="You currently have no artworks in your favorites."
      />
    )
  );

  const renderArtworks = renderWhenTrueOtherwise(
    always(<SmallArtworkList artworks={favoriteArtworks.slice(0, 4) || []} />),
    always(<Information>{renderInformation(error)}</Information>)
  );
  const renderContent = renderWhenTrue(always(renderArtworks(hasArtworks)));

  return (
    <Container isOpen={isOpen} data-testid={FAVOURITE_DROPDOWN_TEST_ID}>
      <Content>{renderContent(!loading)}</Content>
    </Container>
  );
};
