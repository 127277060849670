import { css } from 'styled-components';

export enum FONT_SIZE {
  XS = '12px',
  S = '15px',
  M = '20px',
  ML = '24px',
  L = '32px',
  MLL = '42px',
  XL = '58px',
  XXL = '72px',
}

export enum LINE_HEIGHT {
  S = '1',
  M = '1.2',
  L = '1.4',
}

export enum LETTER_SPACING {
  NORMAL = 'normal',
  S = '0.01em',
  M = '0.02em',
  L = '0.03em',
}

export enum FONT_WEIGHT {
  REGULAR = '400',
  BOOK = '500',
  SEMIBOLD = '600',
  BOLD = '800',
}

export enum FONT_FAMILY {
  PRIMARY = 'MediumLLWeb',
  MONOSPACED = "'Monaco', 'Menlo', 'Courier New', 'Courier', monospaced",
}

export enum FONT_STYLE {
  NORMAL = 'normal',
  ITALIC = 'italic',
}

type TypoProps = {
  size?: FONT_SIZE;
  height?: LINE_HEIGHT;
  spacing?: LETTER_SPACING;
  weight?: FONT_WEIGHT;
  style?: FONT_STYLE;
  family?: FONT_FAMILY;
};

export const typography = ({
  size = FONT_SIZE.S,
  height = LINE_HEIGHT.M,
  spacing = LETTER_SPACING.NORMAL,
  weight = FONT_WEIGHT.BOOK,
  style = FONT_STYLE.NORMAL,
  family = FONT_FAMILY.PRIMARY,
}: TypoProps) => css`
  font-size: ${size};
  font-family: ${family};
  font-weight: ${weight};
  font-style: ${style};
  line-height: ${height};
  letter-spacing: ${spacing};
`;
