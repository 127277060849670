import styled, { css } from 'styled-components';

import { COLORS } from '@theme/colors';
import { breakpoints, media, size } from '@theme/media';
import { OFFSET } from '@theme/helpers';
import { FONT_SIZE, typography } from '@theme/font';

import FacebookSVG from '../../assets/images/icon-facebook.svg';
import InstagramSVG from '../../assets/images/icon-instagram.svg';

export const Container = styled.footer`
  padding: 0 ${OFFSET.S};
  position: relative;
  width: 100%;

  ${media.tablet} {
    padding: 0 ${OFFSET.M};
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  color: ${COLORS.BLACK};
  border-top: 1px solid ${COLORS.ALTO};
  padding: ${OFFSET.M} 0;

  ${media.tablet} {
    padding: ${OFFSET.M} 0 ${OFFSET.S};
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.custom(1260)} {
    flex-direction: row;
  }
`;

export const LinksList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;

  &:first-of-type() {
    bakground: red;
  }

  ${media.custom(640)} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
  }

  ${media.desktop} {
    margin-bottom: 0;
  }
`;

export const LinkItem = styled.li`
  padding: ${OFFSET.XS} 0;
  ${typography({ size: FONT_SIZE.S })};

  ${media.custom(640)} {
    padding: 0;
  }

  ${media.bigDesktop} {
    ${typography({})};
  }

  button {
    padding-left: 0;
  }
`;

export const LinkContent = styled.span`
  padding-bottom: 1px;
  ${typography({ size: FONT_SIZE.S })};
  ${media.bigDesktop} {
    ${typography({})};
  }
`;

export const MainLogo = styled(LinkContent)`
  text-transform: uppercase;
  margin-bottom: ${OFFSET.XS};
  display: inline-block;

  ${media.custom(1260)} {
    margin: 0;
  }
`;

export const linkStyles = css`
  text-decoration: none;
  border: none;
  background: none;

  &:hover {
    ${LinkContent} {
      border-bottom: 1px solid ${COLORS.BLACK};
    }
  }

  ${media.custom(640)} {
    padding: 0 ${OFFSET.S} 0 0;
  }
`;

export const Link = styled.a`
  ${linkStyles};
`;

export const Button = styled.button.attrs({ type: 'button' })`
  ${linkStyles};
`;

export const linkButtonStyles = css`
  text-decoration: none;
  border: none;
  background: none;

  &:hover {
    ${LinkContent} {
      border-bottom: 1px solid ${COLORS.BLACK};
    }
  }

  ${media.custom(640)} {
    padding: 0 0 0 0;
  }
`;

export const LinkButton = styled.button.attrs({ type: 'button' })`
  ${linkButtonStyles};
`;

export const SocialList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`;

export const SocialItem = styled.li`
  width: 24px;
  height: 26px;

  &:not(:first-of-type) {
    margin-left: ${OFFSET.S};
    ${media.custom(640)} {
      margin-left: 0;
    }
  }
`;

export const SocialLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${media.custom(640)} {
    justify-content: center;
  }
`;

const iconStyles = css`
  width: 12px;
  height: 12px;
`;

export const FacebookIcon = styled(FacebookSVG)`
  ${iconStyles};
`;

export const InstagramIcon = styled(InstagramSVG)`
  ${iconStyles};
`;
