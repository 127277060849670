import styled from 'styled-components';

import { media } from '@theme/media';
import { CONTAINER_PADDING, NARROW_CONTAINER_WIDTH } from '@components/blocks/blocks.constants';
import { colonSuffix, masterBlockBottomLine, paragraphTextStyles } from '@components/blocks/blocks.styles';
import { FONT_SIZE, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  ${masterBlockBottomLine};
`;

export const InnerContainer = styled.div`
  margin: calc(${CONTAINER_PADDING}px * 2) ${CONTAINER_PADDING}px;
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const Title = styled.h2`
  padding-right: ${OFFSET.S};
  flex-shrink: 0;

  ${paragraphTextStyles};
  ${colonSuffix};
  text-transform: uppercase;
  margin-top: 0;

  ${media.tablet} {
    width: calc(50vw - ${NARROW_CONTAINER_WIDTH}px / 2 - ${CONTAINER_PADDING}px * 3);
    min-width: 130px;
  }
`;

export const Description = styled.div`
  max-width: calc(${NARROW_CONTAINER_WIDTH}px + ${CONTAINER_PADDING}px * 4);
  ${typography({})};

  ${media.mobile} {
    ${typography({ size: FONT_SIZE.M })};
  }
`;

export const Cta = styled.span`
  div,
  a {
    display: inline-block;
  }
`;
