import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { media } from '@theme/media';
import { StyledLazyImage } from '@components/lazyImage';
import { slickStyles } from '@components/common/slick.styles';
import { StyledOptimisedImage } from '@components/optimisedImage';
import { WaitlistButton } from '@components/waitlistButton';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledWaitlistButton = styled(WaitlistButton)<{ isArtistDesignedCurrentLayout: boolean }>`
  ${({ isArtistDesignedCurrentLayout }) =>
    isArtistDesignedCurrentLayout
      ? css`
          bottom: -20px;
        `
      : css`
          bottom: 55px;
        `};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const Caption = styled.div`
  padding: ${OFFSET.XS} 0;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const ArtworkArtist = styled.div`
  text-transform: uppercase;
`;

export const ArtworkDetail = styled.div`
  word-break: break-word;
`;

export const GrayLabel = styled.div`
  color: ${COLORS.SILVER_CHALICE};
`;

export const FavoriteButtonContainer = styled.div`
  padding-top: 30px;
`;

interface StyledImageProps {
  readonly customHeight?: string;
  readonly customWidth?: string;
  readonly customStyles?: FlattenSimpleInterpolation;
}

export const Placeholder = styled.div<StyledImageProps>`
  width: ${({ customWidth = 'auto' }) => customWidth};
  height: ${({ customHeight = 'auto' }) => customHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FONT_SIZE.M};

  ${({ customStyles }) => customStyles};
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const PlaceHolderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImagePlaceholder = styled.div`
  height: 286px;
  width: 286px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
`;

export const PlaceHolderText = styled.h3`
  ${typography({ height: LINE_HEIGHT.L, spacing: LETTER_SPACING.S })};
  font-size: 40px;
  text-transform: uppercase;
`;

export const HoverImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${StyledOptimisedImage} {
    opacity: 0;
  }
`;

export const Link = styled.a<{ isHoverAble: boolean }>`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  text-align: center;
  text-decoration: none;
  display: block;

  ${media.desktop} {
    &:hover {
      ${FavoriteButtonContainer} {
        opacity: 1;
      }
    }
  }

  &:hover {
    ${StyledOptimisedImage} {
      opacity: ${({ isHoverAble }) => (isHoverAble ? 0 : 1)};
    }

    ${HoverImageWrapper} ${StyledOptimisedImage} {
      opacity: ${({ isHoverAble }) => (isHoverAble ? 1 : 0)};
    }
  }
`;

const additionalSlickStyles = css`
  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
    margin: 0 1px;
  }

  .slick-dots {
    position: static;

    li,
    li button,
    li button::before {
      width: 6px;
      height: 6px;
      padding: 0;
    }

    li button {
      border: 1px solid black;
      border-radius: 100%;
    }

    li.slick-active button {
      background-color: black;
    }
  }
`;

export const SliderWrapper = styled.div`
  ${slickStyles}
  ${additionalSlickStyles}

  ${HoverImageWrapper} {
    position: static;
    opacity: 1;
  }

  ${ImageWrapper} ${StyledLazyImage},
  ${ImageWrapper} ${HoverImageWrapper} ${StyledLazyImage} {
    opacity: 1;
  }

  ${ImageWrapper} ${StyledOptimisedImage},
  ${ImageWrapper} ${HoverImageWrapper} ${StyledOptimisedImage} {
    opacity: 1;
  }
`;
