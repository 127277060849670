import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  margin: 0 ${OFFSET.M} ${OFFSET.XL};
  border-top: 0px solid ${COLORS.ALTO};
  border-bottom: 0px solid ${COLORS.ALTO};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(50vh);
  ${typography({ size: FONT_SIZE.XS })};
  text-align: left;
  text-transform: uppercase;

  ${media.mobile} {
    ${typography({ spacing: LETTER_SPACING.M })};
  }

  ${media.tablet} {
    margin: 0 ${OFFSET.L};
  }
`;
