import styled from 'styled-components';

import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';
import { FONT_SIZE, FONT_WEIGHT, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';
import { CONTAINER_PADDING } from '@components/blocks/blocks.constants';

import { containerStyles } from '../blocks.styles';

export const Container = styled.div`
  ${containerStyles};
`;

export const H1 = styled.h1`
  ${typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.S, weight: FONT_WEIGHT.BOOK })};
  margin: ${OFFSET.L} 0;
  text-transform: uppercase;
  text-align: center;

  ${media.desktop} {
    ${typography({ size: FONT_SIZE.XXL, height: LINE_HEIGHT.S, spacing: LETTER_SPACING.M, weight: FONT_WEIGHT.BOOK })};
    margin: ${CONTAINER_PADDING * 2}px ${CONTAINER_PADDING * 1.5}px;
  }
`;

export const H2 = styled.h2`
  ${typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.S, weight: FONT_WEIGHT.BOOK })};
  text-align: center;
  margin: ${OFFSET.L} 0;

  ${media.desktop} {
    margin: ${CONTAINER_PADDING * 2}px ${CONTAINER_PADDING}px;
    ${typography({ size: FONT_SIZE.XXL, height: LINE_HEIGHT.S, spacing: LETTER_SPACING.S, weight: FONT_WEIGHT.BOOK })};
  }
`;

export const H3 = styled.h3`
  ${typography({ size: FONT_SIZE.M, weight: FONT_WEIGHT.BOOK })};
  margin: ${OFFSET.M} 0 ${OFFSET.S};

  ${media.mobile} {
    ${typography({ size: FONT_SIZE.L, weight: FONT_WEIGHT.BOOK })};
  }
`;

export const H4 = styled.h4`
  ${typography({ size: FONT_SIZE.M, weight: FONT_WEIGHT.BOOK })};
  margin: ${OFFSET.M} 0 ${OFFSET.S};
  text-transform: none;
  text-align: center;
`;

export const H5 = styled.h5`
  ${typography({ size: FONT_SIZE.M, weight: FONT_WEIGHT.BOOK })};
  margin: ${OFFSET.S} 0 ${OFFSET.S};
  text-transform: none;
  margin-left: ${CONTAINER_PADDING * 1.5}px;
`;

export const H6 = styled.h6`
  ${typography({})};
  margin: ${OFFSET.XS} 0;
  text-transform: none;
`;
