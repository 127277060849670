import React from 'react';
import { always, cond, equals } from 'ramda';

import { BLOCK_TYPE, PARAGRAPH_WITH_TITLE_OPTION } from '@components/blocks/blocks.constants';
import { renderWhenTrue } from '@utils/rendering';

import {
  Container,
  Title,
  Paragraph,
  AnswerTitle,
  QuestionTitle,
  QuestionParagraph,
  QuestionContainer,
  AnswerContainer,
  QuestionContent,
  CollapsableParagraph,
} from './paragraphWithTitle.styles';

export interface ParagraphWithTitleProps {
  title: string;
  subtitle?: string;
  paragraph: string;
  displayOption: PARAGRAPH_WITH_TITLE_OPTION;
  isContinuReadingClicked: boolean;
}

export const ParagraphWithTitle = ({
  title,
  subtitle,
  paragraph,
  displayOption,
  isContinuReadingClicked,
}: ParagraphWithTitleProps) => {
  const renderSubtitle = renderWhenTrue(always(<div>{subtitle}</div>));
  const biographyBlock = (
    <Container>
      <Title>
        {title}
        {renderSubtitle(!!subtitle)}
      </Title>
      {!isContinuReadingClicked ? (
        <CollapsableParagraph dangerouslySetInnerHTML={{ __html: paragraph }} />
      ) : (
        <Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} />
      )}
    </Container>
  );

  const questionBlock = (
    <QuestionContainer>
      <div />
      <QuestionContent>
        <QuestionTitle>
          <span>{title}</span>
          {renderSubtitle(!!subtitle)}
        </QuestionTitle>
        <QuestionParagraph dangerouslySetInnerHTML={{ __html: paragraph }} />
      </QuestionContent>
      <div />
    </QuestionContainer>
  );

  const answerBlock = (
    <AnswerContainer>
      <div />
      <div>
        <AnswerTitle>
          <span>{title}</span>
          {renderSubtitle(!!subtitle)}
        </AnswerTitle>
        <QuestionParagraph dangerouslySetInnerHTML={{ __html: paragraph }} />
      </div>
      <div />
    </AnswerContainer>
  );

  const block = cond([
    [equals(PARAGRAPH_WITH_TITLE_OPTION.QUESTION), always(questionBlock)],
    [equals(PARAGRAPH_WITH_TITLE_OPTION.ANSWER), always(answerBlock)],
    [equals(PARAGRAPH_WITH_TITLE_OPTION.BIOGRAPHY), always(biographyBlock)],
    // @ts-ignore
  ])(displayOption);

  return <div data-testid={BLOCK_TYPE.PARAGRAPH_WITH_TITLE}>{block}</div>;
};
