import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';

import { changeImageSize } from '@components/optimisedImageWrapper';
import { MIN_WIDTH } from '@components/optimisedImage/optimisedImage.constants';

import { StyledOptimisedImage } from './optimisedImage.styles';

export const OptimisedImage = ({
  src,
  alt,
  customWidth,
  customHeight,
  customStyles,
  defaultDimensions = '100%',
}: OptimisedImageProps) => {
  const imageStylesProps = {
    customWidth,
    customHeight,
    customStyles,
    defaultDimensions,
  };

  const optimisticWidth = Number(customWidth) > MIN_WIDTH ? Number(customWidth) : MIN_WIDTH;

  return <StyledOptimisedImage src={changeImageSize(src, optimisticWidth)} alt={alt} {...imageStylesProps} />;
};

export interface OptimisedImageProps {
  src: string;
  placeholderSrc: string;
  alt: string;
  threshold?: number;
  customWidth?: string;
  customHeight?: string;
  customStyles?: FlattenSimpleInterpolation;
  defaultDimensions?: string;
  isFavorite?: boolean;
}
