import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { always, length, lt, pipe, map } from 'ramda';
import NextLink from 'next/link';
import { useEffectOnce } from 'react-use';
import dayjs from 'dayjs';

import { renderWhen, renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { MAXIMUM_UNITS_PER_CART, ROUTE } from '@utils/constants';
import { formatCartPrice, getFullArtistName, getMaximumUnitsToPurchase } from '@utils/helpers';
import { FavoriteButton } from '@components/favoriteButton';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useHeaderSublevel } from '@hooks/useUi';
import { useUpdateShoppingCartItem } from '@hooks/useShoppingCart';
import { PhysicalItem, RemoveCartPayload } from '@definitions/shoppingCart.types';

import {
  Container,
  ImageWrapper,
  Image,
  Details,
  QuantityContainer,
  RemoveButton,
  NameContainer,
  Artist,
  ProductTitle,
  PriceContainer,
  Price,
  VariantOption,
  ExpiringDate,
} from './shoppingCartItem.styles';
import { QuantityPicker } from './quantityPicker';

export interface ShoppingCartPhysicalItemProps {
  physicalItem: PhysicalItem;
  removeItem: (data: RemoveCartPayload) => void;
  index: number;
}

export const ShoppingCartPhysicalItem = ({
  physicalItem: {
    productId,
    variantId,
    imageUrl,
    artist,
    name,
    year,
    salePrice,
    slug,
    favorite,
    optionValues,
    id,
    liveEndAt,
    quantity,
    inventoryLevel,
    maximumUnitsPerCart = MAXIMUM_UNITS_PER_CART,
  },
  removeItem,
  index,
}: ShoppingCartPhysicalItemProps) => {
  const { updateCartItem } = useUpdateShoppingCartItem() || {};
  const [isQuantityPickerLoading, setIsQuantityPickerLoading] = useState(false);
  const { isMobile } = useBreakpoint();
  const isBeforeTwoMonths = dayjs(liveEndAt).isBefore(dayjs().utc().add(60, 'day'));
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { closeHeaderSublevel } = useHeaderSublevel();
  const maximumUnitsToPurchase = getMaximumUnitsToPurchase(maximumUnitsPerCart);

  const renderPrice = renderWhenTrue(() => <Price>{!!salePrice && formatCartPrice(salePrice)}</Price>);

  const renderRemoveButton = renderWhenTrue(
    always(
      <RemoveButton onClick={() => removeItem({ itemId: id })}>
        <FormattedMessage id="shoppingCartItem.removeButton" defaultMessage="Remove" />
      </RemoveButton>
    )
  );

  const renderOptions = renderWhen(
    pipe(length, lt(0)),
    map(({ optionDisplayName, label }) => (
      <VariantOption key={label}>
        {optionDisplayName}: {label}
      </VariantOption>
    ))
  );

  const renderExpirationInformation = renderWhenTrue(
    always(
      <ExpiringDate>
        <FormattedMessage
          id="shoppingCartItem.expiringDate"
          defaultMessage="Available until {date}."
          values={{ date: dayjs(liveEndAt).format('h:mm A, MMM D, YYYY') }}
        />
      </ExpiringDate>
    )
  );

  const renderQuantity = renderWhenTrueOtherwise(
    () => '1 ×',
    () => (
      <QuantityPicker
        maximumUnitsPerCart={maximumUnitsToPurchase}
        quantity={quantity}
        inventoryLevel={inventoryLevel}
        onIncrease={handleIncrease}
        onDecrease={handleDecrease}
        isLoading={isQuantityPickerLoading}
      />
    )
  );

  const mainUrl = `${ROUTE.ARTWORKS}/${artist.slug}/${slug}`;

  const cartItemLink = optionValues?.length ? `${mainUrl}?variantId=${variantId}` : mainUrl;

  useEffectOnce(() => {
    if (!index) {
      linkRef.current?.focus();
    }
  });

  const updateCart = async (currentQuantity: number) => {
    setIsQuantityPickerLoading(true);
    await updateCartItem({ lineItem: { productId, variantId, quantity: currentQuantity } });
    setIsQuantityPickerLoading(false);
  };

  const handleIncrease = async () => {
    await updateCart(quantity + 1);
  };

  const handleDecrease = async () => {
    await updateCart(quantity - 1);
  };

  return (
    <Container key={productId} data-testid={name}>
      <Details>
        <QuantityContainer>
          {renderQuantity(maximumUnitsToPurchase === 1)}
          {renderRemoveButton(!isMobile)}
        </QuantityContainer>
        <NameContainer>
          <Artist>{getFullArtistName(artist)}</Artist>
          <a
            onClick={() => {
              window.history.pushState({}, '', cartItemLink);
            }}
            href={cartItemLink}
          >
            <ProductTitle>
              <i>{name}</i>, {year}
            </ProductTitle>
          </a>

          {renderExpirationInformation(isBeforeTwoMonths)}
          {renderOptions(optionValues)}
          {renderRemoveButton(isMobile)}
        </NameContainer>
        <PriceContainer>
          <FavoriteButton favorite={favorite} slug={slug} height={18} />
          {renderPrice(!!salePrice)}
        </PriceContainer>
      </Details>
      <a
        onClick={() => {
          window.history.pushState({}, '', cartItemLink);
        }}
        href={cartItemLink}
      >
        <ImageWrapper onClick={closeHeaderSublevel} ref={linkRef}>
          <Image src={imageUrl} alt={imageUrl} />
        </ImageWrapper>
      </a>
    </Container>
  );
};
