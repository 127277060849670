import styled from 'styled-components';

import {
  containerStyles,
  paragraphStyles,
  linkStyles,
  paragraphTextStyles,
  multilineTextLinkStyles,
} from '../blocks.styles';

export const Container = styled.div`
  ${containerStyles};
  ${paragraphTextStyles};

  p {
    a {
      ${multilineTextLinkStyles}
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    ${paragraphStyles};
    display: inline;
  }
`;
