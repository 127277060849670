import React, { useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { always } from 'ramda';
import { AnimatePresence } from 'framer-motion';

import { BUTTON_SIZE, Error, TextInput, formMessages } from '@components/form';
import { useYupValidationResolver } from '@hooks/useYup';
import api from '@services/nextAPI';
import { formatIntlLink } from '@utils/helpers';
import reportError from '@utils/reportError';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { MainAnimateContainer } from '@components/mainAnimateContainer';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR, ROUTE } from '@utils/constants';
import { BLOCK_TYPE } from '@components/blocks';
import pintrk, { PINTEREST_EVENTS } from '@utils/pinterestTag';
import { useTrackConversions } from '@hooks/useTrackConversions';
import { CONVERSIONS_EVENTS, CONVERSIONS_STATUS } from '@definitions/conversions.types';
import { useBreakpoint } from '@hooks/useBreakpoint';

import {
  Form,
  TermsConditions,
  CheckboxField,
  TextField,
  Button,
  InformationText,
  Container,
  Title,
} from './newsletterBlock.styles';
import {
  EMAIL_FIELD_NAME,
  NEWSLETTER_TERMS_NAME,
  FORM_TEST_ID,
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
} from './newsletterBlock.constants';

export interface FormProps {
  email: string;
  termsConditions: string;
  first_name: string;
  last_name: string;
}

export interface NewsletterBlockProps {
  infoText: string;
  tag: string;
  successInformation: string;
  title: string;
  textColor?: string;
  backgroundColor?: string;
  nameVisibility?: string;
}

export const NewsletterBlock = ({
  infoText,
  tag,
  successInformation,
  title,
  textColor = DEFAULT_TEXT_COLOR,
  nameVisibility,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
}: NewsletterBlockProps) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { sendConversion } = useTrackConversions();
  const emailPlaceholder = intl.formatMessage({
    id: 'newsletterBlock.emailPlaceholder',
    defaultMessage: 'Email Address:',
  });
  const termsCopy = {
    id: 'newsletterBlock.terms',
    defaultMessage: 'I have read, understood and accept the <terms>terms & conditions</terms>',
  };
  const termsRequired = intl.formatMessage({
    id: 'newsletterBlock.termsRequired',
    defaultMessage: 'You need to accept terms and conditions.',
  });
  const emailError = intl.formatMessage({
    id: 'newsletterBlock.emailError',
    defaultMessage: 'Please enter a valid email address.',
  });

  const firstNameError = intl.formatMessage({
    id: 'newsletterBlock.firstNameError',
    defaultMessage: 'Please enter first name.',
  });

  const lastNameError = intl.formatMessage({
    id: 'newsletterBlock.lastNameError',
    defaultMessage: 'Please enter last name.',
  });

  const validationSchema = useMemo(() => {
    const firstNameValidation =
      nameVisibility?.toLowerCase() === 'required' ? yup.string().required(firstNameError) : yup.string().nullable();

    const lastNameValidation =
      nameVisibility?.toLowerCase() === 'required' ? yup.string().required(lastNameError) : yup.string().nullable();

    return yup.object({
      [EMAIL_FIELD_NAME]: yup.string().email(emailError).required(emailError),
      [FIRST_NAME_FIELD_NAME]: firstNameValidation,
      [LAST_NAME_FIELD_NAME]: lastNameValidation,
      [NEWSLETTER_TERMS_NAME]: yup.bool().oneOf([true], termsRequired).required(termsRequired),
    });
  }, [nameVisibility]);

  const resolver = useYupValidationResolver(validationSchema);

  const { register, handleSubmit, control } = useForm<FormProps>({ resolver });
  const handleNewsletterSubmit = async ({ email, first_name, last_name }: FormProps) => {
    try {
      setLoading(true);
      setError(false);
      await api.post('email/subscribe', { email, first_name, last_name, tags: [tag] });
      setSuccess(true);
      await sendConversion({
        eventType: CONVERSIONS_EVENTS.COMPLETE_REGISTRATION,
        status: CONVERSIONS_STATUS.NEWSLETTER,
      });
      pintrk.event(PINTEREST_EVENTS.SIGNUP, { lead_type: 'Newsletter' });
    } catch (e) {
      setError(true);
      reportError(e);
    } finally {
      setLoading(false);
    }
  };
  const { errors } = useFormState({ control });

  const renderError = renderWhenTrue(
    always(
      <Error>
        <FormattedMessage {...formMessages.generalError} />
      </Error>
    )
  );

  const renderForm = renderWhenTrueOtherwise(
    always(
      <MainAnimateContainer key="form">
        {!success ? (
          <Form
            data-testid={FORM_TEST_ID}
            onSubmit={handleSubmit(handleNewsletterSubmit)}
            onKeyDown={(e) => {
              if (e.key === 'Tab') e.stopPropagation();
            }}
          >
            <InformationText>{infoText}</InformationText>
            <div style={{ display: 'flex', marginTop: 30 }}>
              <TextInput
                type="email"
                register={register(EMAIL_FIELD_NAME)}
                error={errors[EMAIL_FIELD_NAME]}
                placeholder={emailPlaceholder}
              />
              <Button
                style={{ marginLeft: 20, height: 38, width: '20%' }}
                type="submit"
                disabled={loading}
                fullWidth={true}
                size={BUTTON_SIZE.L}
                textColor={textColor}
                backgroundColor={backgroundColor}
              >
                <FormattedMessage id="newsletterBlock.submitEmail" defaultMessage="Sign Up" />
              </Button>
            </div>
            {renderError(error)}

            <TermsConditions>
              <CheckboxField
                register={register(NEWSLETTER_TERMS_NAME)}
                error={errors[NEWSLETTER_TERMS_NAME]}
                label={intl.formatMessage(termsCopy, {
                  terms: formatIntlLink(ROUTE.TERMS_AND_CONDITIONS),
                })}
              />
            </TermsConditions>
          </Form>
        ) : (
          <InformationText>{successInformation}</InformationText>
        )}
      </MainAnimateContainer>
    ),
    always(
      <MainAnimateContainer key="successMessage">
        <InformationText>{successInformation}</InformationText>
      </MainAnimateContainer>
    )
  );

  const renderAllForm = renderWhenTrueOtherwise(
    always(
      <MainAnimateContainer key="form">
        {!success ? (
          <Form
            data-testid={FORM_TEST_ID}
            onSubmit={handleSubmit(handleNewsletterSubmit)}
            onKeyDown={(e) => {
              if (e.key === 'Tab') e.stopPropagation();
            }}
          >
            <InformationText>{infoText}</InformationText>

            <div style={{ marginTop: 30 }}>
              <TextField
                register={register(EMAIL_FIELD_NAME)}
                error={errors[EMAIL_FIELD_NAME]}
                placeholder={intl.formatMessage(formMessages.email)}
                asterisk
              />
              <div style={{ display: 'flex', marginTop: 20, marginBottom: 30 }}>
                <div style={{ width: 300, marginRight: 25 }}>
                  <TextField
                    register={register(FIRST_NAME_FIELD_NAME)}
                    error={errors[FIRST_NAME_FIELD_NAME]}
                    placeholder={intl.formatMessage(formMessages.firstName)}
                    optional={nameVisibility?.toLowerCase() === 'optional' ? true : false}
                    asterisk={nameVisibility?.toLowerCase() === 'required' ? true : false}
                  />
                </div>
                <div style={{ width: 330 }}>
                  <TextField
                    register={register(LAST_NAME_FIELD_NAME)}
                    error={errors[LAST_NAME_FIELD_NAME]}
                    placeholder={intl.formatMessage(formMessages.lastName)}
                    optional={nameVisibility?.toLowerCase() === 'optional' ? true : false}
                    asterisk={nameVisibility?.toLowerCase() === 'required' ? true : false}
                  />
                </div>
              </div>
              <TermsConditions>
                <CheckboxField
                  register={register(NEWSLETTER_TERMS_NAME)}
                  error={errors[NEWSLETTER_TERMS_NAME]}
                  label={intl.formatMessage(termsCopy, {
                    terms: formatIntlLink(ROUTE.TERMS_AND_CONDITIONS),
                  })}
                />
              </TermsConditions>
              <Button
                style={{ height: 38, width: '100%' }}
                type="submit"
                disabled={loading}
                fullWidth={true}
                size={BUTTON_SIZE.L}
                textColor={textColor}
                backgroundColor={backgroundColor}
              >
                <FormattedMessage id="newsletterBlock.submitEmail" defaultMessage="Sign Up" />
              </Button>
            </div>

            {/* Error Message */}
            {error && renderError(error)}
          </Form>
        ) : (
          <InformationText>{successInformation}</InformationText>
        )}
      </MainAnimateContainer>
    ),
    always(
      <MainAnimateContainer key="successMessage">
        <InformationText>{successInformation}</InformationText>
      </MainAnimateContainer>
    )
  );

  const renderMobileForm = renderWhenTrueOtherwise(
    always(
      <MainAnimateContainer key="form">
        <Form data-testid={FORM_TEST_ID} onSubmit={handleSubmit(handleNewsletterSubmit)}>
          <InformationText>{infoText}</InformationText>
          <TextInput
            type="email"
            register={register(EMAIL_FIELD_NAME)}
            error={errors[EMAIL_FIELD_NAME]}
            placeholder={emailPlaceholder}
          />
          <TermsConditions>
            <CheckboxField
              register={register(NEWSLETTER_TERMS_NAME)}
              error={errors[NEWSLETTER_TERMS_NAME]}
              label={intl.formatMessage(termsCopy, {
                terms: formatIntlLink(ROUTE.TERMS_AND_CONDITIONS),
              })}
            />
          </TermsConditions>
          {renderError(error)}
          <div style={{ marginTop: 50 }}>
            <Button
              type="submit"
              disabled={loading}
              fullWidth={true}
              size={BUTTON_SIZE.L}
              textColor={textColor}
              backgroundColor={backgroundColor}
            >
              <FormattedMessage id="newsletterBlock.submitEmail" defaultMessage="Sign Up" />
            </Button>
          </div>
        </Form>
      </MainAnimateContainer>
    ),
    always(
      <MainAnimateContainer key="successMessage">
        <InformationText>{successInformation}</InformationText>
      </MainAnimateContainer>
    )
  );

  const renderMobileFormAll = renderWhenTrueOtherwise(
    always(
      <MainAnimateContainer key="form">
        <Form data-testid={FORM_TEST_ID} onSubmit={handleSubmit(handleNewsletterSubmit)}>
          <InformationText>{infoText}</InformationText>
          <TextInput
            type="email"
            register={register(EMAIL_FIELD_NAME)}
            error={errors[EMAIL_FIELD_NAME]}
            placeholder={emailPlaceholder}
          />
          <div style={{ marginTop: 20 }}>
            <TextField
              register={register(FIRST_NAME_FIELD_NAME)}
              error={errors[FIRST_NAME_FIELD_NAME]}
              placeholder={intl.formatMessage(formMessages.firstName)}
              optional={nameVisibility?.toLowerCase() === 'optional' ? true : false}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              register={register(LAST_NAME_FIELD_NAME)}
              error={errors[LAST_NAME_FIELD_NAME]}
              placeholder={intl.formatMessage(formMessages.lastName)}
              optional={nameVisibility?.toLowerCase() === 'optional' ? true : false}
            />
          </div>
          <TermsConditions>
            <CheckboxField
              register={register(NEWSLETTER_TERMS_NAME)}
              error={errors[NEWSLETTER_TERMS_NAME]}
              label={intl.formatMessage(termsCopy, {
                terms: formatIntlLink(ROUTE.TERMS_AND_CONDITIONS),
              })}
            />
          </TermsConditions>
          {renderError(error)}
          <div style={{ marginTop: 50 }}>
            <Button
              type="submit"
              disabled={loading}
              fullWidth={true}
              size={BUTTON_SIZE.L}
              textColor={textColor}
              backgroundColor={backgroundColor}
            >
              <FormattedMessage id="newsletterBlock.submitEmail" defaultMessage="Sign Up" />
            </Button>
          </div>
        </Form>
      </MainAnimateContainer>
    ),
    always(
      <MainAnimateContainer key="successMessage">
        <InformationText>{successInformation}</InformationText>
      </MainAnimateContainer>
    )
  );

  return (
    <Container data-testid={BLOCK_TYPE.NEWSLETTER_BLOCK}>
      <Title>{title}</Title>
      <AnimatePresence initial={false} exitBeforeEnter>
        {isMobile
          ? nameVisibility?.toLowerCase().includes('hidden')
            ? renderMobileForm(!success)
            : renderMobileFormAll(!success)
          : nameVisibility?.toLowerCase().includes('hidden')
          ? renderForm(!success)
          : renderAllForm(!success)}
      </AnimatePresence>
    </Container>
  );
};
