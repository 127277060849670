export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';
export const BIG_DESKTOP = 'bigDesktop';

export type BreakpointType = 'mobile' | 'tablet' | 'desktop' | 'bigDesktop';

export const breakpoints = {
  mobile: 576,
  tablet: 768,
  desktop: 994,
  bigDesktop: 1440,
};

export const heightBreakpoints = {
  tablet: 576,
};

export const size = {
  mobile: `${breakpoints.mobile}px`,
  tablet: `${breakpoints.tablet}px`,
  desktop: `${breakpoints.desktop}px`,
  bigDesktop: `${breakpoints.bigDesktop}px`,
  tabletHeight: `${heightBreakpoints.tablet}px`,
};

export const media = {
  mobile: `@media (min-width: ${size.mobile})`,
  tablet: `@media (min-width: ${size.tablet})`,
  tabletHeight: `@media (min-width: ${size.tablet}) and (min-height: ${size.tabletHeight})`,
  desktop: `@media (min-width: ${size.desktop})`,
  bigDesktop: `@media (min-width: ${size.bigDesktop})`,
  custom: (customBreakpoint: number) => `@media (min-width: ${customBreakpoint}px)`,
};
