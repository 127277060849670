import styled, { css } from 'styled-components';
import { always, cond, equals, ifElse, pipe, prop, propEq, T } from 'ramda';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { FONT_SIZE, FONT_WEIGHT, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

import { BUTTON_SIZE } from './form.constants';

interface ButtonProps {
  size?: BUTTON_SIZE;
  fullWidth?: boolean;
  wide?: boolean;
  inverted?: boolean;
  primary?: boolean;
  textLink?: boolean;
}

const textLinkStyles = css`
  background-color: transparent;
  color: ${COLORS.BLACK60};
  border: 1px solid transparent;
`;

const invertedStyles = css`
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};

  &:hover {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.BLACK};
  }
`;

const xxsSizeStyles = css`
  font-size: 10px;
  line-height: ${LINE_HEIGHT.M};
  letter-spacing: ${LETTER_SPACING.M};
  font-weight: ${FONT_WEIGHT.BOOK};
  text-transform: uppercase;
  padding: 4px 6px 2px;
  height: auto;
  margin-top: 10px;

  ${media.mobile} {
    padding: 4px 6px 2px;
  }

  ${media.tablet} {
    padding: 0 6px;
    min-height: 20px;
  }
`;

const lSizeStyles = css`
  ${typography({})};
  padding: 11px 20px 8px;
  height: 44px;

  ${media.tablet} {
    height: auto;
    padding: 7px 20px 7px;
  }
`;

const xlSizeStyles = css`
  ${typography({})};
  height: 68px;
  box-sizing: border-box;
  width: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const xxlSizeStyles = css`
  padding: 25px 14px 22px;
`;

const wideStyles = css`
  min-width: 160px;

  ${media.tablet} {
    min-width: 172px;
  }
`;

const defaultSizeStyles = css`
  height: 44px;

  ${media.tablet} {
    height: 34px;
  }
`;

const getWideStyles = ifElse(propEq('wide', true), always(wideStyles), always(null));
const getTextLinkStyles = ifElse(propEq('textLink', true), always(textLinkStyles), always(null));
const getInvertedStyles = ifElse(propEq('inverted', true), always(invertedStyles), always(null));
const getSizeStyles = pipe(
  // @ts-ignore
  prop('size'),
  cond([
    [equals(BUTTON_SIZE.XXS), always(xxsSizeStyles)],
    [equals(BUTTON_SIZE.L), always(lSizeStyles)],
    [equals(BUTTON_SIZE.XL), always(xlSizeStyles)],
    [equals(BUTTON_SIZE.XXL), always(xxlSizeStyles)],
    [T, always(defaultSizeStyles)],
  ])
);

export const defaultButtonStyles = css<ButtonProps>`
  border: 1px solid ${COLORS.BLACK};
  background-color: ${COLORS.WHITE};
  padding: ${OFFSET.XS} ${OFFSET.S};
  ${typography({ size: FONT_SIZE.XS })};
  text-align: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  text-transform: ${({ primary }) => (primary ? 'uppercase' : 'initial')};

  ${media.mobile} {
    letter-spacing: ${LETTER_SPACING.M};
  }

  ${media.tablet} {
    padding: 8px 13px 7px;
  }

  &:hover {
    background-color: ${COLORS.BLACK};
    color: ${COLORS.WHITE};
  }

  &:disabled {
    color: ${COLORS.DOVE_GRAY};
  }

  ${getTextLinkStyles};
  ${getSizeStyles};
  ${getInvertedStyles};
  ${getWideStyles};
`;

export const Button = styled.button<ButtonProps>`
  ${defaultButtonStyles};
`;

export const Link = styled.a<ButtonProps>`
  display: block;
  ${defaultButtonStyles};
`;

export const Error = styled.div`
  color: ${COLORS.RED};
  ${typography({ size: FONT_SIZE.XS })};
`;

export const ErrorMessage = styled(Error)`
  position: absolute;
  top: 100%;
  left: 0;
`;

export const ButtonRightContainer = styled.div`
  margin: ${OFFSET.M} 0 ${OFFSET.M};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1px;
`;

export const Optional = styled.div`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  position: absolute;
  top: 9px;
  right: 14px;
  display: block;
  color: ${COLORS.BLACK30};
  pointer-events: none;
`;

export const Select = styled.select`
  ${typography({})};
  border: none;
  -webkit-appearance: none;
  background-color: transparent;
  width: 100%;
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid ${COLORS.BLACK};
  border-top: 1px solid ${COLORS.BLACK};
  border-radius: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SelectOption = styled.option``;

export const SelectLabel = styled.label`
  white-space: nowrap;
  letter-spacing: ${LETTER_SPACING.S};
  padding: 10px 10px 10px 12px;
  line-height: 1;
`;

export const SelectArrow = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  &:after {
    content: '\\25BC';
    font-size: 14px;
  }
`;
