import React from 'react';

import { Bar, Indicator } from './loader.styles';

export const LOADER_TEST_ID = 'loader';

export const Loader = () => (
  <Bar data-testid={LOADER_TEST_ID}>
    <Indicator />
  </Bar>
);
