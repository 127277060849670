import styled, { keyframes } from 'styled-components';

import { COLORS } from '@theme/colors';
import { NARROW_CONTAINER_WIDTH } from '@components/blocks';

export const Bar = styled.div`
  max-width: ${NARROW_CONTAINER_WIDTH}px;
  height: 3px;
  background-color: ${COLORS.BLACK10};
  position: relative;
  overflow: hidden;
  margin: 0 auto;
`;

const loadingIndicatiorAnimation = keyframes`
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
`;

export const Indicator = styled.div`
  display: block;
  background: ${COLORS.BLACK};
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${loadingIndicatiorAnimation} 2s forwards linear infinite;
}`;
