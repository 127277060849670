import React, { MouseEvent, useEffect, useState } from 'react';
import { css, SimpleInterpolation } from 'styled-components';
import { useIntl } from 'react-intl';

import reportError from '@utils/reportError';
import { useFavorite } from '@hooks/useFavorite';
import { useCustomer } from '@hooks/useCustomer';
import { useHeaderSublevel } from '@hooks/useUi';
import { SUBLEVEL } from '@components/header/header.constants';
import pintrk, { PINTEREST_EVENTS } from '@utils/pinterestTag';
import { DEFAULT_TEXT_COLOR } from '@utils/constants';
import { getCurrentPLP } from '@modules/plp/plp.helpers';

import StarIcon from '../../assets/images/icon-star.svg';

import { Container } from './favoriteButton.styles';

export interface FavoriteButtonProps {
  slug: string;
  favorite: boolean;
  customStyles?: SimpleInterpolation;
  width?: number;
  height?: number;
  textColor?: string;
}

export const FavoriteButton = ({
  favorite,
  slug,
  width = 44,
  height = 44,
  customStyles = css``,
  textColor = DEFAULT_TEXT_COLOR,
}: FavoriteButtonProps) => {
  const [isFavorite, setIsFavorite] = useState(favorite);
  const currentPLP = getCurrentPLP();
  const { addFavorite, removeFavorite } = useFavorite(currentPLP);
  const { isAuthed } = useCustomer();
  const intl = useIntl();
  const { openHeaderSublevel } = useHeaderSublevel();
  const addLabel = intl.formatMessage({
    id: 'favoriteButton.addLabel',
    defaultMessage: 'Add the artwork to favorites',
  });
  const removeLabel = intl.formatMessage({
    id: 'favoriteButton.removeLabel',
    defaultMessage: 'Remove the artwork from favorites',
  });

  useEffect(() => {
    setIsFavorite(favorite);
  }, [favorite]);

  const addToFavorite = async () => {
    try {
      await addFavorite({ slug });
      setIsFavorite(true);
      pintrk.event(PINTEREST_EVENTS.LEAD, { lead_type: 'Favorite' });
    } catch (e) {
      reportError(e);
    }
  };

  const removeFromFavorite = async () => {
    try {
      await removeFavorite({ slug });
      setIsFavorite(false);
    } catch (e) {
      reportError(e);
    }
  };

  const handleSetFavorite = (e: MouseEvent) => {
    e.preventDefault();

    if (!isAuthed) {
      return openHeaderSublevel({ sublevel: SUBLEVEL.ACCOUNT, preventRedirectAfterLogin: true });
    }

    if (isFavorite) {
      return removeFromFavorite();
    }
    return addToFavorite();
  };

  return (
    <Container
      onClick={handleSetFavorite}
      active={isFavorite}
      width={width}
      height={height}
      customStyles={customStyles}
      aria-label={isFavorite ? removeLabel : addLabel}
      textColor={textColor}
    >
      <StarIcon />
    </Container>
  );
};
