import qs from 'querystring';

import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { defaultTo, head, identity, ifElse, is, isEmpty, pipe, trim } from 'ramda';
import { useRouter } from 'next/router';

import { GlobalState } from '@modules/createStore';
import { useFetchArtworks, useResetArtworks } from '@hooks/useArtworks';
import reportError from '@utils/reportError';
import { useTrackConversions } from '@hooks/useTrackConversions';
import { CONVERSIONS_EVENTS } from '@definitions/conversions.types';
import { artworksDomain } from '@modules/artworks/artworks.selectors';
import { ACTION_PREFIX, PLP_VARIANT } from '@modules/plp/plp.constants';
import { ArtworksState } from '@definitions/artworks.types';

import { Input } from './searchInput.styles';

export interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isSearch: boolean;
  setLoading: (loading: boolean) => void;
}

export const SearchInput = ({ isSearch, setLoading, ...restProps }: SearchInputProps) => {
  const { fetchArtworks } = useFetchArtworks(PLP_VARIANT.ARTWORKS);
  const { resetArtworks } = useResetArtworks(PLP_VARIANT.ARTWORKS);
  const { sendConversion } = useTrackConversions();
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const querySearch = pipe(defaultTo(''), ifElse(is(Array), head, identity), trim)(router.query.search);
  const [search, setSearch] = useState(querySearch);

  useEffect(() => {
    if (isSearch && router.isReady) {
      inputRef.current?.focus();
      setSearch(querySearch);
    }
  }, [router.isReady]);

  useDebounce(
    async () => {
      try {
        if (!isSearch || !router.isReady) {
          return;
        }

        setLoading(true);
        const isSearchEmpty = isEmpty(search);
        resetArtworks();
        const { pathname } = location;
        const queryString = isSearchEmpty ? pathname : `?${qs.stringify({ search })}`;
        history.replaceState(history.state, '', queryString);

        if (isSearchEmpty) {
          return;
        }

        const { meta, artworks } = await fetchArtworks({ search, page: 1 });
        sendConversion({
          eventType: CONVERSIONS_EVENTS.SEARCH,
          stringSearch: search,
          value: artworks.length,
        }).finally(() => ({}));
        if (meta.hasNext) {
          await fetchArtworks({ search, page: 2, infiniteLoader: true });
        }
      } catch (e) {
        reportError(e);
      } finally {
        setLoading(false);
      }
    },
    400,
    [search]
  );

  return (
    <Input
      {...restProps}
      defaultValue={querySearch}
      onChange={(e) => setSearch(trim(e.target?.value))}
      ref={inputRef}
    />
  );
};
