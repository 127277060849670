import styled, { SimpleInterpolation } from 'styled-components';
import { prop } from 'ramda';

import { media } from '@theme/media';

export const Container = styled.button<{
  width: number;
  height: number;
  active: boolean;
  textColor: string;
  customStyles: SimpleInterpolation;
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  transition: transform 0.9s ease-out;
  transform: rotate(${({ active }) => (active ? 360 : 0)}deg);
  opacity: 1;

  svg {
    width: 10px;
    fill: ${({ active, textColor }) => (active ? textColor : 'none')};
  }

  svg > path:last-child {
    fill: ${({ textColor }) => textColor};
  }

  path {
    stroke: ${({ textColor }) => textColor};
  }

  ${media.desktop} {
    &:hover {
      path {
        fill: ${({ textColor }) => textColor};
        stroke: ${({ textColor }) => textColor};
      }
    }
  }

  ${prop('customStyles')};
`;
