import { defineMessages } from 'react-intl';

export const headerMessages = defineMessages({
  favorites: { id: 'header.favorites', defaultMessage: 'Favorites' },
  platform: { id: 'header.platform', defaultMessage: 'Platform:' },
  artworks: { id: 'header.artworks', defaultMessage: 'Artworks' },
  artists: { id: 'header.artists', defaultMessage: 'Artists' },
  features: { id: 'header.features', defaultMessage: 'Features' },
  search: { id: 'header.search', defaultMessage: 'Search' },
  cancel: { id: 'header.cancel', defaultMessage: 'Cancel' },
  bag: { id: 'header.bag', defaultMessage: 'Bag' },
  shipping: { id: 'header.shipping', defaultMessage: 'Shipping to: {country}' },
  account: { id: 'header.account', defaultMessage: 'Account' },
  accountMobilePrefix: { id: 'header.accountMobilePrefix', defaultMessage: 'Login to ' },
  searchPlaceholder: { id: 'header.searchPlaceholder', defaultMessage: 'Artworks, artists, galleries...' },
  closeSublevelLabel: { id: 'header.closeSublevelLabel', defaultMessage: 'Close {sublevel} dropdown' },
  shippingLabel: { id: 'header.shippingLabel', defaultMessage: 'Shipping to. Currently selected {country}' },
  closeAllSubLevelsLabel: { id: 'header.closeAllSubLevelsLabel', defaultMessage: 'Close all dropdowns' },
  menuLabel: { id: 'header.menuLabel', defaultMessage: 'Menu' },
});
