import styled from 'styled-components';

import { COLORS } from '@theme/colors';
import { FONT_SIZE } from '@theme/font';

interface StylesProps {
  color: string;
}

export const ArrowRight = styled.i`
  transition: transform 0.5s ease-in-out;
  margin-right: 5px;
  display: inline-block;

  &:before {
    content: '→';
    font-size: ${FONT_SIZE.XS};
  }
`;

export const Link = styled.a`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.span`
  transition: border 0.25s ease-in-out;
  text-align: center;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    opacity: 0.1;
    transition: opacity 0.25s ease-in-out;
  }
`;

export const Container = styled.div`
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  width: fit-content;

  & > a {
    color: ${({ color }: StylesProps) => (color ? color : COLORS.BLACK)};
  }

  ${Text}::after {
    background-color: ${({ color }: StylesProps) => (color ? color : COLORS.BLACK)};
  }

  &:hover ${Text}::after {
    opacity: 1;
  }

  &:hover ${ArrowRight} {
    transform: rotate(360deg);
  }
`;

export const ExternalLink = styled.a``;
