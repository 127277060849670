import React, { useEffect, useState } from 'react';
import { always } from 'ramda';
import { useInView } from 'react-intersection-observer';

import { ASSET_PLACEMENT, BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { renderWhenTrue } from '@utils/rendering';

import { Container, Caption, Content, VideoWrapper, Player } from './videoBlock.styles';

export interface VideoBlockProps {
  videoUrl: string;
  caption?: string;
  autoplay: boolean;
  placement?: ASSET_PLACEMENT;
  className?: string;
}

export const VideoBlock = ({
  videoUrl,
  caption,
  placement = ASSET_PLACEMENT.NORMAL,
  autoplay,
  className,
}: VideoBlockProps) => {
  const renderCaption = renderWhenTrue(always(<Caption>{caption}</Caption>));
  const [playing, setPlaying] = useState(autoplay);
  const [videoRef, inView] = useInView();

  useEffect(() => {
    if (autoplay) {
      setPlaying(inView);
    }
  }, [inView]);

  return (
    <Container className={className} data-testid={BLOCK_TYPE.VIDEO_BLOCK}>
      <Content placement={placement}>
        <VideoWrapper ref={videoRef}>
          <Player
            url={videoUrl}
            width="100%"
            height="100%"
            data-testid="video"
            controls
            playing={playing}
            muted
            playsinline
          />
        </VideoWrapper>
        {renderCaption(!!caption)}
      </Content>
    </Container>
  );
};
