import styled, { FlattenSimpleInterpolation, keyframes } from 'styled-components';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';
import { FONT_SIZE, LETTER_SPACING, LINE_HEIGHT, typography } from '@theme/font';

export const Caption = styled.div`
  padding: 12px 4px;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const Title = styled.div`
  text-transform: uppercase;
`;

export const ArtworkDetail = styled.div`
  word-break: break-word;
`;

export const Card = styled.div`
  width: 100%;
  padding-top: 65%;
  position: relative;
`;

const cardAnimation = keyframes`
  0% {opacity: 0;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  53% {opacity: 0;}
  100% {opacity: 0;}
`;

const cardAnimationFirstElement = keyframes`
  0% {opacity: 1;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  53% {opacity: 0;}
  100% {opacity: 0;}
`;

export const BackgroundImage = styled.div<{ backgroundImage: string; index: number; variantsCount: number }>`
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${OFFSET.XS};
  opacity: 0;

  &:first-child {
    opacity: 1;
  }

  animation-iteration-count: infinite;
  animation-duration: ${({ variantsCount }) => variantsCount * 1.5}s;
  animation-delay: ${({ index }) => index * 1.5}s;
`;

export const Link = styled.a`
  ${typography({ size: FONT_SIZE.XS, height: LINE_HEIGHT.S, spacing: LETTER_SPACING.M })};
  text-align: center;
  color: ${COLORS.BLACK};
  text-decoration: none;
  display: block;

  &:hover {
    ${BackgroundImage} {
      &:first-child {
        animation-name: ${cardAnimationFirstElement};
      }
      animation-name: ${cardAnimation};
    }
  }
`;

interface ImageContainerProps {
  readonly customHeight?: string;
  readonly customWidth?: string;
  readonly customStyles?: FlattenSimpleInterpolation;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  width: ${({ customWidth = 'auto' }) => customWidth};
  height: ${({ customHeight = 'auto' }) => customHeight};
  display: flex;
  align-items: center;
  min-width: 120px;

  ${({ customStyles }) => customStyles};

  ${media.tablet} {
    min-width: 240px;
  }
`;

export const CardCopyWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const CardCopy = styled.div`
  ${typography({ spacing: LETTER_SPACING.S })};
  background-color: ${COLORS.WHITE};
  margin: 0 ${OFFSET.M};
  width: 100%;
  padding: 9px 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.mobile} {
    flex-direction: row;
  }

  ${media.desktop} {
    justify-content: space-between;
  }
`;

export const LeftCopy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile} {
    flex-direction: row;
  }

  ${media.desktop} {
    justify-content: space-between;
  }
`;

export const PlatformCopy = styled.div`
  text-transform: uppercase;
  display: none;

  ${media.bigDesktop} {
    display: block;
    margin-right: 20px;
  }
`;

export const PriceCopy = styled.div`
  display: none;

  ${media.desktop} {
    display: block;
  }
`;

export const TitleCopy = styled.div`
  text-align: center;
  line-height: 1;
`;

export const CardDollar = styled.div`
  ${typography({ size: FONT_SIZE.L, height: LINE_HEIGHT.L, spacing: LETTER_SPACING.S })};
  text-align: center;
  width: 100%;
  color: ${COLORS.WHITE};
`;
