import React, { useRef, useState } from 'react';
import { propOr } from 'ramda';
import { useMount } from 'react-use';
import { useIntl } from 'react-intl';

import { useWindowListener } from '@hooks/useWindowListener';
import { CONTAINER_PADDING, NARROW_CONTAINER_WIDTH } from '@components/blocks/blocks.constants';
import { ARROW_TYPE } from '@utils/constants';

import ArrowLeft from '../../assets/images/carousel-arrow-left.svg';
import ArrowRight from '../../assets/images/carousel-arrow-right.svg';

import {
  Container,
  ScrollTrack,
  ScrollBar,
  ScrollContainer,
  ArrowPrevWrapper,
  ArrowNextWrapper,
} from './customHorizontalScrollContainer.styles';

export interface CustomHorizontalScrollContainerProps {
  children: React.ReactNode;
  itemsLength: number;
}

export const CustomHorizontalScrollContainer = ({ children, itemsLength }: CustomHorizontalScrollContainerProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [leftPosition, setLeftPosition] = useState(0);
  const [barWidth, setBarWidth] = useState(0);
  const [arrowVisible, setArrowVisible] = useState<ARROW_TYPE | null>(null);
  const intl = useIntl();
  const getContainerProps: () => HTMLDivElement = () =>
    propOr({ scrollWidth: 0, offsetWidth: 0, scrollLeft: 0 }, 'current', containerRef);
  const scrollRightLabel = intl.formatMessage({
    id: 'customHorizontalScrollContainer.scrollRight',
    defaultMessage: 'Scroll Right',
  });
  const scrollLeftLabel = intl.formatMessage({
    id: 'customHorizontalScrollContainer.scrollLeft',
    defaultMessage: 'Scroll Left',
  });

  const setNewWidth = () => {
    const { scrollWidth, offsetWidth } = getContainerProps();
    const innerWidth = offsetWidth - 2 * CONTAINER_PADDING;
    const barWidth = (offsetWidth * offsetWidth) / scrollWidth;

    const newWidth = Math.round((barWidth * innerWidth) / offsetWidth) || 0;
    setBarWidth(newWidth);
  };

  const updateScroll = () => {
    const { scrollLeft, offsetWidth, scrollWidth } = getContainerProps();
    const innerWidth = offsetWidth - 2 * CONTAINER_PADDING;

    setLeftPosition((scrollLeft * innerWidth) / scrollWidth);
  };

  const handleMouseMove = ({ pageX, currentTarget }: { pageX: number; currentTarget: HTMLDivElement }) => {
    const containerWidth = currentTarget?.offsetWidth;
    const { scrollLeft, scrollWidth } = getContainerProps();

    if (pageX < containerWidth / 2 - NARROW_CONTAINER_WIDTH && scrollLeft !== 0) {
      return setArrowVisible(ARROW_TYPE.PREV);
    }

    if (containerWidth / 2 + NARROW_CONTAINER_WIDTH < pageX && scrollLeft + containerWidth < scrollWidth) {
      return setArrowVisible(ARROW_TYPE.NEXT);
    }

    return setArrowVisible(null);
  };

  useMount(() => {
    setTimeout(setNewWidth);
  });

  useWindowListener('resize', setNewWidth, { throttle: 200 });

  const handleScrollRight = () => {
    if (containerRef.current) {
      const { scrollWidth, offsetWidth, scrollLeft } = getContainerProps();
      const scrollBy = Math.ceil((scrollWidth - offsetWidth) / itemsLength) * 2;
      containerRef.current.scrollLeft += scrollBy;

      if (scrollLeft + offsetWidth + scrollBy >= scrollWidth) {
        setArrowVisible(null);
      }
    }
  };
  const handleScrollLeft = () => {
    if (containerRef.current) {
      const { scrollWidth, offsetWidth, scrollLeft } = getContainerProps();
      const scrollBy = Math.ceil((scrollWidth - offsetWidth) / itemsLength) * 2;
      containerRef.current.scrollLeft -= scrollBy;

      if (scrollLeft - scrollBy <= 0) {
        setArrowVisible(null);
      }
    }
  };

  return (
    <Container onMouseMove={handleMouseMove}>
      <ArrowPrevWrapper
        isVisible={ARROW_TYPE.PREV === arrowVisible}
        onClick={handleScrollLeft}
        aria-label={scrollLeftLabel}
      >
        <ArrowLeft />
      </ArrowPrevWrapper>
      <ArrowNextWrapper
        isVisible={ARROW_TYPE.NEXT === arrowVisible}
        onClick={handleScrollRight}
        aria-label={scrollRightLabel}
      >
        <ArrowRight />
      </ArrowNextWrapper>
      <ScrollContainer onScroll={updateScroll} ref={containerRef}>
        {children}
      </ScrollContainer>
      <ScrollTrack />
      <ScrollBar style={{ transform: `translateX(${leftPosition}px)`, width: `${barWidth}px` }} />
    </Container>
  );
};
