import { always, Arity0Fn, Arity1Fn, complement, equals, identity, ifElse, isNil, path, pipe } from 'ramda';

export const renderWhen = (pred: (arg: any) => boolean, fn: Arity0Fn | Arity1Fn) => ifElse(pred, fn, always(null));

export const renderWhenOtherwise = (pred: (arg: any) => boolean, fn: Arity1Fn, otherwise: Arity1Fn) =>
  ifElse(pred, fn, otherwise);

export const renderWhenNotNil = (fn: Arity0Fn) => renderWhen(complement(isNil), fn);

export const renderWhenTrue = (fn: Arity0Fn) => renderWhen(equals(true), fn);

export const renderWhenTrueOtherwise = (fn: Arity0Fn, otherwise: Arity0Fn) => ifElse(equals(true), fn, otherwise);

export const renderIfExistsIn = (
  arrayPath: (string | number)[],
  fn = identity as Arity1Fn,
  otherwise = always(null) as Arity0Fn
) => (object: object) => pipe(path(arrayPath), ifElse(equals(undefined), otherwise, fn))(object);
