import styled, { FlattenSimpleInterpolation } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

interface StyledLazyImageProps {
  readonly customHeight?: string;
  readonly customWidth?: string;
  readonly customStyles?: FlattenSimpleInterpolation;
  readonly defaultDimensions?: string;
}

export const StyledLazyImage = styled.img<StyledLazyImageProps>`
  object-fit: contain;
  width: ${({ customWidth, defaultDimensions }) => {
    return customWidth ? customWidth : defaultDimensions;
  }};
  height: ${({ customHeight, defaultDimensions }) => {
    return customHeight ? customHeight : defaultDimensions;
  }};
  transition: opacity 400ms ease-in-out;

  ${({ customStyles }) => customStyles}
`;

export const InViewThreshold = styled.div<{ threshold: number }>`
  position: absolute;
  z-index: -1;
  top: ${({ threshold }) => threshold}px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
`;
