import styled from 'styled-components';

export const Container = styled.div<{
  width?: string;
  fontSize?: string;
  borderSpacing?: string;
  margin?: number;
  textAlign?: string;
  textBoxAlign?: string;
}>`
  width: ${({ width }) => width || '95%'};
  font-size: ${({ fontSize }) => fontSize};
  border-spacing: ${({ borderSpacing }) => borderSpacing || '0'};
  margin: 25px;
  padding-top: 30px;
  text-align: ${({ textAlign }) => textAlign};

  ${({ textBoxAlign }) => {
    switch (textBoxAlign) {
      case 'left':
        return `
          margin-left: 40px;
          margin-right: auto;
        `;
      case 'right':
        return `
          margin-left: auto;
          margin-right: 40px;
        `;
      case 'center':
        return `
          margin: 0 auto;
        `;
      default:
        return `
          margin: 0 auto;
        `;
    }
  }}

  ol, ul {
    margin: 1;
    padding: 0;
    list-style-position: inside;
  }

  li {
    margin: 1;
    padding: 0;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin: 25px;
    width: 88%;
    font-size: ${({ fontSize }) => (fontSize === '48px' || fontSize === '72px' ? '32px' : fontSize)};
    line-height: ${({ borderSpacing }) =>
      borderSpacing === '54px' || borderSpacing === '72px' ? '36px' : borderSpacing};
  }
`;
