import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container } from './noArtworks.styles';

export const NoArtworks = () => (
  <Container>
    <FormattedMessage id="noArtworks.description" defaultMessage="CURRENTLY NO ARTWORKS AVAILABLE" />
  </Container>
);
